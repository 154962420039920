﻿.m-guide-block {
	margin: 0 auto 15px auto;
	padding: 0;
	max-width: 600px;

	&__link {
		display: block;
		margin: 0;
		padding: 0;
		text-decoration: none;

		&:hover {
			text-decoration: none;

			.m-guide-block__image__image {
				@include image-hover-anim();
			}
		}
	}

	&__title {
		background: $colorPurplePale;
		color: $colorBase;
		margin: 0;
		padding: 10px;
		text-align: center;
	}

	&__image {
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;

		&__image {
			display: block;
			height: auto;
			margin: 0 auto;
			position: relative;
			width: 100%;
			z-index: 1;
			@include image-hover();
		}

	}

	&:last-child,
	&--tight {
		margin-bottom: 0;
	}
}

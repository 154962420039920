﻿.m-recently-viewed {
	margin: 0;
	padding: 0 10px 15px 10px;

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -6px 0 0 -6px;
		overflow: hidden;
		padding: 0;
		text-align: center;

		&__item {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 6px 0 5px 6px;
			text-align: left;
			vertical-align: top;
			width: 50%;
			max-width: 208px;

			@include mq-min($bpMobileLarge) {
				width: 33.3333%;
			}

			@include mq-min($bpTabletMid) {
				width: 25%;
			}

			@include mq-min($bpTabletLarge) {
				padding: 12px 0 5px 12px;
			}

			@include mq-min($bpDesktop) {
				width: 16.6666%;
			}

			&:nth-child(3) {
				@include mq-max($bpMobileLarge - 1) {
					display: none;
				}
			}

			&:nth-child(4) {
				@include mq-max($bpTabletMid - 1) {
					display: none;
				}
			}

			&:nth-child(5),
			&:nth-child(6) {
				@include mq-max($bpDesktop - 1) {
					display: none;
				}
			}
		}

		&__link {
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 0;
			text-decoration: none;

			&:hover {
				color: $colorBase;
				text-decoration: none;

				.m-recently-viewed__menu__image__image {
					@include image-hover-anim();
				}
			}
		}

		&__image {
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: relative;
			z-index: 1;

			&__image {
				display: block;
				margin: 0;
				padding: 0;
				width: 100%;
				aspect-ratio: 2/1;
				@include image-hover();
			}
		}

		&__empty {
			background: lighten($colorPurplePale, 5%);
			display: block;
			margin: 0;
			padding: 0 0 50% 0;
			width: 100%;
		}

		&__title {
			display: block;
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			margin: 0;
			padding: 7px 0 0 0;
			@include text-overflow();
		}

		&__title--highlight {
			font-family: $fontBase;
			font-weight: 300;
		}

		&__title--faded {
			color: #939598;
		}

		@include mq-min($bpTabletLarge) {
			margin: -12px 0 0 -12px;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpDesktopClear) {
		padding: 0 0 25px 0;
	}

	@include mq-min($bpLarge) {
		padding-bottom: 30px;
	}

	&--tight {
		padding-bottom: 0;
	}
}

.m-recently-viewed--recent-bookings {

	padding-bottom: 0;

	.m-recently-viewed__menu__item {
		width: 100%;
		max-width: none;
		display: inline-block;

		@include mq-min($bpMobileLarge) {
			width: 50%;
		}

		@include mq-min($bpTabletMid) {
			width: 50%;
		}

		@include mq-min($bpDesktop) {
			width: 25%;
		}
	}

	.m-recently-viewed__menu__title {
		padding: 3px 0 0 0;
	}

	.m-recently-viewed__menu__title--first {
		padding: 7px 0 0 0;
	}
}

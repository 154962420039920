.c-flights {
    &__route {
        display: flex;

        &__airport {
            flex: 2;

            &__code {
                font-size: 20px;
            }

            &__name {
                font-size: 13px;
                color: rgba($colorBase, 0.6)
            }
        }

        &__icon {
            flex: 1;
            color: $colorAction;
            font-size: 25px;
        }
    }

    &__airline {
        font-size: 17px;
        color: $colorAction;
        padding: 10px 15px;
        border: 1px solid $colorPurplePale;
        text-align: center;
    }

    &__alternative {
        font-size: 13px;
        position: relative;
        text-align: center;
        display: flex;

        &__link {
            color: $colorBase;

            &:hover {
                color: $colorBase;
                text-decoration: none;
            }

            i {
                color: $colorLink;
            }
        }
    }

    &__details {
        display: flex;
        gap: 8px;
        font-size: 15px;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        &__title {
            color: $colorAction;
        }

        &__number {
            display: none;
        }

        &__times {
            i {
                color: $colorAction;
                margin: auto;
            }
        }
    }
}

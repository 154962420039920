.c-flights-search {
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 10px;

    @include mq-min($bpLarge) {
        flex-direction: row;
        gap: 0;
    }

    &__info {
        width: 100%;
        font-size: 13px;

        @include mq-min($bpLarge) {
            width: 75%;
        }

        &__details {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .c-flights__route {
            padding-bottom: 20px;
        }
    }

    &__meta {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: relative;
        gap: 5px;

        @include mq-min($bpLarge) {
            width: 25%;
            padding: 0 15px;
        }

        @include mq-min($bpLarge) {
            flex-direction: column;
        }
    }
}

.m-awards {
    width: 100%;
    display: flex;
    padding: 10px;
    box-sizing: border-box;

    &__trust-pilot {
        width: auto;
    }

    &__press-awards {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 15px;

        &__item {
            height: 100px;
            width: 100px;
            display: none;

            img {
                object-fit: contain;
                height: 100%;
                aspect-ratio: 1;
            }

            @include mq-min($bpDesktop) {
                display: block;
            }
        }

        .m-awards__press-awards__item.carousel {
            display: block;

            @include mq-min($bpDesktop) {
                display: none;
            }
        }
    }
}

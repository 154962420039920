﻿.m-booking-block {
	background: $colorPurplePale;
	margin: 0 0 15px;
	padding: 0;

	&__title {
		color: $colorGrey;
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
		padding: 0 0 17px;
	}

	&__inner {
		margin: 0;
		padding: 15px;

		@include mq-min($bpTabletLarge) {
			padding: 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 25px;
		}

		@include mq-min($bpLarge) {
			padding: 30px;
		}
	}

	&__header {
		border-bottom: 1px solid $colorWhite;
		margin: -5px 0 15px 0;
		padding: 0 0 15px 0;

		&__title,
		&__info {
			@include box-sizing();
			display: block;
			margin: 0;
			padding: 5px 0 0 0;

			@include mq-min($bpDesktop) {
				display: inline-block;
				padding-bottom: 15px;
			}
		}

		&__title {
			color: $colorGrey;
			font-size: $fontSize + 3;
			line-height: $fontHeight + 3;

			@include mq-min($bpTabletLarge) {
				padding-right: 15px;
			}
		}

		&__info,
		&__summary {
			font-size: $fontSize;
			line-height: $fontHeight;
			padding: 15px 0 0;
		}

		&__sub-title {
			color: $colorBase;
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			padding: 20px 0 15px;
		}

		&--alt {
			border-bottom: none;
			margin-bottom: 0 !important;
		}

		&--tight {
			padding-bottom: 5px !important;
		}

		@include mq-min($bpTabletLarge) {
			margin-bottom: 20px;
			padding-bottom: 20px;
		}

		@include mq-min($bpDesktop) {
			margin-bottom: 20px;
			padding-bottom: 23px;
		}
	}

	&__airport {
		min-height: 30px;
		padding: 0 0 20px 40px;
		position: relative;

		&:before {
			background: url(/images/common/sprite.png) no-repeat -160px -138px;
			content: " ";
			height: 30px;
			left: 0;
			position: absolute;
			top: 0;
			width: 30px;
		}

		&--return:before {
			background-position: -192px -138px;
		}

		&__title {
			color: $colorGrey;
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;

			span {
				color: $colorBase;
				display: block;
				font-size: $fontSize;
				padding: 3px 0 0;
			}
		}

		@include mq-min($bpDesktop) {
			@include box-sizing();
			float: left;
			padding: 0 20px 20px 40px;
			width: 50%;
		}
	}

	&__flights {
		background: $colorWhite;
		border: 1px solid $colorDivider;
		overflow: hidden;
		padding: 15px;
		margin: 0 0 20px;
		clear: both;

		&.active {
			border: 1px solid $colorPurple;
		}

		&:hover {
			cursor: pointer;
		}

		&__row {
			border-bottom: 1px solid $colorDivider;
			margin: 0 0 20px;
			overflow: hidden;

			&--tight {
				border: none;
				margin: 0;
			}
		}

		&__block {
			@include mq-min($bpDesktop) {
				@include box-sizing();
				float: left;
				padding: 0 20px 0 0;
				width: 50%;

				&--right {
					float: right;
				}
			}

			&__luggage {
				padding-left: 45px !important;
				position: relative;

				&:before {
					@include bg('/images/common/sprite.png', no-repeat, -213px -27px);
					content: "";
					display: block;
					height: 25px;
					left: 0;
					position: absolute;
					top: 0;
					width: 32px;
				}

				&__summary {
					b {
						font-family: $fontBaseMedium;
						font-weight: normal;
					}
				}
			}

			&__date {
				padding: 0 0 5px;
			}

			&__price {
				color: $colorBase;
			}

			&__label {
				color: $colorGrey;
				padding: 0 7px 0 0;
			}
		}

		&__list-item {
			padding: 0 0 15px;

			span {
				color: $colorGrey;
				font-weight: 300;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 20px;
		}

		@include mq-min($bpDesktop) {
			margin: 0 0 25px;
			padding: 25px;
		}

		@include mq-min($bpLarge) {
			margin: 0 0 30px;
			padding: 30px;
		}
	}

	&__actions {
		border-top: 1px solid $colorWhite;
		margin: 10px 0 0 0;
		padding: 15px 0 0 0;

		&__menu {
			font-size: 0;
			line-height: 0;
			margin: -10px 0 0 -10px;
			padding: 0;

			&__item {
				@include box-sizing();
				float: right;
				margin: 0;
				padding: 10px 0 0 10px;

				&--alt {
					float: left;
				}
			}
		}

		@include mq-min($bpTabletLarge) {
			padding-top: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-top: 25px;
		}

		&--alt {
			border-top: none;
			border-bottom: 1px solid $colorWhite;
			margin: 0 0 20px 0;
			padding: 0 0 15px 0;

			@include mq-min($bpTabletLarge) {
				padding-top: 0;
				padding-bottom: 20px;
			}

			@include mq-min($bpDesktop) {
				padding-top: 0;
				padding-bottom: 25px;
			}
		}
	}

	&__included {
		&__list {
			list-style: none;
			margin: 0;
			padding: 0 0 10px 15px;
		}

		&__list-item {
			list-style: none;
			margin: 0;
			padding: 0 0 5px;
			position: relative;
			z-index: 1;

			&:before {
				background: lighten($colorBase, 40%);
				@include border-radius(100%);
				content: "";
				display: block;
				height: 4px;
				left: -15px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 8px;
				width: 4px;
				z-index: 1;
			}
		}
	}

	&__extras,
	&__upgrades {
		margin: 0;

		&__item {
			display: block;
			vertical-align: top;
			width: 100%;

			&__price {
				color: $colorBase;
			}

			@include mq-min($bpTablet) {
				display: table-cell;
				width: auto;
			}
		}
	}

	&__upgrades {
		&__item {
			&--car-description {
				@include mq-min($bpTablet) {
					padding-bottom: 10px;
				}
			}

			&--car-price {
				@include mq-min($bpTablet) {
					width: 130px;
					padding-left: 10px;
				}
			}
		}
	}

	&__extras {
		&__title {
			display: none;
		}

		@include mq-min($bpTablet) {
			&__title {
				display: table-cell;
			}

			&__item--constrained {
				width: 50px;
			}

			&__item--pad-right {
				padding-right: 20px;
			}

			&__item--pad-top {
				padding-top: 10px;
			}
		}
	}

	&__login {
		&__title {
			color: $colorGrey;
			font-size: $fontSize;
			line-height: $fontHeight + 3;
			margin: 0 0 15px;

			&--tight {
				margin: 0;
			}

			span {
				color: $colorBase;
				display: block;
			}
		}

		&__forgot {
			color: $colorGrey;
			text-decoration: underline;
		}

		&__password-btn {
			@include mq-min($bpDesktop) {
				margin-top: 18px;
			}
		}
	}

	&__details {
		&__login {
			background: $infoHighlightBackgroundColor;
			box-sizing: border-box;
			margin: 0 auto 15px auto;
			padding: 8px;
			text-align: center;
			color: $colorWhite;

			a {
				color: $colorWhite;
				text-decoration: underline;

				&:hover,
				&:focus {
					color: $colorWhite;
					text-decoration: none;
				}
			}

			p {
				padding: 0;
			}
		}

		&__title {
			font-size: $fontSize;
			line-height: $fontHeight;
			padding: 5px 0 20px;

			@include mq-min($bpTablet) {
				padding: 15px 0 20px;
			}

			&--alt {
				color: $colorGrey;
				font-size: $fontSize + 2;
				line-height: $fontHeight + 2;
				padding: 0 0 20px;

				b {
					color: $colorBase;
					font-size: $fontSize;
					line-height: $fontHeight;
				}
			}
		}

		&__info {
			padding-bottom: 0;

			@include mq-min($bpTablet) {
				padding: 24px 0 0;
			}
		}
	}

	&__payment {
		&__title {
			color: $colorGrey;
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
		}

		&__question-icon {
			padding-bottom: 15px;
		}

		&__info-icon {
			float: left;
			margin-top: 10px;
			margin-right: 20px;
			font-size: 40px
		}

	}

	&__flights-select {
		padding: 0;
		margin: 0;
		position: relative;

		&__title {
			color: $colorGrey;
		}

		&__text {
			padding: 8px 0;
			font-size: $fontSize + 1;
		}

		@include mq-min($bpTabletLarge) {
			padding: 0 0 10px;
		}

		&__loader {
			background: rgba($colorWhite, .7);
			padding: 0;
			position: absolute;
			z-index: 5;
			width: 100%;
			height: 100%;

			&:before {
				@include bg($svgLoader, no-repeat, 50% 50%);
				content: "";
				display: block;
				height: 100px;
				z-index: 1;
			}
		}

	}

	&__price-info {
		margin-bottom: 20px;
	}

	&__flights-title {
		margin: 0 0 20px 0;
		padding: 0;
	}
}

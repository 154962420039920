.c-flights-properties {
    padding: 20px;
    gap: 15px;
    display: flex;
    flex-direction: column;

    &__flight {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        border-bottom: 1px solid $colorPurplePale;
        gap: 10px;

        .c-flights__route {
            width: 100%;

            @include mq-min($bpTablet) {
                flex: 1;
            }
        }

        .c-flights__details {
            @include mq-min-max($bpDesktop, $bpLarge) {
                font-size: 13px;
            }
        }

        .c-flights__details__number {
            @include mq-min($bpMobileMid) {
                display: block;
            }
        }
    }

    &__meta {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 1px solid $colorPurplePale;
            padding-bottom: 10px;
        }

        &__title {
            font-size: 15px;
        }
    }

    &__link {
        margin-bottom: 15px;

        &:before {
            font-family: $fontAwesome;
            content: "\f072";
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 5px;
        }
    }

    &__container {
        display: none;

        &--active {
            display: block;
        }
    }
}

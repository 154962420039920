﻿.m-link-list {
	font-size: 0;
	line-height: 0;
	margin: 0 0 0 3px;
	padding: 0;

	&__item {
		@include box-sizing();
		display: inline-block;
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 10px 0 0 0;
		vertical-align: middle;

		&--block {
			display: block;
		}
	}

	&--spaced {
		padding: 0 0 10px;

		@include mq-min($bpTabletLarge) {
			padding: 0 0 20px;
		}
	}

	&--border {
		border-bottom: 1px solid $colorDivider;
		padding: 0 0 10px;
		margin: 0 0 10px;

		@include mq-min($bpTabletLarge) {
			padding: 0 0 15px;
			margin: 0 0 15px;
		}
	}
}

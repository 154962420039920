﻿.l-breadcrumbs {
	&__menu {
		border-bottom: 1px solid $colorBorder;
		color: $colorBase;
		font-size: 0;
		line-height: 0;
		margin: 0 0 15px;
		padding: 0 0 10px;

		&__item {
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 0 6px 0 0;
			position: relative;
			vertical-align: top;
			z-index: 1;
		}

		&__link {
			color: $colorBase;
			text-decoration: none !important;

			&:after {
				color: $colorBase;
				content: "\f061";
				margin: 0;
				padding: 0 12px 0 12px;
				font-family: "Font Awesome 6 Pro";
				font-weight: 500;
				font-size: 17px;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 0 0 20px;
		}

		&--spaced {
			@include mq-min($bpTabletLarge) {
				margin: 0 0 30px;
			}
		}
	}
}

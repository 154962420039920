﻿.c-info {
	display: flex;
	align-content: center;
	background-color: $infoBackgroundColor;
	color: $infoFontColor;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 21px;

	.fa-solid {
		margin-right: 5px;
		font-size: 25px;
	}
}

.c-info--highlight {
	background-color: $infoHighlightBackgroundColor;
	color: $infoHighlightFontColor;
}

.c-info__icon {
	width: 23px;
	height: 23px;
	margin-right: 10px;
	flex-shrink: 0;
}



@include mq-min($bpDesktop) {

	.c-info {
		font-size: 15px;
		line-height: inherit;
	}

}

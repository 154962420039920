﻿.m-phrases__table {
	border: none !important;
	margin: 0 !important;
	overflow: hidden !important;
	min-width: 0 !important;

	&__item {
		background: none !important;
		display: block;
		width: 100%;
		@include box-sizing();

		&--phrase {
			padding: 10px 0 0 0;
			font-weight: 600;

			@include mq-min($bpTabletLarge) {
				font-weight: 300;
				padding: 5px 20px 5px 3px;
				min-width: 195px;
			}
		}

		&--translation {
			padding: 0 0 5px;

			@include mq-min($bpTabletLarge) {
				padding: 5px 3px;
			}
		}

		@include mq-min($bpTabletLarge) {
			display: table-cell;
			width: auto;
			padding-bottom: 10px;
		}
	}
}

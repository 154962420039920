.m-flex {
    display: flex;
    
    &--desktop-only {
        display:block;
        @media(min-width: $bpDesktop) {
            display: flex;
        }
    }

    &--column {
        flex-direction: column;
    }

    &--layout__main {
        flex: 1;
        height: 100%;
    }
}
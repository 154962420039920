﻿.m-folks-selector {
	display: none;
	height: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	top: 50px;
	width: 100%;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.8);
	text-align: left;
	font-family: $fontBase;
}

.m-folks-selector__trigger {
	display: flex;
	align-items: center;
}

.m-folks-selector__trigger-icon {
	display: inline-block;
	margin: 0 15px 0 2px;
	color: #7C7B7B;

	@include mq-min($bpTablet) {
		margin: 0 3px 0 5px;
	}
}

.m-folks-selector__trigger--large .m-folks-selector__trigger-icon {
	margin: 0 10px;
}

.m-folks-selector__inner {
	position: absolute;
	top: 55px;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 15px;
	box-sizing: border-box;
}

.m-folks-selector__popup {
	position: relative;
	margin: 0 auto;
	max-width: $maxPageWidthLarge;
	z-index: 10005;
	overflow: hidden;

	@include mq-min($bpTablet) {
		position: relative;
		top: 33%;
		margin: 0 auto;
		padding-bottom: 150px;
	}

	.select {
		border-color: $colorGreyMid;

		@include mq-min($bpTabletLarge) {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - 100px);
		}
	}

	label,
	label span,
	label small {
		color: $colorBase;
		font-size: 15px;
		line-height: 20px;

		@include mq-min($bpTablet) {
			display: inline;
		}
	}

	label {
		display: block;
		padding: 0 0 5px 0;
		margin-top: -3px;
		font-family: $fontBase;
		font-size: 13px;

		small {
			font-weight: 300;

			@include mq-min($bpDesktop) {
				display: block;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 8px 5px 0 0;
			display: inline-block;
			text-align: right;
		}

		@include mq-min($bpDesktop) {
			padding: 0 10px 0 0;
		}
	}
}

.m-folks-selector__popup__wrapper {
	min-height: 300px;
	padding: 20px;
	border: 1px solid $colorGreyMid;
	background: $colorWhite;
	position: relative;

	@include mq-min($bpTablet) {
		min-height: 155px;
		padding-top: 55px;
	}
}

.m-folks-selector__button {
	background-color: #FFF;
	font: inherit;
	font-size: inherit;
	color: $colorTeal;
	border: none;
	cursor: pointer;
}

.m-folks-selector__button:hover,
.m-folks-selector__button:focus {
	text-decoration: underline;
}

.m-folks-selector__popup__actions__contact {
	font-size: 15px;
	line-height: 25px;
	padding: 10px 0;
	display: none;
	color: $colorBase;

	@include mq-min($bpMobileLarge) {
		float: left;
	}
}

.m-folks-selector__popup__close {
	font-size: 15px;
	line-height: 15px;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 20;
}

.m-folks-selector__popup__actions {

	@include mq-min($bpMobileLarge) {
		padding-top: 10px;
	}
}

.m-folks-selector__popup__actions__confirm {
	max-width: 180px;
	position: absolute;
	right: 20px;
	bottom: 15px;
}

.m-folks-selector__popup__actions__add {
	font-size: 15px;
	line-height: 25px;
	padding: 10px 0;
	width: 50%;
	text-transform: uppercase;

	@include mq-min($bpMobileLarge) {
		float: left;
		display: inline-block;
		width: auto;
	}
}

.m-folks-selector__popup__row__inputs-wrapper {
	// no apparant styling for this in default state
}

.m-folks-selector__popup__actions {

	@include mq-min($bpMobileLarge) {
		padding-top: 10px;
	}
}

.m-folks-selector__popup__row {
	clear: both;
	position: relative;

	&:first-child {

		.m-folks-selector__popup__row__remove {
			display: none;
		}
	}
}

.m-folks-selector__popup__row--multiple {
	padding: 0 0 15px;
	margin: 0 0 15px;
	border-bottom: 1px solid $colorBorder;
}

.m-folks-selector__popup__row__room,
.m-folks-selector__popup__row__remove {
	font-size: 15px;
	line-height: 25px;
	box-sizing: border-box;

	p {
		color: $colorBase;
		padding-bottom: 0;
		text-transform: uppercase;
		white-space: nowrap;
	}
}

.m-folks-selector__popup__row__room {
	padding: 0 0 10px 0;

	@include mq-min($bpTabletLarge) {
		width: 80px;
		display: inline-block;
		vertical-align: top;
		padding-top: 12px;
		padding-right: 10px;
	}
}

.m-folks-selector__popup__row__remove {
	margin-top: 10px;

	@include mq-min($bpTabletLarge) {
		width: 200px;
		position: absolute;
		top: 0;
		right: 0;
		text-align: right;
		z-index: 1;
	}
}

.m-folks-selector__popup__row__room-number {}

.m-folks-selector__popup__row__inputs {

	@include mq-min($bpMobileLarge) {
		margin-left: -15px;
	}

	@include mq-min($bpTabletLarge) {
		width: calc(100% - 90px);
		display: inline-block;
		vertical-align: top;
		margin-left: 0;
	}
}

.m-folks-selector__popup__row__inputs__adults,
.m-folks-selector__popup__row__inputs__children,
.m-folks-selector__popup__row__inputs__infants {
	padding: 0 0 10px;

	@include mq-min($bpMobileLarge) {
		padding: 5px 15px 5px 15px;
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
		width: 150px;
	}

	@include mq-min($bpTabletLarge) {
		width: 200px;
	}

	@include mq-min($bpDesktop) {
		width: 33.3333%;
		max-width: 235px;
	}
}

.m-folks-selector__popup__row__inputs__infants {
	border: none;
}

.m-folks-selector__popup__quantity {
	font-size: 0;
	line-height: 0;

	@include mq-min($bpDesktop) {
		vertical-align: top;
		display: inline-block;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.m-folks-selector__popup__quantity__button {
	display: inline-block;
	width: 36px;
	height: 36px;
	text-align: left;
	text-indent: -9000px;
	background: $colorPurpleLight;
	vertical-align: top;
	position: relative;
	cursor: pointer;
	-webkit-touch-callout: none;
	user-select: none;
	border: none;
	padding: 0;
}

.m-folks-selector__popup__quantity__button:hover,
.m-folks-selector__popup__quantity__button:focus {
	background-color: $colorPurple;
}

.m-folks-selector__popup__quantity__button--less {}

.m-folks-selector__popup__quantity__button--more {

	&:after {
		background-position: -162px -3px;
	}
}

.m-folks-selector__popup__quantity__value {
	height: 34px;
	width: 45px;
	display: inline-block;
	padding: 0;
	margin: 0;
	border: 0;
	text-align: center;
	appearance: textfield;
	line-height: normal;
	border-radius: 0;
	border-top: 1px solid $colorGreyMid;
	border-bottom: 1px solid $colorGreyMid;
	font-family: $fontBaseLight;
	font-size: 15px;
	color: #3B3B3B;
}

.m-folks-selector__icon {
	width: 24px;
	height: 24px;
	display: block;
	color: $colorWhite;
	margin: 0 auto;
}

// no kids

.m-folks-selector--no-kids {

	.m-folks-selector__popup__row__inputs__adults {
		border: 0;
	}

	.m-folks-selector__popup__row__inputs__children,
	.m-folks-selector__popup__row__inputs__infants {
		display: none;
	}
}

.js-folks-selector-trigger-message {
	padding: 5px 0 0 0;
	text-align: center;
}


/* When in search block */
@include mq-min($bpTablet) {
	.m-folks-selector--in-search-block {
		top: 215px;

		.m-folks-selector {
			position: absolute;
			top: 215px;
			height: auto;
			width: calc(100% + 30px);
			left: -15px;
			background: transparent;
			z-index: 101;
		}

		.m-folks-selector__inner {
			position: relative;
			top: initial;
			left: initial;
			padding: 0;
			box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
		}

		.m-folks-selector__popup__wrapper {
			border: none;
			padding-top: 40px;
			min-height: 1px;
		}

		.m-folks-selector__popup {
			padding-bottom: 0;
		}

		.m-folks-selector__popup__close {
			display: none;
		}

		.m-folks-selector__popup__actions__confirm {
			width: 100%;
		}
	}

	.m-search-block__search-results-page .m-folks-selector--in-search-block {

		.m-folks-selector--single-room .m-folks-selector__popup__row__room {
			display: none;
		}

		.m-folks-selector__popup__row__inputs {
			width: 100%;
		}
	}
}

@include mq-min($bpDesktop) {
	.m-folks-selector--in-search-block {
		.m-folks-selector {
			top: 110px;
		}
	}

	.m-search-block__search-results-page .m-folks-selector--in-search-block {
		.m-folks-selector__popup__row__inputs__children {
			padding: 5px 0;
			max-width: 215px;
		}
	}
}

@include mq-min($bpLarge) {
	.m-folks-selector--in-search-block {
		.m-folks-selector--single-room .m-folks-selector__popup__actions__confirm {
			position: absolute;
			top: 42px;
			right: 20px;
		}
	}

	.m-search-block__search-results-page .m-folks-selector--in-search-block {
		.m-folks-selector--single-room .m-folks-selector__popup__actions__confirm {
			position: relative;
			top: initial;
			right: initial;
		}

		.m-folks-selector__popup__row__inputs__children {
			max-width: 235px;
		}
	}
}

﻿.l-awards {
	margin: 0;
	padding: 0;
	background: $colorOffWhite;

	&__inner {
		@include box-sizing();
		margin: 0 auto;
		padding: 15px 10px;
		position: relative;
		max-width: $maxPageWidth;
		z-index: 2;

		@include mq-min($bpTabletLarge) {
			padding: 20px 10px;
		}

		@include mq-min($bpDesktop) {
			padding: 25px 10px;
		}

		@include mq-min($bpDesktopClear) {
			padding: 25px 0;
		}

		@include mq-min($bpLarge) {
			padding: 30px 0;
			max-width: $maxPageWidthLarge;
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -10px 0 0 -10px;
		padding: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 10px 0 0 10px;
			vertical-align: middle;

			@include mq-min($bpTabletLarge) {
				padding: 20px 0 0 20px;
			}

			@include mq-min($bpDesktop) {
				padding: 30px 0 0 30px;
			}

			@include mq-min($bpLarge) {
				padding: 30px 0 0 40px;
			}
		}

		&__image {
			display: block;
			@include filter(grayscale(100%));
			margin: 0;
			padding: 0;
			width: revert-layer;
		}

		&__link {
			&:hover {
				.l-awards__menu__image {
					@include filter(grayscale(0%));
				}
			}
		}

		@include mq-min($bpTabletLarge) {
			margin: -20px 0 0 -20px;
		}

		@include mq-min($bpDesktop) {
			margin: -30px 0 0 -30px;
		}

		@include mq-min($bpLarge) {
			margin: -30px 0 0 -40px;
		}
	}
}

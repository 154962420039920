﻿.m-my-account {
	&__header {
		padding: 15px 15px 0;

		@include mq-min($bpTabletLarge) {
			padding: 20px 20px 5px;
		}

		@include mq-min($bpDesktop) {
			padding: 25px 25px 15px;
		}

		&__title {
			&__check-icon {
				color: $colorAction;
				margin-right: 5px
			}
		}
	}

	&__content {
		padding: 0;
		margin: 0;

		&--bg {
			background: $colorOffWhite;
			margin: 0 auto 15px;
			padding: 15px;

			@include mq-min($bpMobileLarge) {
				padding: 25px;
			}

			@include mq-min($bpDesktop) {
				padding: 30px 45px 35px;
			}
		}
	}

	&__login {
		padding: 15px;
		margin: 20px auto;
		background: $colorOffWhite;

		&__forgot {
			margin: 15px 0 0;
		}

		&--equal-heights {
			@include mq-min($bpTabletLarge) {
				min-height: 395px;
			}
		}

		&__password-btn {
			@include mq-min($bpDesktop) {
				margin: 41px 0 41px;
			}

			@include mq-min($bpLarge) {
				margin: 66px 0 41px;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 27px 30px 30px;
			margin: 50px auto;
		}

		@include mq-min($bpLarge) {
			padding: 42px 45px 45px;
			margin: 60px auto;
		}

		&--tight {
			margin-bottom: 0;
		}
	}

	&__details {
		margin: 0;
		padding: 0;
		font-size: $fontSize;
		line-height: $fontHeight;

		&__info-icon {
			float: left;
			margin-top: 10px;
			margin-right: 20px;
			font-size: 20px
		}

		&__title {
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
			padding: 0 0 5px;

			&--alt {
				padding: 15px 0;
			}

			&--alt-first {
				padding: 0 0 15px;
			}
		}

		&__summary,
		&__address,
		&__list {
			padding: 0 0 20px;
			overflow-wrap: break-word;
		}

		&__summary--alt {
			padding: 0;
		}

		&__list--tight {
			padding: 0;
		}

		&__preferences-list {
			padding: 0;

			&-item {
				padding-left: 23px;
				position: relative;

				&:before {
					@include bg('/images/common/sprite.png', no-repeat, -143px 0px);
					content: "";
					height: 16px;
					left: 0;
					position: absolute;
					top: 4px;
					width: 16px;
					z-index: 1;
				}

				&--active {
					&:before {
						background-position: -125px 0px;
					}
				}
			}
		}

		&__personal {
			&__name {
				padding: 0 0 15px 0;

				&__label {
					color: $colorGrey;
					font-size: 16px;
					line-height: 24px;
					padding-bottom: 5px;
				}

				&__data {
					line-height: 24px;
					padding-bottom: 5px;
				}
			}
		}

		&__status {
			&--success {
				background: $colorGreen;
				padding: 10px;
				color: black;
			}

			&--fail {
				background: $colorRed;
				padding: 10px;
				color: $colorOffWhite;
			}
		}
		
	}

	&__content {
		padding: 0;
		margin: 0;

		&--bg {
			background: $colorOffWhite;
			margin: 0 auto 15px;
			padding: 15px;

			@include mq-min($bpMobileLarge) {
				padding: 25px;
			}

			@include mq-min($bpDesktop) {
				padding: 30px 45px 35px;
			}
		}
	}

	&__passcodes {
		&__property {
			padding: 5px 0;

			&__key {
				width: 25%;
				display: inline-block;
				padding-right: 10px;
			}

			&__value {
				display: inline-block;
			}
		}

	}
}

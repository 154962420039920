﻿.l-newsletter {
    background: $colorPurplePale;
    margin: 0;
    padding: 0;

    &__inner {
        @include box-sizing();
        margin: 0 auto;
        padding: 10px;
        position: relative;
        max-width: $maxPageWidth;
        z-index: 2;

        @include mq-min($bpTabletLarge) {
            padding: 15px 10px;
        }

        @include mq-min($bpDesktop) {
            padding: 20px 10px;
        }

        @include mq-min($bpDesktopClear) {
            padding: 20px 0;
        }

        @include mq-min($bpLarge) {
            padding: 25px 0;
            max-width: $maxPageWidthLarge;
        }
    }

    &__content {
        font-size: $fontSize + 2;
        line-height: $fontHeight + 2;
        margin: 0 auto;
        padding: 0 0 5px 0;
        max-width: 620px;
    }

    &__title {
        font-family: $fontBaseLight;
        font-size: $fontSize + 7;
        line-height: $fontHeight + 7;
    }

    &__form {
        font-size: 0;
        line-height: 0;
        margin: -10px 0 0 -10px;
        padding: 0 0 5px 0;
        text-align: center;

        &__form {
            max-width: 460px;
            margin: 0 auto;

            @include mq-min($bpTablet) {
                max-width: 100%;
                margin: 0;
            }
        }

        &__row {
            @include box-sizing();
            display: inline-block;
            margin: 0;
            padding: 10px 0 0 10px;
            text-align: left;
            vertical-align: top;
            width: 100%;

            .form-text,
            .form-select,
            .select,
            .form-checks {
                max-width: 100%;
            }

            @include mq-min($bpTabletLarge) {
                padding: 15px 0 0 15px;
            }

            &--title {
                width: 100%;

                @include mq-min($bpTablet) {
                    max-width: 16%;
                }

                @include mq-min($bpTabletLarge) {
                    max-width: 12%;
                }

                @include mq-min($bpDesktop) {
                    max-width: 10%;
                }
            }

            &--othertitle {
                width: 100%;

                @include mq-min($bpTablet) {
                    max-width: 68%;
                }

                @include mq-min($bpTabletLarge) {
                    max-width: 18%;
                }

                @include mq-min($bpDesktop) {
                    max-width: 15%;
                }
            }

            &--name,
            &--surname {
                width: 50%;

                @include mq-min($bpTablet) {
                    max-width: 42%;
                }

                @include mq-min($bpTabletLarge) {
                    max-width: 26%;
                }

                @include mq-min($bpDesktop) {
                    max-width: 20%;
                }
            }

            &--email {
                @include mq-min($bpTabletLarge) {
                    max-width: 35%;
                }

                @include mq-min($bpDesktop) {
                    max-width: 28%;
                }
            }

            &--opt-in {
                width: 100%;

                @include mq-min($bpTabletLarge) {
                    padding-top: 18px;
                    width: 70%;
                }

                @include mq-min($bpTabletLarge) {
                    padding-top: 25px;
                }

                @include mq-min($bpDesktop) {
                    padding-top: 25px;
                    width: 58%;
                }
            }

            &--action {
                width: 100%;

                .form-button {
                    width: 100%;
                }

                @include mq-min($bpTabletLarge) {
                    text-align: right;
                    width: 30%;
                }

                @include mq-min($bpDesktop) {
                    width: 20%;
                }
            }
        }

        @include mq-min($bpTabletLarge) {
            margin: -15px 0 0 -15px;
        }
    }
}

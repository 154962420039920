// clear
.clear {
	clear: both;
}

// visibility
.offscreen {
	height: 0;
	left: -9999px;
	margin: 0;
	padding: 0;
	position: absolute;
	@include text-hidden();
	width: 0;
	z-index: -1;
}

.show {
	display: block;
}

.hide {
	display: none;
}

.hide-on-mobile {
	@include mq-max($bpMobileLarge - 1) {
		display: none;
	}
}

.hide-on-mobile-large {
	@include mq-min($bpMobileLarge) {
		@include mq-max($bpTablet - 1) {
			display: none;
		}
	}
}

.hide-on-mobile-large-or-less {
	@include mq-max($bpMobileLarge - 1) {
		display: none;
	}
}

.hide-on-mobile-large-or-more {
	@include mq-min($bpMobileLarge) {
		display: none;
	}
}

.hide-on-tablet {
	@include mq-min($bpTablet) {
		@include mq-max($bpTabletLarge - 1) {
			display: none;
		}
	}
}

.hide-on-tablet-or-less {
	@include mq-max($bpTabletLarge - 1) {
		display: none;
	}
}

.hide-on-tablet-or-more {
	@include mq-min($bpTablet) {
		display: none;
	}
}

.hide-on-tablet-mid {
	@include mq-min($bpTabletMid) {
		@include mq-max($bpTabletLarge - 1) {
			display: none;
		}
	}
}

.hide-on-tablet-mid-or-less {
	@include mq-max($bpTabletMid - 1) {
		display: none;
	}
}

.hide-on-tablet-mid-or-more {
	@include mq-min($bpTabletMid) {
		display: none;
	}
}

.hide-on-tablet-large {
	@include mq-min($bpTabletLarge) {
		@include mq-max($bpDesktop - 1) {
			display: none;
		}
	}
}

.hide-on-tablet-large-or-less {
	@include mq-max($bpTabletLarge - 1) {
		display: none;
	}
}

.hide-on-tablet-large-or-more {
	@include mq-min($bpTabletLarge) {
		display: none;
	}
}

.hide-on-desktop-or-less {
	@include mq-max($bpDesktop - 1) {
		display: none;
	}
}

.hide-on-desktop-or-more {
	@include mq-min($bpDesktop) {
		display: none;
	}
}

.hide-on-large {
	@include mq-min($bpLarge) {
		display: none;
	}
}

.hide-on-large-or-less {
	@include mq-max($bpLarge - 1) {
		display: none;
	}
}

// touch
.hide-on-touch {
	.touchevents & {
		display: none;
	}
}

.show-on-touch {
	.no-touchevents & {
		display: none;
	}
}

// js
.no-border-on-js {
	border-right: 0;
}


// alignment
.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.align-justify {
	text-align: justify;
}

// width restrict
.content-width {
	max-width: $maxContentWidth;

	&--centered {
		margin: 0 auto;
	}
}

// screen readers
.u-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Colours

.u-color-highlight {
	color: $helperColorHighlight
}

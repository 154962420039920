﻿.m-booking-help {
	background: $colorOffWhite;
	margin: 0 0 15px 0;
	padding: 15px;
	text-align: center;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__title {
		font-size: $fontSize + 6;
		line-height: $fontHeight + 6;
		margin: 0;
		padding: 0;
	}

	&__summary {
		margin: 0;
		padding: 10px 0 0 0;
	}

	&__phone {
		display: block;
		font-family: $fontBase;
		font-size: $fontSize + 6;
		line-height: $fontHeight + 6;
		margin: 0;
		padding: 10px 0 0 0;
	}

	&__email {
		display: block;
		font-family: $fontBase;
		margin: 0;
		padding: 10px 0 0 0;
	}

	@include mq-min($bpTabletLarge) {
		padding: 20px;
	}

	@include mq-min($bpDesktop) {
		padding: 25px;
	}

	@include mq-min($bpLarge) {
		padding: 30px;
	}

	&--block {
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
	}

	&--tight {
		margin-bottom: 0;
	}
}

@use 'sass:math';


$imageHeight_desktop: 750px;
$imageHeight: 450px;

$height_desktop: $imageHeight_desktop + 100;
$height_tablet: $imageHeight + 250;

.m-banner-hero {
	position: relative;
	height: $imageHeight + 450;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 1120;

	@include mq-min($bpTablet) {
		height: $height_tablet;
	}

	@include mq-min($bpDesktop) {
		height: $height_desktop;
	}

	&__video {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 0;

		@include mq-min($bpTablet) {
			height: auto;
			width: 131%;
		}

		@include mq-min($bpDesktopMid) {
			width: 111%;
		}

		@mixin video-content() {
			position: absolute;
			z-index: -1;
			top: 0;
		}

		&__iframe {
			@include video-content();
			display: none;
			left: 0;
			width: 100%;
			height: 100%;

			@include mq-min($bpTablet) {
				display: block;
			}
		}

		>div>img,
		>div>picture>img {
			@include video-content();
		}
	}

	&__banner-container {
		position: absolute;
		height: $imageHeight;
		width: 100%;
		background-color: black;
		overflow: hidden;

		@include mq-min($bpDesktop) {
			height: $imageHeight_desktop;
		}

		img {
			width: 100%;
			height: $imageHeight;
			object-fit: cover;

			@include mq-min($bpDesktop) {
				height: $imageHeight_desktop;
			}
		}

		&__title {
			@mixin title {
				top: 50%;
				left: 50%;
				position: absolute;
				font-size: $fontSize + 22;
				line-height: $fontHeight + 22;
				margin: 0;
				padding: 0;
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
				transform: translate(-50%, -50%);
				width: 75%;
				font-family: $fontHeader;

				@include mq-min($bpDesktop) {
					font-size: $fontSize + 32;
					line-height: $fontHeight + 32;
				}
			}

			&--dark {
				@include title();
				color: $colorBrightGrey;
			}

			&--light {
				@include title();
				color: $colorWhite;
			}
		}

		.bx-prev {
			left: 0;
			height: 80%;

			@include mq-min($bpDesktop) {
				height: auto;
			}
		}

		.bx-next {
			right: 0;
			height: 80%;

			@include mq-min($bpDesktop) {
				height: auto;
			}
		}
	}
}

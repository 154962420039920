.m-profile-container {
    width: 375px;
}

.m-profile--popup {
    width: calc(100% - 20px);
    max-width: 375px;
    text-align: left;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }


    &__close {
        @include bg('/images/common/sprite.png', no-repeat, -70px 0);
        border: 4px solid $colorWhite;
        display: block;
        height: 16px;
        margin: 0;
        padding: 8px;
        position: absolute;
        right: 0;
        @include text-hidden();
        top: 0;
        width: 16px;
        cursor: pointer;
        z-index: 3;

        &:hover {
            @include opacity();
        }

        @include mq-min($bpTabletLarge) {
            border-width: 10px;
        }
    }

    fieldset {
        overflow: hidden;
        padding: 10px 0 20px 0;

        >div {
            display: grid;
            column-gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));

            .form-row {
                position: relative;
                padding-bottom: 5px;

                label {
                    width: 100%;
                    height: 100%;
                    padding: 10px 5px 5px 5px;
                    border-radius: 5px;
                    font-size: 14px;
                    text-align: center;
                    color: #111;
                    border: 2px solid transparent;
                    cursor: pointer;

                    span {
                        display: block;
                        width: 40px;
                        line-height: 26px;
                        margin: 0 auto 3px auto;
                        padding: 5px;
                        aspect-ratio: 1 / 1;
                        border: 2px solid #111;
                        border-radius: 100%;
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }

                input {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;

                    &:hover~label {
                        color: $colorAction;
                        border-color: $colorAction;

                        span {
                            color: $colorAction;
                            border-color: $colorAction;
                        }
                    }

                    &:checked {
                        cursor: default;
                    }

                    &:checked~label {
                        background: $colorAction;
                        border-color: $colorAction;
                        color: #fff;
                        cursor: default;

                        span {
                            border-color: #fff;
                            color: #fff;
                        }
                    }
                }

            }
        }
    }
}

@include mq-min($bpDesktop) {

    .m-profile--popup {
        width: 100%;
    }
}

@include mq-max($bpMobileMid) {
    .m-profile-container {
        width: 300px;
    }

    fieldset {
        width: 265px;
    }

    .m-profile--popup fieldset>div .form-row label {
        font-size: 12px;
    }
}

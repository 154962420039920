.l-back-to-top {
	background: $colorWhite;
	//border-top: 1px solid $colorPurplePale;
	margin: 0;
	padding: 0;

	&__inner {
		margin: 0 auto;
		padding: 0;
		max-width: $maxPageWidth;

		@include mq-min($bpLarge) {
			max-width: $maxPageWidthLarge;
		}
	}

	&__link {
		display: block;
		font-family: $fontBaseMedium;
		font-size: $fontSize - 2;
		line-height: $fontHeight - 2;
		margin: 0;
		outline: 0 !important;
		padding: 0;
		text-transform: uppercase;

		&__inner {
			color: $colorAction;
			display: inline-block;
			margin: 0 auto;
			padding: 12px 25px;
			position: relative;
			z-index: 1;

			.fa-solid:before {
				position: absolute;
				top: 7px;
				left: 0;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&--small {
		text-align: center;

		.l-back-to-top__link {
			font-family: $fontSimple;
			font-size: $fontSize - 3;
			line-height: $fontHeight - 3;
		}

		.l-back-to-top__link__inner {
			padding: 0 25px;
		}
	}
}

.m-blog-snippets {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		margin: 0;
		position: relative;
		z-index: 1;
		padding: 0 40px;

	}

	&__menu {
		font-size: 0;
		line-height: 0;
		padding: 0;
		text-align: left;
		margin: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			vertical-align: top;
			max-width: 600px;
			margin: 0;
			padding: 0;
			width: 270px;

			@include mq-min($bpDesktop) {
				width: 270px;
			}

			@include mq-min($bpLarge) {
				width: 310px;
			}

			@include mq-min($bpMobileLarge) {
				width: 50%;
			}

			@include mq-min($bpTabletMid) {
				width: 33.3333%;
			}
		}

		&__link {
			border: 1px solid $colorBorder;
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 15px;
			text-decoration: none;

			&:hover {
				color: $colorBase;
				text-decoration: none;

				.m-blog-snippets__menu__image__image {
					@include image-hover-anim();
				}
			}

			@include mq-min($bpDesktop) {
				padding: 20px;
			}
		}

		&__image {
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: relative;
			z-index: 1;

			&__image {
				display: block;
				margin: 0;
				padding: 0;
				@include image-hover();
			}
		}

		&__title {
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			margin: 0;
			padding: 10px 0 0 0;
		}

		&__info {
			margin: 0;
			padding: 5px 0 0 0;

			&__item {
				@include box-sizing();
				color: $colorGrey;
				display: inline-block;
				font-size: $fontSize;
				line-height: $fontHeight;
				margin: 0;
				padding: 0 16px 0 0;
				position: relative;
				vertical-align: top;
				z-index: 1;

				&:before {
					background: $colorGrey;
					content: "";
					display: block;
					height: 13px;
					margin: 0;
					@include opacity();
					padding: 0;
					position: absolute;
					right: 7px;
					top: 6px;
					width: 1px;
					z-index: 1;
				}

				&:last-child {
					padding-right: 0;

					&:before {
						display: none;
					}
				}
			}
		}

		&__summary {
			font-size: $fontSize;
			@include min-height(100px);
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 0 0 0;

			&__text {
				display: inline;
				margin: 0;
				padding: 0;
			}

			&__more {
				color: $colorBlue;
				display: inline;
				font-family: $fontBaseMedium;
				margin: 0;
				padding: 0;
			}
		}

	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpLarge) {
		padding-bottom: 30px;
	}

	&--tight {
		padding-bottom: 0;
	}
}

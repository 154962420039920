﻿@import "../config";

.bx
{
	&-prev, &-next
	{
		bottom: 0;
		@include box-sizing();
		display: block;
		margin: 0;
		padding: 0;
		position: absolute;
		@include text-hidden();
		top: 0;
		width: 35px;
		z-index: 1000;
		&:before
		{
			@include bg('/images/common/sprite.png', no-repeat, 0 0);
			content: "";
			display: block;
			height: 63px;
			left: 50%;
			margin: -32px 0 0 -10px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 19px;
			z-index: 1;
			.bx-small &
			{
				height: 32px;
				margin-top: -16px;
			}
			.bx-medium &
			{
				height: 47px;
				margin-top: -24px;
			}
		}
		&.disabled
		{
			cursor: not-allowed;
			@include opacity(.25);
		}
	}
	&-prev
	{
		left: 0;
		&:before
		{
			background-position: 0 -138px;
			.bx-small &
			{
				background-position: 0 -153px;
			}
			.bx-medium &
			{
				background-position: 0 -146px;
			}
		}
		.bx-break &
		{
			@include mq-min($bpDesktop + 100)
			{
				left: -40px;
			}
		}
	}
	&-next
	{
		right: 0;
		&:before
		{
			background-position: -19px -138px;
			.bx-small &
			{
				background-position: -19px -153px;
			}
			.bx-medium &
			{
				background-position: -19px -146px;
			}
		}
		.bx-break &
		{
			@include mq-min($bpDesktop + 100)
			{
				right: -40px;
			}
		}
	}
	&-pager
	{
		bottom: -30px;
		font-size: 0;
		left: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		text-align: center;
		width: 100%;
		z-index: 1000;
		&-item
		{
			display: inline-block;
			margin: 0;
			padding: 0 1px;
			&:first-child
			{
				padding-left: 0;
			}
		}
		&-link
		{
			background: $colorPurplePale;
			border: 4px solid $colorWhite;
			@include border-radius(100%);
			display: inline-block;
			height: 8px;
			margin: 0;
			padding: 0;
			width: 8px;
			&:hover, &.active
			{
				background: $colorPurpleLight;
			}
		}
	}
}

.carousel{
	&--hero{
		position: absolute;
		width: 100%;
	}

	&__slide{
		visibility: hidden;
		width: 100% !important;
		z-index: auto !important;

		&__link{
			width: 100%;
			
			&:hover{
				text-decoration: none;
			}
		}
	}

	.bx-viewport{
		height: 100% !important;
	}
}

.carousel--single{
	display: flex;

	@include mq-min($bpDesktop) {
		display: none;
	}
	
}

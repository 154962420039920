﻿.m-map-block {
	height: 280px;
	margin: 0 0 15px;
	padding: 0;
	position: relative;

	&__gmap {
		bottom: 0;
		display: block;
		height: 280px;
		position: relative;
		position: absolute;
		left: 0;
		margin: 0;
		overflow: hidden;
		padding: 0;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

﻿@import "../_config";

.m-country-regions {
	&__title {
		padding: 0 0 30px;
	}

	&__map {
		margin: 0 auto;
		padding: 0;
		position: relative;

		.fa-solid {
			position: absolute;
			top: 10px;
			right: 20px
		}
	}
}

.l-footer {
	background: $colorPurplePale;
	color: $colorBrightGrey;
	margin: 0;
	padding: 0;

	&__inner {
		@include box-sizing();
		margin: 0 auto;
		padding: 10px 10px 40px 10px;
		position: relative;
		text-align: left;
		max-width: $maxPageWidth;
		z-index: 2;

		@include mq-min($bpTabletLarge) {
			padding: 15px 10px 40px 10px;
		}

		@include mq-min($bpDesktop) {
			padding: 20px 10px 50px 10px;
		}

		@include mq-min($bpDesktopClear) {
			padding: 20px 0 50px 0;
		}

		@include mq-min($bpLarge) {
			padding: 25px 0 50px 0;
			max-width: $maxPageWidthLarge;
		}
	}

	&__copyright {
		@include box-sizing();

		h2,
		h3 {
			font-family: $fontBaseMedium;
			font-size: $fontSize + 3;
			line-height: $fontHeight - 7;
			padding-top: 15px;
			text-align: center;

			@include mq-min($bpTabletLarge) {
				text-align: left;
				padding-top: 0;
			}
		}

		p {
			font-size: $fontSize - 4;
			line-height: $fontHeight - 4;
			text-align: center;

			&:last-child {
				padding: 0;
			}

			a {
				color: $colorBrightGrey;
				text-decoration: underline;

				&:hover {
					color: $colorBrightGrey;
					text-decoration: none;
				}
			}

			@include mq-min($bpTabletLarge) {
				text-align: left;
			}
		}

		@include mq-min($bpTabletLarge) {
			float: left;
			width: 50%;
			max-width: 580px;
		}

		@include mq-min($bpDesktop) {
			max-width: 700px;
			width: 60%;
		}

		@include mq-min($bpLarge) {
			width: 65%;
		}
	}

	&__menu {
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0 auto;
		padding: 15px 0 0 0;
		text-align: center;
		max-width: 292px;

		&__item {
			@include box-sizing();
			display: inline-block;
			height: 30px;
			margin: 0 0 0 5px;
			padding: 0;
			vertical-align: middle;

			@include mq-min($bpMobileLarge) {
				margin-left: 10px;
			}

			&:first-child {
				margin-left: 0;
			}

			&--ba {
				width: 131px;
			}

			&--abta {
				width: 63px;
			}

			&--atol {
				width: 28px;
			}

			&--aito {
				background-position: -236px 0;
				width: 55px;
			}
		}

		&__link {
			display: block;
			height: 30px;
		}

		@include mq-min($bpMobileLarge) {
			max-width: 312px;
		}

		@include mq-min($bpTabletLarge) {
			float: right;
			padding: 5px 0 0;
			width: 50%;
		}

		@include mq-min($bpDesktop) {
			width: 40%;
		}

		@include mq-min($bpLarge) {
			width: 35%;
		}
	}

	&__created-by {
		clear: both;
		color: rgba($colorWhite, .7);
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
		margin: 0;
		padding: 20px 0 0 0;
		text-align: center;

		a {
			color: $colorWhite;
			color: rgba($colorWhite, .7);
			text-decoration: none;

			&:hover {
				color: $colorWhite;
				text-decoration: none;
			}
		}

		@include mq-min($bpTabletLarge) {
			/*clear: right;
			float: right;*/
			text-align: right;
			position: absolute;
			bottom: 50px;
			right: 0;
			z-index: 1;
		}
	}

	@include mq-min($bpTabletLarge) {
		border-top: 0;
	}
}

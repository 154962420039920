﻿.m-property {
	margin: 0;
	padding: 0;

	&__colOffset {
		padding-left: 12px !important;
	}

	&__season-content {
		background-color: #e7ecf2;
		padding: 15px;
		margin: 0 0 15px 0;

		p {
			padding: 0;
		}
	}

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__header {
		font-size: 0;
		@include min-height(36px);
		line-height: 0;
		margin: 0 0 15px 0;
		padding: 5px 0 0 60px;
		position: relative;
		z-index: 2;

		&__title,
		&__location {
			display: block;
			margin: 0;
			padding: 0;

			@include mq-min($bpTablet) {
				display: inline-block;
			}
		}

		&__title {
			font-family: $fontBase;
			font-size: $fontSize + 10;
			line-height: $fontHeight + 10;

			@include mq-min($bpTablet) {
				padding-right: 10px;
			}
		}

		&__location {
			color: $colorGrey;
			font-family: $fontBaseLight;
			font-size: $fontSize;
			line-height: $fontHeight;

			&__link {
				color: $colorGrey;

				&:hover {
					color: $colorGrey;
					text-decoration: underline;
				}
			}
		}

		&__collection {
			max-height: 41px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			max-width: 50px;
			z-index: 1;

			&__image {
				display: block;
				margin: 0;
				max-height: 41px;
				max-width: 50px;
			}

			@include mq-min($bpDesktop) {
				&:hover {
					.m-help-overlay {
						display: block !important;

						&__close {
							display: none;
						}
					}
				}
			}
		}

		&--no-collection {
			padding-left: 0;
		}

		@include mq-min($bpTabletLarge) {
			margin-bottom: 20px;
		}

		@include mq-min($bpDesktop) {
			margin-bottom: 25px;
		}

		@include mq-min($bpLarge) {
			margin-bottom: 30px;
		}
	}

	&__enquire {
		background: $colorBlue;
		margin: 0 auto 15px;
		max-width: 400px;
		padding: 25px 15px;

		&__inner {
			margin: 0;
			padding: 0;
		}

		&__title {
			color: $colorWhite;
			font-size: $fontSize + 10;
			font-family: $fontSerifAlt;
			line-height: $fontHeight + 10;
			margin: 0;
			padding: 0 0 15px;
			text-align: center;
		}

		&__summary {
			color: $colorWhite;
			margin: 0;
			padding: 0 0 15px;
		}

		@include mq-min($bpTabletLarge) {
			padding: 30px 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 35px 25px;
		}

		@include mq-min($bpLarge) {
			padding: 35px 40px 40px;
		}
	}

	&__overview {
		background: $colorOffWhite;
		padding: 20px;
		font-size: 0;
		line-height: 0;
		margin: 0 0 20px;

		&__block {
			@include box-sizing();
			font-size: $fontSize;
			line-height: $fontHeight;

			@include mq-min($bpDesktop) {
				display: inline-block;
				padding: 0 35px 0 0;
				vertical-align: top;
				width: 63%;
			}

			&--right {
				@include mq-min($bpDesktop) {
					width: 37%;
				}
			}
		}

		&__sub-title {
			color: $colorGrey;
			display: block;
		}

		&__list {
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			padding: 0 0 0 12px;

			&-item {
				position: relative;

				&:before {
					background: lighten($colorBase, 40%);
					@include border-radius(100%);
					content: "";
					display: block;
					height: 4px;
					left: -15px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 10px;
					width: 4px;
					z-index: 1;
				}
			}
		}

		&__facilities-list {
			font-size: 0;
			line-height: 0;
			text-align: center;

			&-item {
				color: $colorPurple;
				display: inline-block;
				font-size: $fontSize + 4;
				line-height: $fontHeight - 1;
				padding: 0 22px 0 0;
				vertical-align: top;

				[class^="fa"] {
					font-size: 28px;
				}


				&:last-child {
					padding: 0;
				}

			}

			@include mq-min($bpDesktop) {
				text-align: left;
			}

			&--multiples {
				text-align: left;
			}
		}

		&__multiples-link {
			padding: 15px 0;

			&__link {
				white-space: normal !important;
			}
		}
	}

	&__offer {
		background: $colorBlue;
		padding: 20px;
		margin: 0 0 20px;

		&__title {
			color: $colorWhite;
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
		}

		&__summary {
			color: $colorWhite;
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			padding: 0;
		}
	}

	&__availability {
		background: $colorPurplePale;
		color: $colorBrightGrey;
		margin: 0 auto 15px;
		max-width: 400px;
		padding: 12px 10px;
		position: relative;

		&__accommodation-only {
			text-align: center;
			padding: 10px 0 0;
			font-weight: 700;
			font-size: 16px;
		}

		&__info {
			text-align: center;
			padding: 10px 0 0;

			&__accommodation-title {
				padding-bottom: 15px;
			}

			&__accommodation {
				&__detail {
					color: $colorWhite;
					text-decoration: underline;
					padding-top: 10px;

					&:hover {
						color: lighten($colorGrey, 30%);
						text-decoration: underline;
					}
				}

				&__property {
					padding: 0;
					font-weight: 700;
				}

				&__departure {
					padding: 0;
				}

				&__departure-date {
					font-weight: 700;
				}
			}

			&--relative {
				position: relative;
			}
		}

		&--multiples {
			color: $colorBase;
			background: $colorOffWhite;
			padding-top: 2px;
		}

		&__inner {
			margin: 0;
			padding: 0;
		}

		&__title {
			color: $colorBrightGrey;
			font-size: $fontSize + 10;
			font-family: $fontSerifAlt;
			line-height: $fontHeight + 10;
			margin: 0;
			padding: 0 0 15px;
			text-align: center;
		}

		&__perperson {
			padding-top: 6px;
		}

		&__constrained {
			margin: 0 auto;
			padding: 0 10px;
			max-width: 317px;
		}

		&__total {
			color: $colorBrightGrey;
			font-size: $fontSize + 4;
			font-family: $fontBaseMedium;
			line-height: $fontHeight + 4;
			margin: 0;
			padding: 0;
			text-align: center;
		}

		&__centered {
			font-family: $fontBaseMedium;
			font-size: $fontSize - 2;
			padding: 0;
			text-align: center;

			&--spaced {
				padding: 15px 0;
			}

			&--dates-available {
				padding: 0 0 15px;
			}
		}

		&__selected {
			font-family: $fontBaseMedium;
			font-size: $fontSize - 2;
			padding: 0 0 10px;
		}

		&__price {
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			padding-top: 15px;
		}

		&__unavailable a {
			color: $colorWhite;
			font-family: $fontBaseLight;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		&__tabs {
			margin: 0 0 15px;

			&__title {
				font-size: $fontSize + 2;
				line-height: $fontHeight + 2;
			}

			&__content {
				background: $colorWhite;
				color: $colorBase;
				padding: 0;

				&--ad-reg {
					padding: 10px;
				}

				&--alt {
					text-align: center;
				}

				.m-tabs--alt .m-tabs__content {
					background: #fff;
					padding: 10px;
				}
			}

			&__summary {
				padding: 0;
				font-weight: bold;
				font-size: 17px;
			}

			&__list {
				list-style-type: none;
				padding: 0 0 0 15px;
			}

			&__list-item {
				list-style: none;
				line-height: 21px;
				padding: 0 0 3px !important;
				position: relative;
				font-size: 16px;

				&:before {
					background: $colorBase;
					@include border-radius(100%);
					content: "";
					display: block;
					height: 4px;
					left: -15px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 8px;
					width: 4px;
					z-index: 1;
				}
			}
		}


		&__booking-widget {
			position: relative;

			&--container {
				display: flex;
			}

			&__nav {
				background: $colorWhite;
				color: $colorBase;
				position: relative;
				padding: 10px 0 2px 0;

				&--multiples {
					background: none;
				}

				&__title {
					font-size: $fontSize + 5;
					line-height: $fontHeight + 5;
					position: relative;
					text-align: center;

					&--multiples,
					&--single {
						min-height: 50px;
						padding: 12px 0 0;
					}

					&:hover {
						cursor: pointer;
					}

					&:after {
						border-color: $colorGreyMid transparent transparent transparent;
						border-style: solid;
						border-width: 8px 8px 0 8px;
						content: " ";
						height: 0;
						left: 50%;
						margin: 0 0 0 -8px;
						position: absolute;
						bottom: 9px;
						width: 0;
					}

					span {
						display: block;
						font-size: $fontSize - 3;
						line-height: $fontHeight - 3;
					}

					&.active {
						&:after {
							border-color: transparent transparent $colorGreyMid transparent;
							border-style: solid;
							border-width: 0 8px 8px 8px;
						}
					}
				}

				&__link {
					left: 0;
					height: 30px;
					overflow: hidden;
					position: absolute;
					text-indent: 200%;
					top: 23px;
					visibility: hidden;
					white-space: nowrap;
					width: 40px;

					&.active {
						visibility: visible;
						z-index: 10;
					}

					.fa-solid {
						position: absolute;
						top: 0;
						left: -27px;
						font-size: 30px;
						color: #8bafbb
					}

					&--next {
						left: auto;
						right: 0;

						&:before {
							background-position: -19px -137px;
							left: auto;
							right: 10px;
						}
					}
				}
			}

			&__date {
				left: 0px;
				bottom: 0px;
				position: absolute;
				right: 0px;
				top: 0px;
				//z-index: 1;

				.day-holder {
					display: flex;
					justify-content: space-between;

					.m-help-pin {
						margin-top: 3px;
						margin-right: 3px;
					}
				}

				span {
					margin-left: 3px;
					top: 2px;
					//z-index: 2;
					cursor: default;
				}

				&.disabled {
					background: $colorOffWhite;
					cursor: default;
				}

				&.selectable,
				&.selected,
				&.selectable *,
				&.selected * {
					cursor: pointer;
				}

				&.selected {
					color: #FFF;
					background: #8896ab;
				}
			}

			&__table {
				background: $colorWhite;
				@include box-sizing();
				color: $colorBase;
				border-top: 1px solid $colorBorder;
				border-left: 1px solid $colorBorder;
				width: 100%;

				thead {
					border-bottom: 1px solid $colorBorder;
					border-right: 1px solid $colorBorder;
				}

				th {
					background: $colorOffWhite;
					@include box-sizing();
					font-size: $fontSize - 3;
					line-height: $fontHeight - 3;
					padding: 5px;
					text-align: center;
					width: 14.285714%;
				}

				td {
					border-right: 1px solid $colorBorder;
					border-bottom: 1px solid $colorBorder;
					@include box-sizing();
					font-size: $fontSize - 4;
					height: 0;
					line-height: $fontHeight - 4;
					padding-bottom: 13%;
					position: relative;
					vertical-align: top;
					width: 14.285714%;
				}
			}

			&__input {
				@include opacity(0);
			}

			&__label {
				bottom: 0;
				display: none;
				font-size: $fontSize - 6;
				font-size: $fontSize - 6.5;
				left: 0;
				line-height: $fontHeight - 6;
				padding: 2px;
				position: absolute;
				right: 0;
				//z-index: 2;

				&.active {
					display: block;
				}

				&.sold-out-price {
					background: $colorRed !important;
					color: $colorWhite;
				}

				&.sold-out {
					background: none !important;
					color: $colorRed;
					font-size: $fontSize - 2;
					font-weight: bold;
					text-align: center;
					margin-bottom: 8px;

					@include mq-min($bpDesktop) {
						padding-top: 3px;
					}
				}

				&--check {
					color: #58595b;
				}

				&.enquire {
					@include bg('/images/common/sprite.png', no-repeat, -248px -26px);
					bottom: 3px;
					content: "";
					height: 20px;
					left: 50%;
					margin: 0px 0 0 -9px;
					text-indent: -9999px;
					width: 18px;
				}

				&--group {
					@include mq-min($bpDesktop) {
						line-height: $fontHeight - 8;
						padding: 0 1px;
					}

					@include mq-min($bpLarge) {
						line-height: $fontHeight - 6;
					}
				}
			}

			&__date-menu {
				margin: 0;
				padding: 0;
				text-align: center;

				&__link {
					color: $colorBase;

					&:hover {
						color: $colorTeal;
						text-decoration: none;
					}

					&.active {
						color: $colorGreyLight;
					}
				}
			}

			&__plane {
				position: absolute;
				bottom: 21px;
				left: 21px;
				width: 14px;
				height: 14px;

				@media screen and (min-width: $bpDesktop) and (max-width: $bpLarge) {
					width: 12px;
					height: 12px;
					left: 19px;
				}

				&--alternative {
					color: $bookingWidgetAlternativePlaneColor;
				}
			}

			&__overlay.m-help-overlay {
				padding: 15px 20px 15px 15px;

				p {
					padding: 0;

					@media screen and (max-width: 375px),
					(min-width: $bpDesktop) and (max-width: $bpLarge) {
						font-size: 14px;
					}
				}
			}

			&__help-link {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 3;
			}

			&--multiples {
				&__title {
					color: $colorTeal;
					cursor: pointer;
					font-size: $fontSize - 2;
					line-height: $fontHeight - 2;
					padding: 0;
					text-align: center;

					&:hover {
						color: $colorBlue;
					}
				}

				&__detail {
					color: $colorWhite;

					&:hover {
						color: $colorWhite;
						text-decoration: underline;
					}
				}
			}

			@include mq-min($bpDesktop) {
				min-height: 340px;
				overflow: hidden;
				@include transition(min-height .5s);
			}

			@include mq-min($bpLarge) {
				min-height: 368px;
			}
		}

		&__loader {
			background: rgba($colorWhite, 0.8);
			bottom: 0;
			display: none;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 10;

			&:before {
				@include bg($svgLoader, no-repeat, 0 0);
				content: "";
				height: 43px;
				left: 50%;
				margin: -22px 0 0 -20px;
				position: absolute;
				top: 50%;
				width: 40px;
				z-index: 15;
			}
		}

		&--tight {
			margin-bottom: 0;
		}
	}

	&__total {
		background: $colorOffWhite;
		margin: 0 auto 15px;
		max-width: 400px;
		padding: 25px 15px;
		position: relative;
		text-align: center;

		&:before {
			border-color: $colorBlue transparent transparent;
			border-style: solid;
			border-width: 16px 13px 0;
			content: "";
			height: 0;
			left: 50%;
			margin: 0 0 0 -13px;
			position: absolute;
			top: 0;
			width: 0;
			z-index: 1;
		}

		&__inner {
			margin: 0;
			padding: 0;
		}

		&__summary {
			margin: 0;
			padding: 0 0 15px;
		}

		@include mq-min($bpTabletLarge) {
			padding: 30px 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 35px 25px;
		}

		@include mq-min($bpLarge) {
			padding: 35px 40px 40px;
		}
	}

	&__widget-form {
		text-align: center;

		@include mq-min($bpDesktop) {
			position: sticky;
			top: -570px;
		}

		&__wrapper {
			text-align: left;
			display: inline-block;

			@include mq-min($bpDesktop) {
				display: block;
				margin: 0;
			}
		}
	}

	&__terms {
		&__link {
			color: $colorBase;
			text-decoration: underline;

			&:hover {
				color: $colorLinkHover;
			}
		}
	}
}

.m-collection {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		margin: 0;
		position: relative;
		z-index: 1;
		padding: 0 40px;

		@include mq-min($bpDesktop + 100) {
			padding: 0;
		}

		.bx {

			&-prev,
			&-next {
				height: 141px;
				margin: 0;
				top: 0;

				@include mq-min($bpDesktop) {
					height: 154px;
				}

				@include mq-min($bpLarge) {
					height: 174px;
				}
			}
		}

		.bx-wrapper {
			max-width: $maxPageWidthLarge !important;
		}

		.bx-viewport {
			height: 100% !important;
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		padding: 0;
		text-align: left;
		margin: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			vertical-align: top;
			max-width: 600px;
			margin: 0;
			padding: 0 0 10px 0;
			width: 211px;

			@include mq-min($bpDesktop) {
				width: 231px;
			}

			@include mq-min($bpLarge) {
				width: 261px;
			}

			@include mq-min($bpMobileLarge) {
				width: 50%;
			}

			@include mq-min($bpTabletMid) {
				width: 33.3333%;
			}

			@include mq-min($bpDesktop) {
				width: 25%;
			}
		}

		&__link {
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 0;
			text-decoration: none;

			&:hover {
				color: $colorBase;
				text-decoration: none;

				.m-collection__menu__image__summary {
					.no-touchevents & {
						@include opacity(1);
					}
				}
			}
		}

		&__image {
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;

			&__image {
				display: block;
				margin: 0;
				padding: 0;
				z-index: 1;
			}

			&__summary {
				background: $colorPurpleLight;
				background: rgba($colorPurpleLight, .8);
				bottom: 0;
				left: 0;
				margin: 0;
				@include opacity(0);
				padding: 0;
				position: absolute;
				right: 0;
				top: 0;
				@include transition(all .5s);
				z-index: 2;

				&:before {
					border: 1px solid $colorWhite;
					bottom: 14px;
					display: block;
					left: 14px;
					content: "";
					margin: 0;
					padding: 0;
					position: absolute;
					right: 14px;
					top: 14px;
					z-index: 0;
				}

				&__inner {
					bottom: 30px;
					color: $colorWhite;
					display: block;
					font-family: $fontBase;
					font-size: $fontSize + 2;
					left: 30px;
					line-height: $fontHeight + 2;
					margin: 0;
					padding: 0;
					position: absolute;
					text-align: center;
					right: 30px;
					top: 30px;
					z-index: 1;

					.csstransforms & {
						bottom: auto;
						top: 50%;
						@include transform(translateY(-50%));
					}
				}
			}
		}

		&__title {
			display: block;
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			margin: 0;
			padding: 10px 0 0 0;
			text-align: left;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpLarge) {
		padding-bottom: 30px;
	}

	&--tight {
		padding-bottom: 0;
	}
}

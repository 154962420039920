﻿.c-search-map {
	height: 400px;
	height: 80vh;
	min-height: 575px;
	background: $colorPurplePale;
	padding: 0 10px;

	&__icon-wrapper {
		position: relative;
		display: block;
		width: 35px;
		height: 52px;

		img {
			width: 35px;
		}

		&__img {
			position: absolute;
			top: 0;
			left: 0;

			&:last-child {
				.map-icon-open & {
					display: none;
				}
			}
		}

		&__text {
			position: absolute;
			display: block;
			width: 100%;
			text-align: center;
			top: 25%;
			font-weight: bold;
			font-family: "FuturaBT-light", Helvetica, Arial, sans-serif;
			font-size: 13px;
			color: white;
		}
	}

	&__fullscreen-wrapper {
		display: block;
		position: fixed;
		transform: translate(-50%, -50%);
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
	}
}

// Accordion implementation for Passenger Details on My Account
$svgCaretDown: '/images/common/svgCaretDown.svg';
$svgCaretUp: '/images/common/svgCaretUp.svg';

.st-accord {
    background-color: $colorOffWhite;
    color: $colorBase;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: 2px solid $colorPurplePale;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    text-align: left;
    outline: none;
    transition: 0.4s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    div {
        display: flex;
        align-items: center;
    }

    &--active {
        background-color: $colorPurplePale;

        .st-accord__icon {
            transform: rotate(0deg);
        }
    }

    &:hover {
        background-color: $colorPurplePale;
    }

    &__panel {
        padding: 18px;
        display: none;
        background-color: $colorPurplePale;
        overflow: hidden;
    }

    h3 {
        font-size: 18px;
        font-weight: 300;
        line-height: 31px;

        @include mq-min($bpMobileLarge) {
            font-size: 22px;
        }
    }

    &__icon {
        transform: rotate(180deg);
        color: $colorBlue;
        font-size: 21px;
    }

    &__status {
        @mixin st-accord__status {
            font-size: 16px;
            font-weight: 600;
            padding-left: 23px;
            position: relative;
            margin-left: 8px;
            margin-right: 8px;
            font-family: "FuturaBT-Light", "FuturaBT-Book", Helvetica, Arial, Verdana, sans-serif;

            @include mq-min($bpMobileLarge) {
                font-size: 18px;
            }

            &:before {
                font-family: $fontAwesome;
                content: "\f05a";
                color: $colorTeal;
                padding: 2px;
                width: 18px;
            }
        }

        &--incomplete {
            @include st-accord__status;
            color: $colorRed;
        }

        &--complete {
            @include st-accord__status;
            color: $colorTeal;

            &:before {
                font-family: $fontAwesome;
                content: "\f00c";
                color: $colorTeal;
                padding: 2px;
                width: 18px;
            }
        }
    }
}

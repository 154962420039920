.l-page {
	// See critical.scss for .l-page base css

	&__inner {
		@include box-sizing();
		margin: 0 auto;
		padding: 10px;
		position: relative;
		text-align: left;
		max-width: $maxPageWidth;
		z-index: 10;

		@include mq-min($bpTabletLarge) {
			padding: 15px 10px;
		}

		@include mq-min($bpDesktop) {
			border-top: 1px solid $colorDivider;
			padding: 20px 0;
		}

		@include mq-min($bpLarge) {
			padding: 25px 0;
			max-width: $maxPageWidthLarge;
		}
	}

	&__break {
		margin: 0;

		@include mq-min($bpDesktop) {
			margin: 0;
		}

		@include mq-min($bpDesktopClear) {
			margin: 0;
		}
	}

	&__fade {
		background: rgba($colorWhite, .2);
		bottom: 0;
		display: none;
		left: 0;
		margin: 0;
		padding: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 9999;

		@include mq-min($bpDesktop) {
			display: none !important;
		}

	}

}

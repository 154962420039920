.m-toggle-block {
	position: relative;
	margin-bottom: 15px;

	&--centered {
		text-align: center;
	}

	&__inner {
		margin: 0;
		padding: 0;
		height: 0;
		overflow: hidden;

		&.active {
			height: auto;
		}
	}

	&__content {
		padding: 15px 0;

		&--tight {
			padding: 15px 0 0;
		}

		&--landing {
			padding: 0 15px 15px;
		}

		&--constrained {
			max-width: 800px;
			margin: 0 auto;
		}

		&--align-left {
			text-align: left !important;
		}
	}

	&__title {
		color: $colorTeal;
		font-family: $fontBaseMedium;
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
		margin: 0;
		padding: 0 0 0 15px;
		position: relative;
		cursor: pointer;

		&:hover {
			color: $colorBase;

			&:before {
				border-color: transparent transparent transparent $colorBase;
			}
		}

		&.active {
			&:before {
				border-color: $colorBlue transparent transparent transparent;
				border-width: 8px 4px 0 4px;
			}

			&:hover {
				&:before {
					border-color: $colorBase transparent transparent transparent;
					border-width: 8px 4px 0 4px;
				}
			}
		}

		&--small {
			color: $colorTeal;
			font-family: $fontBase;
			cursor: pointer;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 2;
			padding: 0;
			text-align: center;

			&:hover {
				color: $colorBlue;
			}
		}

		&--alt {
			font-size: $fontSize;
			line-height: $fontHeight;

			@include mq-min($bpDesktop) {
				margin-top: -20px;
			}

			&:before {
				display: none !important;
			}
		}

		&--centered {
			display: inline-block;
		}

		&:before {
			border-color: transparent transparent transparent $colorBlue;
			border-style: solid;
			border-width: 4px 0 4px 8px;
			content: " ";
			height: 0;
			left: 0;
			position: absolute;
			top: 9px;
			width: 0;
		}

	}

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
	}

	&--tight {
		margin-bottom: 0;
	}

	&--show-tablet-large {
		.m-toggle-block {
			&__inner {
				@include mq-min($bpTabletLarge) {
					height: auto !important;
					overflow: visible !important;
				}
			}

			&__content {
				@include mq-min($bpTabletLarge) {
					padding: 0;
				}
			}

			&__title {
				@include mq-min($bpTabletLarge) {
					display: none;
				}
			}
		}
	}
}

﻿.c-date-picker {
	position: absolute;
	left: 0;
	right: 0;
	top: 176px;
	/* Height of tab bar + padding top on search form */
	background-color: #FFF;
	box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
	padding: 25px;
	z-index: 99999;
	/* Needs to be above everything */
	display: none;

	&.is-active {
		display: block;

		/* Hide the top of the shadow*/
		&:before {
			content: '';
			height: 3px;
			width: 100%;
			background-color: #FFF;
			position: absolute;
			top: -3px;
			left: 0;
			right: 0;
		}
	}
}

.c-date-picker__col--month2 {
	display: none;
}

@include mq-max($bpTablet) {

	html.date-picker-open {

		body {
			overflow: hidden;
		}

		.l-page__inner {
			z-index: 1100;
		}

		.l-nav-sub {
			background-color: transparent;
		}

		.l-utils--mobile-nav {
			display: none;
		}

		.l-nav-sub__search-sub {
			top: 0;
		}
	}

	.c-date-picker.is-active {
		height: 100%;
		left: 0;
		margin: 0;
		padding: 0;
		position: fixed;
		top: 50px;
		width: 100%;
		z-index: 10000;
		background: rgba(0, 0, 0, 0.8);
	}

	.c-date-picker__calendar-wrapper {
		position: relative;
		background-color: $colorWhite;
		padding: 40px 20px;
		margin: 15px;
		height: 56%;
		top: 55px;
		overflow-y: scroll;
	}

	.c-date-picker__close-mobile {
		font-size: 15px;
		font-family: inherit;
		line-height: 15px;
		color: #72acb3;
		position: absolute;
		top: 5px;
		right: 0;
		padding: 10px 20px;
		z-index: 101;
		background-color: transparent;
		-webkit-appearance: none;
		border: none;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}


	.c-date-picker .c-date-picker__arrow--next {
		margin-left: 10px;
	}

	.c-date-picker .c-date-picker__calendar,
	.c-date-picker .c-date-picker__radio-list,
	.c-date-picker .c-date-picker__col-header {
		max-width: 320px;
	}

	.c-date-picker .c-date-picker__select-container {
		max-width: 100%;
	}

	.c-date-picker__apply {
		position: static;
		float: right;
	}
}

@include mq-min($bpTablet) {

	html.date-picker-open body {
		margin-top: 0 !important;
		/* Overrides mobile JavaScript in cases where the user changes orientation from mobile to desktop. Saves adding another listener */
	}

	.c-date-picker {
		top: 265px;
		/* Height of tab bar + padding top on search form + the height of one row of form inputs */
	}

	.c-date-picker__calendar-wrapper {
		display: flex;
	}

	.c-date-picker__col {
		flex: 1;
		padding: 0 10px;
	}

	.c-date-picker__col--month2 {
		display: block;
	}

	.c-date-picker__hide-desktop {
		display: none;
	}

	.c-date-picker__calendar-subtitle {
		margin-top: 10px;
	}

	.c-date-picker .c-date-picker__arrow--next {
		padding-left: 15px;
	}

	.c-date-picker .c-date-picker__arrow--previous {
		padding-right: 15px;
		margin-right: 0;
	}

	.c-date-picker__apply {
		position: absolute;
		bottom: 25px;
		right: 35px;
	}
}

@media screen and (min-width: $bpTablet) and (max-width: $bpTabletLarge) {
	.c-date-picker {
		padding: 10px;
	}
}

.c-date-picker__calendar-title {
	text-transform: uppercase;
	font-size: 15px;
	line-height: 39px;
	font-family: $fontBaseLight;
	margin: 0;
	padding: 0;
}

.c-date-picker__calendar-subtitle {
	font-family: $fontBaseLight;
	font-size: 15px;
}

.c-date-picker__select-container {
	border: 1px solid #dadada;
	max-width: 285px;

	select {
		font-family: $fontBaseLight;
	}
}

.c-date-picker__select {
	text-transform: uppercase;
	font-size: 18px;
	font-family: $fontBase;
}

.c-date-picker__calendar {
	max-width: 285px;
	table-layout: fixed;
	margin: 0;
}

.c-date-picker__calendar-row--header {
	border-top: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
}


.c-date-picker__calendar-cell {
	text-align: center;
	padding: 0;

	&.is-weekend {
		background-color: #f9f9f9;
	}
}

.c-date-picker__calendar-cell--header {
	font-family: $fontBaseMedium;
	font-size: 14px;
	padding: 3px 0;
}

.c-date-picker__calendar-button {
	display: block;
	width: 100%;
	background-color: transparent;
	padding: 10px 0;
	border: none;
	margin: 0;
	text-align: center;
	-webkit-appearance: none;
	font-family: inherit;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	border: 1px solid transparent;

	&.is-disabled {
		cursor: default;
		color: #CCC;
		font-weight: normal;
	}

	&.is-active:not(:empty) {
		background-color: #8896ab;
		border-color: #FFF;
		color: #FFF;
	}

	&.is-start-date:not(:empty) {
		border-color: $colorBase;
		background-color: #FFF;
		color: $colorBase;
	}

	&:focus {
		border-color: #8896ab;

		&.is-active {
			border-color: $colorBase;
		}
	}
}

.c-date-picker__radio-list {
	list-style: none;
	padding: 0;
	margin: 0;
	max-width: 285px;
}

.c-date-picker__radio-item {
	margin: 0 0 5px 0;
	padding: 0;
}

.c-date-picker__radio-label {
	display: block;
	position: relative;
	cursor: pointer;
}

.c-date-picker__radio-text {
	display: block;
	padding: 6px 14px;
	background-color: #e7ecf2;
	color: #aeb2b7;
	border: 1px solid transparent;
}

.c-date-picker__radio-input:checked~.c-date-picker__radio-text {
	background-color: #8896ab;
	color: #FFF;
}


.c-date-picker__radio-input:focus~.c-date-picker__radio-text {
	border-color: #8896ab;
}

.c-date-picker__radio-input {
	opacity: 0.001;
	position: absolute;
	left: 0;
	right: 0;
}

.c-date-picker__radio-icon {
	display: inline-block;
	vertical-align: middle;
	width: 24px;
	height: 24px;
	color: inherit;
	margin-right: 5px;
}

.c-date-picker__arrow {
	background-color: #FFF;
	padding: 0;
	border: none;
	margin: 0;
	text-align: center;
	-webkit-appearance: none;
	cursor: pointer;
	color: $colorBase;
}

.c-date-picker__arrow--previous {
	margin-right: 10px;
}

.c-date-picker__arrow--next {
	margin-left: auto;
}

.c-date-picker__col-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.c-date-picker__arrow:hover,
.c-date-picker__arrow:focus {
	color: #939598;
}

.c-date-picker__arrow-icon {
	height: 30px;
	width: 16px;
	display: block;
}

.c-date-picker__flexible-date {
	margin: 0 0 30px 0;
	font-size: 12px;
}

@include mq-min($bpDesktop) {

	.c-date-picker {
		top: 162px;
		/* Height of tab bar + padding top on search form */
	}

	.c-date-picker__col--month1 {
		padding-left: 100px;
	}

	.c-date-picker__col--month1,
	.c-date-picker__col--month2 {
		width: 35%;
	}

	.c-date-picker__col--month2 {
		padding-left: 50px;
	}

	.c-date-picker__col--length {
		width: 30%;
		padding-left: 100px;
	}

	// Search results page
	.m-search-block__search-results-page .c-date-picker__col {
		padding-left: 0;
	}

	.c-date-picker__flexible-date {
		margin-bottom: 70px;
	}
}

@include mq-min($bpLarge) {

	.c-date-picker__col--length .c-date-picker__col-inner {
		padding-left: 25px;
	}

	.c-date-picker__apply {
		width: 225px;
	}

	.l-header-sub-nav .c-date-picker__apply {
		width: 205px;
	}

	.m-search-block__search-results-page .c-date-picker__col--length .c-date-picker__col-inner {
		padding-left: 0;
	}

	.m-search-block__search-results-page .c-date-picker__apply {
		width: auto;
	}
}

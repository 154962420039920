﻿.m-destinations-list {
	display: none;
	visibility: hidden;

	@include mq-min($bpTablet) {
		background: $colorPurpleLight;
		display: block;
		padding: 10px 0;
		margin-bottom: 15px;
		visibility: visible;

		&__inner {
			padding: 0;
			margin: 0;
		}

		&__content {
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
		}

		&__col {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0 0 -15px;
			padding: 0;
			vertical-align: top;
			width: 100%;

			@include mq-min($bpMobileLarge) {
				width: 50%;
			}

			@include mq-min($bpTabletMid) {
				width: 25%;
			}
		}

		&__title {
			margin: 0;
			padding: 0;

			&__link {
				color: $colorWhite;
				display: block;
				font-size: $fontSize;
				line-height: $fontHeight;
				margin: 0;
				padding: 3px 15px;
				text-decoration: none;
				text-transform: uppercase;

				&:hover,
				&.active {
					color: $colorWhite;
				}

				@include mq-min($bpDesktop) {
					padding: 3px 20px;
				}

				@include mq-min($bpLarge) {
					padding: 3px 30px;
				}
			}
		}

		&__menu {
			margin: 0;
			padding: 0 0 15px;

			&__item {
				margin: 0;
				padding: 0;
			}

			&__link {
				color: $colorWhite;
				display: block;
				font-size: $fontSize;
				line-height: $fontHeight;
				margin: 0;
				padding: 3px 15px;
				text-decoration: none;

				&:hover,
				&.active {
					color: $colorWhite;
				}

				@include mq-min($bpDesktop) {
					padding: 3px 20px;
				}

				@include mq-min($bpLarge) {
					padding: 3px 30px;
				}
			}
		}
	}

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
		padding: 17px 0;
	}

	@include mq-min($bpLarge) {
		margin-bottom: 30px;
		padding: 25px 0;
	}

	&--tight {
		margin-bottom: 0;
	}
}

﻿.m-divider {
	padding: 0 0 15px;
	margin: 0;

	&__title {
		font-size: $fontSize + 7;
		line-height: $fontHeight + 7;
		margin: 0;
		padding: 0;
		text-align: center;
		position: relative;
	}

	@include mq-min($bpTabletLarge) {
		padding: 0 0 20px;
	}

	@include mq-min($bpDesktop) {
		margin: 0 -20px;
		padding: 0 0 25px;
	}

	@include mq-min($bpLarge) {
		margin: 0 -30px;
		padding: 0 0 30px;
	}

	&--close {
		margin-top: 0;
	}
}

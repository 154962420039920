﻿.m-grid-links {
	margin: 0;
	padding: 0 0 15px 0;
	max-width: $maxContentWidth;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__title {
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 0 0 5px 0;

		&--inline {
			@include mq-min($bpTabletLarge) {
				float: left;
				padding-top: 5px;
			}
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -15px;
		padding: 0;

		&--inline {
			overflow: hidden;
		}

		&__item {
			@include box-sizing();
			display: inline-block;
			font-family: $fontBaseMedium;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 0 0 15px;
			vertical-align: top;

			h3 {
				@include box-sizing();
				display: inline-block;
				font-family: $fontBaseMedium;
				font-size: $fontSize;
				line-height: $fontHeight;
				margin: 0;
				/*padding: 5px 0 0 15px;*/
				vertical-align: top;
			}
		}
	}

	&--alt {
		margin: 0;
		padding: 30px 0 0;
		max-width: 100%;
	}
}

﻿.m-applied-filters {
	position: relative;
	font-size: 0;
	margin-bottom: 10px;
	display: none;
}

.m-applied-filters__group {
	margin: 20px 0;
}

.m-applied-filters__item {
	display: inline-flex;
	align-items: center;
	text-align: center;
	background: $colorPurple;
	border: 1px $colorPurple solid;
	color: $colorWhite;
	font-weight: normal;
	font-family: inherit;
	font-size: 14px;
	line-height: 18px;
	padding: 5px 12px;
	margin: 0 10px 10px 0;
	-webkit-appearance: none;
	cursor: pointer;
}

.m-applied-filters__item:hover,
.m-applied-filters__item:focus {
	background: $colorWhite;
	border-color: $colorPurple;
	color: $colorPurple;
}

.m-applied-filters__icon {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
}

.m-applied-filters__clear {
	font-size: 15px;
	position: absolute;
	font-family: $fontBaseMedium;
	top: 0;
	right: 0;
	padding: 20px 0 0;
	line-height: 21px;
}

.m-applied-filters__clear-icon {
	font-weight: bold;
}

@import "config";
@import "base/forms";

//form elements fontsize
.form-text,
.form-textarea {
	font-size: $fontSize - 2;
	border: 1px solid $colorWhite;
}

// actions
.form-action {
	&--spaced {
		padding-top: 15px;

		@include mq-min($bpTabletLarge) {
			padding-top: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-top: 25px;
		}

		@include mq-min($bpLarge) {
			padding-top: 30px;
		}
	}

	&--spaced-btm {
		padding-bottom: 20px;
	}

	&--tight {
		padding: 0;
	}
}

//labels
.form-row label {
	color: $colorGrey;

	&.alt {
		color: $colorWhite;
	}
}

//optional
span.opt {
	color: $colorGreyMid;
	font-size: $fontSize;
	padding: 0 0 0 3px;
}

label.opt {
	display: inline-block;
}


//form row
.form-row {
	&--tight {
		padding: 0;
	}

	&--enquire {
		padding-top: 17px;
	}

	&--spaced {
		padding: 20px 0;

		@include mq-min($bpTabletLarge) {
			padding: 25px 0;
		}
	}

	&--spaced-bottom {
		padding: 0 0 30px 0;

		@include mq-min($bpTabletLarge) {
			padding: 0 0 35px 0;
		}
	}

	&--booking {
		padding: 15px 0;
	}

	&--border {
		border-top: 1px solid $colorWhite;
		padding-top: 17px;
		margin-top: 10px;
	}

	&--centered {
		label {
			color: $colorWhite;
			text-align: center !important;

			&.label-inline {
				font-size: $fontSize - 2;
				white-space: nowrap;
			}
		}

		&--alt label {
			color: $colorGrey;
		}
	}

	&--inline-numeric {
		margin: 0 -10px 0 0;
		padding: 0 0 20px;

		.form-row {
			float: left;
			max-width: 153px;
			padding: 0 10px 0 0;
			width: 33.3333%;
		}
	}

	&--inline-half {
		@include mq-min($bpTablet) {
			margin: 0 -20px 0 0;
			padding: 0 0 15px;

			.form-row {
				float: left;
				padding: 0 20px 0 0;
				width: 50%;

				&--info {
					float: right;
				}
			}

			&--tight {
				padding: 0;
			}
		}
	}

	&--inline-offset {
		@include mq-min($bpTablet) {
			margin: 0 -10px 0 0;
			padding: 0 0 15px;

			>.form-row {
				float: left;
				padding: 0 10px 0 0;
				width: 38%;

				&--narrow {
					width: 24%;
				}

				&--inline {
					float: none;
					width: 100%;
					padding: 0;
				}
			}

			&--tight {
				padding: 0;
			}
		}
	}

	.form-error--alt {
		max-width: 100% !important;
	}
}

// button group

.button-group {

	.form-button {
		display: inline-block;
	}

	&--right {
		text-align: right;
	}

	&--centered {
		text-align: center;
	}
}

// buttons
.form-button {
	background: $colorPurple;
	border-color: $colorPurple;
	color: $colorWhite;
	font-family: $fontBaseMedium;
	font-size: $fontSize - 3;
	line-height: $fontHeight - 7;
	padding: 10px 20px;
	text-transform: uppercase;
	z-index: 0; //set to zero so dropdowns go over the top of submit btn's

	@include mq-max($bpMobileMid) {

		font-size: 14px;
		padding: 10px 17px;
	}

	&:focus {
		outline: 1px dotted $colorPurple;
	}

	&:hover {
		background: $colorWhite;
		border-color: $colorPurple;
		color: $colorPurple;
	}

	&--secondary {
		background: white;
		border-color: $colorPurple;
		color: $colorPurple;

		&:hover {
			background: $colorPurple;
			border-color: $colorPurple;
			color: white;
		}
	}


	&--action {
		background: $colorAction;
		border-color: $colorAction;
		color: $colorWhite;

		&:hover {
			background: $colorWhite;
			border-color: $colorAction;
			color: $colorAction;
		}
	}

	&--action-inverted {
		background: $colorWhite;
		border-color: $colorAction;
		color: $colorAction;

		&:hover {
			background: $colorAction;
			color: $colorWhite;
		}
	}

	&--alt {
		&:hover {
			background-color: $colorWhite;
			border-color: $colorWhite;
		}
	}

	&--simple {
		background: none;
		border-color: $colorPurple;
		color: $colorPurple;

		&:hover {
			background: $colorPurple;
			border-color: $colorPurple;
			color: $colorWhite;
		}
	}

	&--clear {
		background: none;
		border-color: $colorWhite;
		color: $colorWhite;

		&:hover {
			background: $colorWhite;
			border-color: $colorWhite;
			color: $colorTeal;
		}
	}

	&--text {
		background: none !important;
		border-color: transparent !important;
		color: $colorLink;
		font-size: $fontSize;
		font-weight: 400;
		line-height: $fontSize;
		padding: 10px 0;
		min-width: unset;
		text-decoration: none;

		&:hover {
			color: $colorLinkHover;
			text-decoration: underline !important;
		}
	}

	&--white {
		background: $colorWhite;
		border-color: $colorWhite;
		color: $colorPurple;

		&:hover {
			background: $colorPurplePale;
			border-color: $colorPurplePale;
			color: $colorPurple;
		}
	}

	&--icon {
		background: none !important;
		border: none;
		color: $colorPurple;
		@include min-height(12px);
		padding: 5px 10px;
		position: relative;
		text-align: left;
		min-width: 30px;

		.fa-solid {
			font-size: 23px;
			margin-right: 5px;
			vertical-align: middle;
			margin-bottom: 3px;
		}

		&:hover {
			color: $colorBlue;
			@include opacity();
			text-decoration: none;
		}

		&.loading {
			&:before {
				@include bg($svgLoader, no-repeat, 50% 50%);
				background-color: $colorWhite;
				bottom: 0;
				height: auto;
				left: 0;
				margin: 0;
				right: 0;
				top: 0;
				width: auto;
			}
		}

		&--gallery {
			padding-left: 0;

			&:before {
				background-position: 0 -86px;
			}
		}

		&--video {
			padding-left: 32px;

			&:before {
				background-position: -58px -86px;
				width: 22px;
			}
		}

		&--share {
			padding-left: 25px;

			&:before {
				background-position: -23px -86px;
				width: 15px;
			}
		}

		&--email {
			padding-left: 30px;

			&:before {
				background-position: -153px -60px;
				width: 23px;
			}

			&--alt {
				padding: 11px 30px;

				&:before {
					top: 18px;
				}
			}
		}

		&--wishlist {
			padding-left: 17px;

			&:before {
				background-position: -38px -86px;
				width: 10px;
			}

			&.added {
				&:before {
					background-position: -48px -86px;
				}
			}
		}

		&--floorplan {
			padding-left: 32px;

			&:before {
				background-position: -81px -86px;
				width: 21px;
			}
		}
	}

	&--inline {
		min-width: 100px;

		@include mq-min($bpTablet) {
			min-width: 150px;
		}
	}

	&--b-marg {
		margin-bottom: 15px;

		@include mq-min($bpDesktop) {
			margin-bottom: 0;
		}
	}
}

// checks
.form-checks {
	&__item {
		line-height: 30px;

		label {
			margin: 0;
			padding: 0 0 0 34px;

			input {
				left: 0;
				position: absolute;
				opacity: 0.001;
				top: 0;

				&[type="checkbox"] {
					height: 25px;
					width: 25px;
				}

				&:checked~span {
					&:before {
						background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMzIgMzIiPjxwYXRoIGQ9Ik0yNyA0bC0xNSAxNS03LTctNSA1IDEyIDEyIDIwLTIweiIgZmlsbD0iIzU4NTk1YiI+PC9wYXRoPjwvc3ZnPg==);
						background-repeat: no-repeat;
						background-position: 50% 50%;
						background-color: $colorWhite;
					}
				}

				&:disabled~span {
					opacity: 0.4;
					cursor: default;
				}

				&[type="radio"]~span {
					&:before {
						@include border-radius(100%);
					}
				}

				&[type="radio"]:checked~span {
					&:before {
						background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjEwIj7CoCA8Y2lyY2xlIGN4PSI1IiBjeT0iNSIgcj0iNSIgZmlsbD0iIzU4NTk1YiIvPjwvc3ZnPg==);
					}
				}

				&.input-validation-error~span {
					color: $colorRed;
				}

				&.input-validation-error~span:before {
					background-color: lighten($colorRed, 50%);
					border-color: $colorRed;
				}

				&:focus~span {
					&:before {
						border-color: darken($colorBorder, 25%);
					}
				}
			}

			.accommodation-only {
				right: 0;
			}

			span:not(.accommodation-only) {
				display: block;
				@include min-height(15px);

				&:before {
					left: 0;
					top: 3px;
					position: absolute;
					height: 22px;
					width: 22px;
					z-index: 1;
					display: block;
					background: $colorWhite;
					margin: 0;
					padding: 0;
					border: 1px solid lighten($colorBorder, 10%);
					content: "";
				}
			}

			span.accommodation-only {
				display: block;
				@include min-height(15px);

				&:before {
					background: $colorWhite;
					border: 1px solid lighten($colorBorder, 10%);
					content: "";
					display: block;
					height: 22px;
					right: 0;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 3px;
					width: 22px;
					z-index: 1;
				}
			}


			&:hover {
				span {
					&:before {
						border-color: darken($colorBorder, 10%);
					}
				}

				input:disabled~span:before {
					border-color: lighten($colorBorder, 10%);
				}
			}
		}
	}

	&__item--small {
		label {
			padding-left: 25px;
		}

		label span:before {
			height: 15px;
			width: 15px;
			top: 7px;
		}

		input:checked~span:before {
			background-size: 12px 12px;
		}
	}

	&__item--small--accom-only {
		label {
			padding-right: 28px;
		}

		label span:before {
			height: 15px;
			width: 15px;
		}

		input:checked~span:before {
			background-size: 12px 12px;
		}
	}

	&--tight {
		padding: 0;
	}

	&--spaced {
		padding-top: 15px;
	}

	&--full-width {
		max-width: 100%;

		.form-checks__item {
			padding: 0 0 15px;
		}
	}
}

//textarea
.form-textarea,
.form-select {
	&--full-width {
		max-width: 100%;
	}

	&--bordered {
		border: 1px solid $colorBorder;
	}

	&--constrained {
		width: 235px;
	}
}

.ui-autocomplete {
	background: $colorWhite;
	box-sizing: border-box;
	color: $colorBase;
	font-family: $fontSimple;
	font-size: 13px;
	left: -400px;
	line-height: 16px;
	margin: 0;
	overflow-y: auto;
	padding: 0;
	position: absolute;
	text-align: start;
	top: -400px;
	width: fit-content;
	z-index: 9990;
	max-height: 200px;

	&__menu-item {
		margin: 0;
		padding: 0;
	}

	&__link {
		margin: 0;
		padding: 0;
		display: block;
		color: inherit;
		text-decoration: none;
		line-height: 27px;
		padding: 0 16px;

		&:hover {
			color: inherit;
			text-decoration: none;
		}

		&.active {
			background-color: $colorPurplePale;
			text-decoration: none;
			color: inherit;
		}
	}

	&__empty,
	&__category,
	&__more {
		color: $colorGrey;
		display: block;
		margin: 0;
	}

	&__empty {
		font-style: italic;
		padding: 5px 10px;
	}

	&__category {
		border-top: 1px solid #f0f0f4;
		font-weight: bold;
		padding: 7px 10px 3px;
	}

	&__more {
		font-size: 11px;
		font-style: italic;
		padding: 7px 16px 10px;
	}

	@include mq-min($bpDesktop) {
		max-height: 250px;
	}

	@include mq-min($bpLarge) {
		max-height: 350px;
	}
}

// jquery-ui: datepicker
.ui-datepicker {
	border: 1px solid $colorWhite;
	@include box-shadow();
	@include box-sizing();
	margin: 0;
	padding: 5px;
	z-index: 10000 !important;
	cursor: pointer;

	&.ui-widget {
		@include border-radius(0);
		width: 270px !important;
	}

	.ui-datepicker-header {
		border: 0;
		height: 35px;
		margin: 0;
		padding: 0;
	}

	.ui-datepicker-title {
		background: $colorDivider;
		color: $colorBase;
		font-family: $fontBase;
		font-size: $fontSize - 1;
		font-weight: 700;
		height: 29px;
		line-height: $fontHeight - 1;
		margin: 0;
		padding: 6px 35px 0 35px;
		text-align: center;
		text-transform: uppercase;
	}

	.ui-datepicker-next,
	.ui-datepicker-prev {
		background: none;
		border: 0;
		@include border-radius(0);
		cursor: pointer;
		height: 35px;
		top: 0;
		width: 35px;

		&.ui-state-disabled {
			visibility: hidden;
		}

		&.ui-state-hover {
			background: lighten($colorDivider, 5%);
		}
	}

	.ui-datepicker-prev {
		left: 0;
	}

	.ui-datepicker-next {
		right: 0;
	}

	.ui-datepicker-calendar {
		border: 0;
		border-collapse: collapse;
		margin: 0;
		padding: 0;
		table-layout: fixed;

		thead {
			tr {

				th,
				td {
					background: $colorBase;
					color: $colorWhite;
					font-family: Verdana, sans-serif;
					font-size: $fontSize - 5;
					font-weight: 400;
					height: 30px;
					line-height: $fontHeight - 5;
					margin: 0;
					padding: 0;
					text-align: center;
					vertical-align: middle;
					//width: 25px;
				}
			}
		}

		tbody {
			tr {

				th,
				td {
					background: $colorWhite;
					border: 0;
					font-family: Verdana, sans-serif;
					font-size: $fontSize - 4;
					font-weight: 400;
					height: 30px;
					line-height: $fontHeight - 4;
					margin: 0;
					padding: 0;
					text-align: center;
					vertical-align: middle;

					//width: 25px;
					span {
						background: lighten($colorDivider, 5%);
						border-color: lighten($colorDivider, 5%);
						color: $colorBase;
						display: block;
						height: 25px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;
					}

					a {
						display: block;
						height: 25px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;

						&.ui-state-default {
							background: lighten($colorDivider, 5%);
							border-color: lighten($colorDivider, 5%);
							color: $colorBase;
						}

						&.ui-state-highlight {
							background: lighten($colorDivider, 5%);
							border-color: darken($colorDivider, 25%);
							color: $colorBase;
						}

						&:hover,
						&.ui-state-hover,
						&.ui-state-active {
							background: $colorPurple;
							border-color: $colorPurple;
							color: $colorWhite;
						}
					}

					&:first-child {
						a {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}

//css select

.select {
	background-color: $colorWhite;
	border: 1px solid $colorWhite;
	@include box-sizing();
	display: inline-block;
	height: 39px;
	max-width: 450px;
	position: relative;
	vertical-align: top;
	width: 100%;

	&--full-width {
		max-width: 100%;
	}

	&.select-validation-error {
		background-color: lighten($colorRed, 50%);
		border-color: $colorRed;

		select {
			color: $colorRed;
			@include placeholder-text($colorRed, rgba($colorRed, .8));

			&:focus {
				color: $colorBase;
				@include placeholder-text($colorBase, rgba($colorBase, .8));
			}
		}

		&:hover {
			border-color: $colorRed;
		}
	}

	&:hover {
		border-color: $colorBorder;
	}

	&.focus {
		border: 1px solid $colorBorder !important;
		background: $colorWhite !important;
	}

	&:before {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC4yOTkiIGhlaWdodD0iMTAuMTIzIiB2aWV3Qm94PSIwIDAgMTguMjk5IDEwLjEyMyI+ICA8cGF0aCBpZD0iUGF0aF8xIiBkYXRhLW5hbWU9IlBhdGggMSIgZD0iTS0yNzcuMTU2LDU3MGw4LjUzMSw5LjA1Miw5LjA1Mi05LjA1MiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjc3LjUyIC01NjkuNjQ2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNzA3MDcwIiBzdHJva2Utd2lkdGg9IjEiLz48L3N2Zz4=);
		background-position: 0 50%;
		background-repeat: no-repeat;
		content: "";
		display: block;
		width: 18px;
		height: 10px;
		margin: -5px 0 0 0;
		padding: 0;
		position: absolute;
		right: 15px;
		top: 50%;
		z-index: 2;
	}

	select {
		appearance: none;
		@include box-sizing();
		align-items: center;
		background: transparent !important;
		border: none !important;
		border-radius: 0 !important;
		color: $colorBase;
		cursor: pointer;
		display: block;
		font-family: $fontSimple;
		font-size: $fontSize - 2;
		height: 39px;
		justify-content: flex-start;
		line-height: $fontHeight;
		padding-left: 8px;
		padding-right: 40px;
		position: relative;
		vertical-align: top;
		width: 100%;
		outline: none;
		z-index: 3;
	}

	select::-ms-expand {
		display: none; //hide native select on older IE
	}

	option,
	optgroup {
		background: $colorWhite !important;
		color: inherit;
		font-size: $fontSize - 1;
		font-style: normal;
	}
}

.select--faux {
	overflow: hidden;
}

.select--faux__inner {
	font-size: 15px;
	line-height: 25px;
	color: $colorBase;
	padding: 7px 8px 7px 8px;
	font-family: Helvetica, Arial, Verdana, sans-serif;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	font-weight: 400;
}

/* Select--large */

.select--large {
	height: 48px;

	&:before {
		right: 15px;
	}
}

.select--large select {
	height: 48px;
	padding-right: 40px;
}

.select--large.select--faux {
	padding-right: 40px;
}

.select--large .select--faux__inner {
	line-height: 34px;
}

.select--has-icon {
	padding-left: 50px;
}

.form-field-fake-select {
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC4yOTkiIGhlaWdodD0iMTAuMTIzIiB2aWV3Qm94PSIwIDAgMTguMjk5IDEwLjEyMyI+ICA8cGF0aCBpZD0iUGF0aF8xIiBkYXRhLW5hbWU9IlBhdGggMSIgZD0iTS0yNzcuMTU2LDU3MGw4LjUzMSw5LjA1Miw5LjA1Mi05LjA1MiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjc3LjUyIC01NjkuNjQ2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNzA3MDcwIiBzdHJva2Utd2lkdGg9IjEiLz48L3N2Zz4=);
		background-position: 0 50%;
		background-repeat: no-repeat;
		position: absolute;
		top: 50%;
		margin-top: -5px;
		right: 15px;
		width: 18px;
		height: 10px;
	}

	input {
		padding-right: 40px;
	}
}

﻿.m-accordion-header {
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 17px 0;

	&.is-closed {
		.m-accordion-header__icon {
			transform: rotate(180deg);
		}
	}

	&--in-search-page {
		border-top: 1px solid rgba($colorBase, 0.3);

		.m-search-page__filter__title-label {
			border-top: 0;

			margin: 0;
		}
	}

	&__button {
		background-color: transparent;
		padding: 0;
		border: none;
		cursor: pointer;
		color: $colorBase;
		-webkit-appearance: none;
		transition: color 300ms;
	}

	&__button:hover,
	&__button:focus {
		color: $colorGrey;
	}

	&__icon {
		width: 32px;
		height: 32px;
		transition: transform 300ms;
	}

	&__icon.fa-chevron-up {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 21px;
	}
}

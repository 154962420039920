.m-booking-steps {
	border-bottom: 1px solid $colorDivider;
	border-top: 1px solid $colorDivider;
	@include box-sizing();
	margin: 0 auto;
	padding: 0;
	max-width: $maxPageWidth;

	&__menu {
		flex-direction: row;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: space-between;

		&__item {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 0;
			flex-grow: 1;
			width: auto;
		}

		&__link {
			color: $colorGrey;
			cursor: default;
			display: block;
			margin: 0;
			@include opacity(.6);
			padding: 5px;
			text-decoration: none;

			@include mq-min($bpTablet) {
				padding: 10px;
			}

			@include mq-min($bpTabletLarge) {
				padding: 15px 20px;
				text-align: left;
			}

			@include mq-min($bpDesktop) {
				padding: 15px 25px;
			}

			@include mq-min($bpLarge) {
				padding: 15px 30px;
			}

			&.complete {
				color: $colorBlue;
				@include opacity(.8);
			}

			&.active {
				background: $colorBlue;
				color: $colorWhite;
				@include opacity(1);
			}
		}

		&__step {
			margin: 0;
			padding: 0;

			@include mq-min($bpTablet) {
				font-size: $fontSize + 6;
				line-height: $fontHeight + 6;
			}
		}

		&__summary {
			display: none;
			margin: 0;
			padding: 3px 0 0 0;
			@include text-overflow();

			@include mq-min($bpTabletLarge) {
				display: block;
			}
		}
	}

	@include mq-min($bpDesktop) {
		border-bottom: 0;
	}

	@include mq-min($bpLarge) {
		max-width: $maxPageWidthLarge;
	}
}

﻿.m-home-banner__inner .bx-wrapper {
	height: 147px;

	@include mq-min($bpMobileLarge) {
		height: 226px;
	}

	@include mq-min($bpTablet) {
		height: 253px;
	}

	@include mq-min($bpDesktop) {
		height: 450px;
	}

	@include mq-min($bpLarge) {
		height: 506px;
	}
}

.m-home-banner__inner .bx-wrapper .bx-viewport {
	height: 100% !important;

}

.m-home-banner {
	margin: 0 auto -1px auto;
	padding: 0;
	position: relative;
	max-width: $maxPageWidth;
	z-index: 4;
	height: inherit;

	&__inner {
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}

	&__menu {
		margin: 0;
		padding: 0;

		&__item {
			background: $colorOffWhite;
			@include min-height(187px);
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			height: inherit;

			@include mq-min($bpMobileLarge) {
				@include min-height(226px);
			}

			@include mq-min($bpTablet) {
				@include min-height(283px);
			}

			@include mq-min($bpTabletMid) {
				@include min-height(322px);
			}

			@include mq-min($bpTabletLarge) {
				height: 361px;
			}

			@include mq-min($bpDesktop) {
				height: 450px;
			}

			@include mq-min($bpLarge) {
				height: 506px;
			}

			&--video {
				overflow: hidden;
			}
		}

		&__link {
			display: block;
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
		}

		&__image {
			display: block;
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			object-fit: cover;
			height: inherit;
			width: 100%;

			@include mq-min($bpTabletLarge) {
				height: 361px;
			}

			@include mq-min($bpDesktop) {
				height: 450px;
			}

			@include mq-min($bpLarge) {
				height: 506px;
			}
		}

		&__video {
			display: block;
			height: auto;
			left: 0;
			margin: 0;
			object-fit: cover;
			object-position: center center;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 3;
		}

		&__content {
			color: $colorWhite;
			left: 35px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 35px;
			top: 15px;
			text-align: center;
			@include text-shadow(1px 1px 1px rgba($colorBlack, .3));
			z-index: 4;

			@include mq-min($bpTabletLarge) {
				left: 50px;
				right: 50px;
				top: 50px;
			}

			@include mq-min($bpDesktop) {
				left: 70px;
				right: 70px;
				top: 70px;
			}

			&--left {
				text-align: left;
			}

			&--right {
				text-align: right;
			}

			&--bottom {
				bottom: 15px;
				top: auto;

				@include mq-max($bpMobileLarge) {
					bottom: 50px;
				}

				@include mq-min($bpMobileLarge) {
					bottom: 110px;
				}

				@include mq-min($bpDesktop) {
					bottom: 70px;
				}
			}

			&--middle {
				bottom: 35px;
				top: 35px;

				.m-home-banner__menu__title,
				.m-home-banner__menu__caption {
					left: 0;
					position: absolute;
					right: 0;
					z-index: 1;
				}

				.m-home-banner__menu__title {
					bottom: 50%;
				}

				.m-home-banner__menu__caption {
					top: 50%;
				}

				@include mq-min($bpTabletLarge) {
					bottom: 50px;
					top: 50px;
				}

				@include mq-min($bpDesktop) {
					bottom: 70px;
					top: 70px;
				}
			}

			&--dark,
			&--Dark {
				color: $colorBase;
				@include text-shadow(none);
			}
		}

		&__title {
			font-size: $fontSize + 5;
			line-height: $fontHeight + 5;
			margin: 0;
			padding: 0;
			text-transform: uppercase;

			@include mq-min($bpTabletLarge) {
				font-size: $fontSize + 13;
				line-height: $fontHeight + 13;
			}
		}

		&__caption {
			font-family: $fontBase;
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			margin: 0;
			padding: 5px 0 0 0;

			@include mq-min($bpTabletLarge) {
				font-size: $fontSize + 1;
				line-height: $fontHeight + 1;
			}
		}
	}

	&__play {
		@include bg('/images/common/sprite.png', no-repeat, -104px -138px);
		display: block;
		height: 49px;
		left: 50%;
		margin: -25px 0 0 -25px;
		padding: 0;
		position: absolute;
		@include text-hidden();
		top: 50%;
		width: 49px;
		z-index: 3;

		@include mq-min($bpTabletLarge) {
			background-position: -38px -138px;
			height: 66px;
			margin: -33px 0 0 -33px;
			width: 66px;
		}
	}

	@include mq-min($bpLarge) {
		max-width: $maxPageWidthLarge;
	}
}

﻿.m-documents {
	margin-bottom: 30px;

	&__menu {
		display: grid;
		grid-gap: 5px;
		grid-template-columns: 1fr 1fr;
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -15px;
		padding: 0;

		&__item {
			@include box-sizing();
			display: block;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 3;
			margin: 0;
			padding: 20px 0 0 15px;
			width: 100%;
		}

		&__link {
			align-items: center;
			@include box-sizing();
			color: $colorBase;
			display: block;
			display: flex;
			@include min-height(36px);
			margin: 0;
			@include opacity(.8);
			padding: 0 0 0 40px;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:before {
				@include bg("/images/common/sprite.png", no-repeat, -214px -60px);
				content: "";
				display: block;
				height: 36px;
				left: 0;
				@include opacity(.7);
				position: absolute;
				top: 0;
				width: 28px;
				z-index: 1;
			}

			&:hover {
				color: $colorBase;
				@include opacity(1);
				text-decoration: none;
			}
		}

		&:not(:first-child) {
			padding-top: 10px;
		}

		&:not(:last-child) {
			padding-bottom: 20px;
		}
	}

	&__countdown {
		color: $colorTeal;
		font-family: $fontBaseMedium;
		font-size: $fontSize - 2;
		line-height: $fontHeight - 3;
		margin: 0;
		padding: 0;

		b {
			font-weight: 400;
		}
	}
}

﻿.m-pager {
	margin: 0;
	padding: 0;

	&--dynamic {
		padding-top: 10px;
		text-align: right;
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 7px 0 0;

		&--right {
			text-align: right;
		}

		&__item {
			@include box-sizing();
			color: $colorBase;
			display: none;
			font-size: $fontSize;
			font-weight: 300;
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 0 0 5px;
			vertical-align: top;
			display: inline-block;

			&--prev,
			&--next {
				display: inline-block;
			}

			@include mq-max($bpLarge) {
				font-size: 15px;
			}
		}

		&__link {
			background: $colorDivider;
			color: $colorBase;
			cursor: pointer;
			display: block;
			font-family: $fontBaseMedium;
			margin: 0;
			min-width: 20px;
			padding: 4px 3px;
			text-align: center;
			text-decoration: none !important;

			&:hover,
			&.active {
				background: $colorPurple;
				color: $colorWhite;
			}

			&.active {
				background: $colorPurple;
			}

			&.disabled {
				color: $colorGrey !important;
				cursor: not-allowed;
				@include opacity(.5);
			}

			&--prev,
			&--next {
				color: $colorTeal;
				background: transparent !important;
				font-size: $fontSize + 1;
				font-weight: 300;
				line-height: $fontHeight + 1;

				&:hover {
					color: $colorPurple;
				}
			}

			@include mq-max($bpLarge) {
				font-size: 15px;
				padding: 4px 2px;
			}
		}

		&__range {
			background: transparent;
			display: block;
			margin: 0;
			padding: 8px;
			text-align: center;

			@include mq-max($bpLarge) {
				font-size: 15px;
				padding: 8px 1px;
			}
		}
	}
}

.popupFade-new,
#popupFade {
	backdrop-filter: blur(0.6px);
	background: rgba($colorWhite, 0.6);
	display: none;
	height: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9990;

	&.active {
		display: block;
	}
}

.m-popup,
.m-popup-new {
	@include box-shadow(0 0 15px rgba($colorBlack, .25));
	@include box-sizing();
	background: $colorWhite;
	display: none;
	left: 50%;
	position: absolute;
	top: 50px;
	transform: translate(-50%, 0);
	z-index: 9999;
	width: 100%;
	max-width: 95%;

	@include mq-min($bpTabletLarge) {
		width: 760px;
	}

	@include mq-min($bpDesktop) {
		width: 960px;
	}

	@include mq-min($bpDesktopClear) {
		width: 1000px;
	}

	@include mq-min($bpLarge) {
		width: $maxPageWidthLarge;
	}

	@include mq-min($bpLarge + 60) {
		width: 1130px;
	}
}

.m-popup-new {
	&.active {
		display: block;
		position: fixed;
		top: 25vh;
	}

	&__close {
		width: 30px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
		color: $colorBrightGrey;
		padding: 5px;
		cursor: pointer;

		&:after {
			font-family: $fontAwesome;
			content: "\f00d";
			margin-top: auto;
			margin-bottom: auto;
			font-size: 24px;
		}

		&:hover {
			text-decoration: none;
			color: $colorBrightGrey;
		}
	}

	&__content {
		padding: 35px 20px;
	}
}

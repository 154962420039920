.m-facebook-posts{
    border: 20px solid #e7ecf2; 
    padding: 20px; 
    margin-bottom: 20px;

    &__container {
        max-height: 300px; 
        overflow-y: auto;

        &__post {
            padding-bottom: 16px;
        }
    }
}
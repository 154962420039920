﻿.m-booking-summary {
	background: $colorOffWhite;
	margin: 0 auto 15px;
	max-width: 400px;
	padding: 15px;

	&--confirm {
		max-width: 100% !important;
		padding: 0 !important;
		background: none;
		margin: 0 0 15px;
	}

	&__left-aligned {
		margin-left: 35px
	}

	&__title {
		color: $colorGrey;

		&--alt {
			font-size: $fontSize + 1;
			line-height: $fontHeight + 1;
			text-transform: uppercase;

			span {
				color: $colorBase;
				font-size: $fontSize;
				line-height: $fontHeight;
				text-transform: none;
			}
		}
	}

	&__deposit-container {
		display: none;
	}

	&__notice {
		color: $colorRed;
		font-family: $fontBaseMedium;
		margin: -5px 0 0;
		list-style: disc inside none;

		&--alt {
			margin: 0;
			padding: 0;
		}
	}

	&__image {
		display: block;
		margin: 15px 0 20px;
	}

	&__header {
		&__title {
			font-size: $fontSize + 1;
			line-height: $fontHeight + 1;
			margin: 0;
			padding: 0 0 5px;
		}

		&__location {
			color: $colorGrey;
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;

			&--tight {
				padding: 0;
			}
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -15px 0 30px -15px;
		padding: 10px 0 0;

		&__title,
		&__data {
			@include box-sizing();
			display: inline-block;
			float: left;
			font-size: $fontSize - 1;
			font-weight: 300;
			line-height: $fontHeight - 1;
			margin: 0;
			padding: 5px 0 0 15px;
			vertical-align: top;
			width: 50%;
			/*@include mq-min($bpLarge)
			{
				width: auto;
			}*/
		}

		&__title {
			clear: both;
			color: $colorGrey;
			max-width: 175px;

			@include mq-min($bpLarge) {
				width: 50%;
			}

			&:after {
				content: ":";
				display: inline;
			}
		}

		&--tight {
			margin-bottom: 0;
		}
	}

	&__total {
		padding: 25px 0 0;
		border-top: 1px solid $colorDivider;
		position: relative;

		&__title {
			font-size: $fontSize + 3;
			line-height: $fontHeight + 3;
			padding: 0;
			position: relative;
			z-index: 2;
		}

		&__list {
			padding: 0;
			position: relative;
			z-index: 2;

			&__item__last {
				padding: 0;
			}
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			z-index: 1;
			top: 15px;
			left: -15px;
			right: -15px;
			bottom: 0;

			@include mq-min($bpMobileLarge) {
				top: 0;
				left: -25px;
				right: -25px;
				bottom: -15px;
			}
		}

		&.flash {
			&:before {
				@include animation(flash 0.35s ease-out);
				animation-iteration-count: 3;
			}
		}
	}

	&--tight {
		margin-bottom: 0;
	}

	&__confirmed {
		&__title {
			color: $colorGrey;
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			text-transform: uppercase;
		}

		&__list {
			font-size: 0;
			line-height: 0;
			margin: -15px 0 30px -15px;
			padding: 10px 0 0;
			width: 100%;
			overflow: hidden;

			&__title,
			&__data {
				@include box-sizing();
				display: inline-block;
				float: left;
				font-size: $fontSize;
				font-weight: 300;
				line-height: $fontHeight + 4;
				margin: 0;
				padding: 5px 0 0 15px;
				vertical-align: top;
				width: 50%;

				&__passengers {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					padding: 0 0 10px;

					&:last-child {
						padding: 0;
					}

					&__name {
						padding-right: 4px;
					}
				}

				&__upload-link {
					font-weight: 600;
					padding-left: 23px;
					position: relative;

					a {
						color: $colorRed;
					}

					&:before {
						font-family: $fontAwesome;
						content: "\f05a";
						color: $colorTeal;
					}

					&--complete {
						a {
							color: $colorTeal;
						}

						&:before {
							font-family: $fontAwesome;
							content: "\f00c";
							color: $colorTeal;
						}
					}
				}

				@include mq-min($bpLarge) {
					width: auto;
					max-width: 50%;
				}
			}

			&__title {
				clear: both;
				color: $colorGrey;
				max-width: 250px;

				@include mq-min($bpLarge) {
					width: 50%;
				}
			}
		}

		&__flights-list {
			font-size: 0;
			line-height: 0;
			margin: 0 0 15px -15px;
			padding: 10px 0 0;
			width: 100%;
			overflow: hidden;

			&__item {
				@include box-sizing();
				color: $colorGrey;
				font-size: $fontSize;
				font-weight: 300;
				line-height: $fontHeight;
				margin: 0;
				padding: 5px 0 0 15px;

				&:nth-child(odd) {
					max-width: 250px;
				}

				&:nth-child(even) {
					@include mq-min($bpDesktop) {
						width: 50%;
						width: calc(100% - 250px);
					}
				}

				b {
					color: $colorBase;
					display: block;
					font-family: $fontBase;
					padding: 0 0 3px;
				}

				span {
					@include mq-min($bpMobileLarge) {
						padding: 20px 0 0;
						display: block;
					}
				}

				&--inline {
					@include mq-min($bpMobileLarge) {

						b,
						span {
							display: inline;
							padding: 0;
						}

						span {
							padding: 0 0 0 10px;
						}
					}
				}

				&--outbound,
				&--inbound {
					padding-left: 55px !important;
					padding-bottom: 12px;
					position: relative;

					&:before {
						@include bg('/images/common/sprite.png', no-repeat, -160px -138px);
						content: " ";
						height: 30px;
						left: 15px;
						position: absolute;
						top: 0;
						width: 30px;
					}
				}

				&--inbound:before {
					background-position: -192px -138px;
				}

				&--luggage {
					padding-left: 60px !important;
					position: relative;

					&:before {
						@include bg('/images/common/sprite.png', no-repeat, -213px -27px);
						content: "";
						display: block;
						height: 25px;
						left: 15px;
						position: absolute;
						top: 0;
						width: 32px;
					}
				}

				@include mq-min($bpMobileLarge) {
					display: inline-block;
					float: left;
					padding: 5px 0 0 15px;
					vertical-align: top;
					width: 50%;
				}
			}

			@include mq-min($bpMobileLarge) {
				margin-bottom: 25px;
			}
		}
	}

	&__supplements-list {
		margin: 0 0 15px;
		padding: 0;

		&__item {
			color: $colorGrey;
			padding: 0 0 5px;
		}
	}

	&__documents-list {
		max-width: 400px;

		&__item {
			@include box-sizing();
			max-width: 200px;

			@include mq-min($bpMobileLarge) {
				display: inline-block;
				padding-right: 10px;
				vertical-align: top;
				width: 48%;
			}
		}
	}

	@include mq-min($bpMobileLarge) {
		max-width: 380px;
		padding: 25px;
	}

	&--full-width {
		max-width: 100% !important;
		background: $colorPurplePale;
		overflow: hidden;

		.m-booking-summary__content {
			padding: 20px 0;

			@include mq-min($bpMobileLarge) {
				padding: 30px 0;
			}
		}

		.m-booking-summary__content-left,
		.m-booking-summary__content-right {

			/*max-width: 600px;
			margin: 0 auto;*/
			@include mq-min($bpDesktop) {
				float: left;
				margin: 0;
				width: 50%;
				@include box-sizing();
			}
		}

		.m-booking-summary__content-left {
			padding: 0 0 20px;

			@include mq-min($bpDesktop) {
				padding: 0 20px 0 0;
			}
		}

		.m-booking-summary__title {
			max-width: 600px;
			margin: 0 auto;

			@include mq-min($bpDesktop) {
				max-width: 100%;
				margin: 0;
			}
		}

		.m-booking-summary__image {
			margin: 0 0 30px;
		}

		.m-booking-summary__menu__data {
			@include mq-min($bpDesktop) {
				min-width: 165px;
			}
		}

		@include mq-min($bpDesktop) {
			padding: 30px 45px 35px;
		}
	}

	&__left {
		@include mq-min($bpTablet) {
			float: left;
			width: 48%;
			padding-right: 4%;
		}
	}

	&__right {
		@include mq-min($bpTablet) {
			float: right;
			width: 48%;
		}

		&__image {
			display: block;
			margin: 0 auto 15px;
		}
	}
}

//Options Total price Update animation keyframes

@keyframes flash {
	0% {
		background-color: transparent;
	}

	50% {
		background-color: lighten($colorBlueLight, 10%)
	}

	100% {
		background-color: transparent;
	}
}

﻿.m-collection-summary {
	margin: 0 auto;
	max-width: 450px;
	padding: 0 0 15px 0;

	&__link {
		color: $colorBase;
		display: block;

		&:hover {
			color: $colorBase;
			text-decoration: none;

			.m-collection-summary__image__image {
				@include image-hover-anim();
			}
		}
	}

	&__image {
		margin: 0 0 20px;
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;

		&__image {
			display: block;
			@include image-hover();
			margin: 0;
			padding: 0;
		}
	}

	&__title {
		font-size: $fontSize + 6;
		line-height: $fontHeight + 6;
	}

	&__logo {
		float: left;
		padding: 5px 15px 0 0;
		width: 85px;
	}

	&__content {
		overflow: hidden;
	}

	&__summary {
		padding: 0;
	}

	@include mq-min($bpTabletLarge) {
		margin: 0;
		max-width: 100%;
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	&--tight {
		padding-bottom: 0;
	}
}

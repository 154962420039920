﻿.m-wishlist {
	margin: 0;
	padding: 0;

	&__actions {
		margin: 0;
		padding: 0;
		text-align: center
	}

	&__content {
		border-top: 1px solid $colorDivider;
		margin: 30px 0 0;
		padding: 20px 0 0;
		position: relative;
		z-index: 2;

		@include mq-min($bpTabletMid) {
			padding: 30px 20px 0;
		}

		@include mq-min($bpTabletLarge) {
			padding: 35px 25px 0;
		}

		@include mq-min($bpDesktop) {
			padding: 40px 30px 0;
		}
	}

	&__overlay {
		background: $colorPurple;
		@include box-shadow(0 0 5px rgba($colorBlack, .5));
		color: $colorWhite;
		display: none;
		font-family: $fontBaseMedium;
		font-size: $fontSize - 2;
		left: 0;
		line-height: $fontHeight - 2;
		margin: 0;
		padding: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 10001;

		&__inner {
			@include box-sizing();
			min-height: 3px;
			height: auto !important;
			height: 3px;
			margin: 0 auto;
			padding: 13px 10px;
			position: relative;
			text-align: center;
			max-width: $maxPageWidthLarge;
			z-index: 1;
		}

		&__summary {
			@include box-sizing();
			color: $colorWhite;
			margin: 0;
			padding: 0 36px;

			&__title {
				font-family: $fontBaseMedium;
			}

			&__link {
				color: $colorWhite;
				text-decoration: underline;

				&:hover {
					color: $colorWhite;
					text-decoration: none;
				}
			}

			@include mq-min($bpDesktop) {
				padding: 0 105px;
				text-align: center;
			}
		}

		&__link {
			@include box-sizing();
			margin: 0;
			padding: 0;
			position: absolute;
			right: 10px;
			text-align: right;
			top: 13px;
			max-width: 105px;
			z-index: 1;

			&__link {
				color: $colorWhite;
				display: inline-block;
				margin: 0;
				padding: 0;
				position: relative;
				text-decoration: none;
				z-index: 1;

				&:hover {
					color: $colorWhite;
					text-decoration: underline;
				}
			}
		}

		&__close {
			@include bg('/images/common/sprite.png', no-repeat, 0 -11px);
			border: 10px solid $colorPurple;
			display: block;
			height: 14px;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: absolute;
			right: 0;
			text-indent: -9999px;
			top: 7px;
			width: 16px;
			cursor: pointer;
			z-index: 1;

			&:hover {
				@include opacity();
			}
		}

		@include mq-min($bpTablet) {
			font-size: $fontSize;
			line-height: $fontHeight;
		}
	}
}

﻿.m-section-links {
	border-top: 1px solid $colorDivider;
	margin: 0;
	padding: 15px 0;
	text-align: center;

	&__inner {
		margin: 0 auto;
		padding: 0;
		max-width: $maxContentWidth;
	}

	&__title {
		font-family: $fontBase;
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
		margin: 0;
		padding: 0 0 10px 0;

		@include mq-min($bpTabletLarge) {
			font-size: $fontSize + 7;
			line-height: $fontHeight + 7;
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -15px;
		padding: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			font-family: $fontBaseMedium;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 0 0 15px;
			vertical-align: top;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding: 20px 0;
	}

	@include mq-min($bpDesktop) {
		padding: 25px 0;
	}

	@include mq-min($bpLarge) {
		padding: 30px 0;
	}

	&--tight {
		padding-bottom: 0;
	}
}

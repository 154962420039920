.l-sitemap {
	position: relative;
	z-index: 9;
	background: $colorPurplePale;
	color: $colorBrightGrey;
	margin: 0;
	padding: 0;

	&__inner {
		border-bottom: 1px solid rgba($colorWhite, .25);
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0 auto;
		padding: 10px;
		position: relative;
		text-align: left;
		max-width: $maxPageWidth;
		z-index: 2;

		@include mq-min($bpTabletLarge) {
			padding: 15px 10px;
		}

		@include mq-min($bpDesktop) {
			padding: 20px 10px;
		}

		@include mq-min($bpDesktopClear) {
			padding: 20px 0;
		}

		@include mq-min($bpLarge) {
			padding: 25px 0;
			max-width: $maxPageWidthLarge;
		}
	}

	&__group {
		@include box-sizing();
		display: inline-block;
		margin: 0;
		padding: 0;
		vertical-align: top;
		width: 100%;

		&__title {
			font-family: $fontBaseMedium;
			font-size: $fontSize + 3;
			line-height: $fontHeight + 3;
			margin: 0;
			padding: 0 0 10px 0;
		}

		&__share {
			margin: 0;
			padding: 0 0 10px 0;
		}

		&__menu {
			margin: -5px 0 0 -5px;
			padding: 0 0 15px 0;

			&__item {
				@include box-sizing();
				display: inline-block;
				font-size: $fontSize - 1;
				line-height: $fontHeight - 1;
				margin: 0;
				padding: 5px 0 0 5px;
				vertical-align: top;
				width: 50%;

				@include mq-min($bpTabletLarge) {
					width: 100%;
				}

				&--double {
					@include mq-min($bpDesktop) {
						width: 50%;
					}
				}
			}

			&__link {
				color: $colorBrightGrey;
				text-decoration: none;

				&:hover {
					color: $colorBrightGrey;
					text-decoration: underline;
				}
			}
		}

		@include mq-min($bpTabletLarge) {
			width: 33.3333%;
		}

		@include mq-min($bpDesktop) {
			width: 25%;
		}

		&--ideas {
			@include mq-min($bpDesktop) {
				width: 50%;
			}
		}
	}
}

﻿.m-search-page {
	&__results {
		margin: 0;

		&__header {
			color: $colorGrey;
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
			margin: 0;
			padding: 0 0 10px 0;
		}
	}

	&__results-container {
		background: $colorPurplePale;
		padding: 15px 5px;
		margin: 0 0 15px 0;

		@include mq-min($bpTabletLarge) {
			padding: 15px;
		}
	}

	&__form {
		background: $colorPurplePale;
		margin: 0 0 15px 0;
		padding: 15px;

		&__inner {
			margin: 0;
			padding: 0;
		}

		&__title {
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
			margin: 0;
			padding: 0;
		}

		&__clear-search {
			padding: 15px 0 15px;
			cursor: pointer;
		}

		@include mq-min($bpTabletLarge) {
			padding: 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 25px;
		}

		@include mq-min($bpLarge) {
			padding: 30px;
		}

		&--tight {
			margin-bottom: 0;
		}
	}

	&__filter-container {
		@include mq-max($bpDesktop) {
			opacity: 0;
			max-height: 0;
			overflow: hidden;
			padding: 0;
			margin: 0;
			transition: opacity 300ms, max-height 300ms;
			background-color: #e7ecf2;
			margin-top: -15px;
			width: calc(100% + 10px);

			&.is-active {
				opacity: 1;
				max-height: 1500px;
				/* Height of filters needed for animation */
				padding-top: 10px;
				margin-bottom: 10px;
			}

			.m-toggle-block {
				margin-bottom: 0;
			}

			.m-toggle-block__button {
				display: none;
			}

			.m-search-page__filter {
				margin: 0;
				padding: 15px 30px;
			}
		}
	}

	&__filter {
		background: $colorPurplePale;
		margin: 0 0 15px 0;
		padding: 15px;

		&__title {
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
			margin: 0;
			padding: 0;
		}

		&__title-label {
			border-top: 1px solid rgba($colorBase, 0.3);
			font-family: $fontBaseMedium;
			font-size: 15px;
			display: flex;
			align-self: stretch;
			align-items: center;
			padding: 0;
			gap: 5px;
		}

		&__more-link__inner {
			&:hover {
				color: $colorLinkHover;
				text-decoration: none;
			}
		}

		&__collections {
			margin: 0;
			padding: 0;
			position: relative;
		}

		@include mq-min($bpTabletLarge) {
			padding: 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 25px;
		}

		&--tight {
			margin-bottom: 0;
		}

		&__footer {
			padding: 25px 0 10px 0;
			border-top: 1px solid rgba($colorBase, 0.3);
		}

		&__footer__inner {
			display: flex;
			align-items: center;
			margin: 0 auto;
			max-width: 500px;
		}

		&__footer__clear,
		&__footer__button {
			flex: 1;
		}

		&__footer__button {
			max-width: 182px;
		}

		&__footer__clear {
			font-family: $fontBaseMedium;
			font-size: 15px;
		}
	}

	&__results-text {
		padding: 0;
		margin: 20px 0 10px 0;
		font-family: $fontBaseMedium;
		font-size: 15px;
	}

	&__results-text-top-container {
		display: none;

		@include mq-min($bpDesktop) {
			display: block;
		}
	}

	&__results-text-bottom-container {
		@include mq-min($bpDesktop) {
			display: none;
		}

		.m-search-page__results-text {
			margin-top: 0;
			padding: 0 15px;
		}
	}

	&__header {
		font-size: 0;
		line-height: 0;
		margin: 0 0 25px 0;

		&__half {
			&--pager {
				@include mq-min($bpDesktop) {
					margin-top: -55px;
				}
			}

			font-size: $fontSize;
			line-height: $fontHeight;

			&__inline {
				display: inline-block;
				vertical-align: middle;
			}

			@include mq-min($bpTabletLarge) {
				width: 70%;
				display: inline-block;
				vertical-align: top;

				&--right {
					text-align: right;
					width: 30%;
				}
			}

			@include mq-min($bpDesktop) {
				width: 50% !important;
			}
		}
	}

	&__pagination-btm {
		text-align: center;

		@include mq-min($bpTabletLarge) {
			text-align: right;
		}
	}

	&__re-search {
		border-top: 1px solid $colorDivider;
		margin: 35px 0 0 0;
		padding: 20px 0;
	}

	&__no-results {
		text-align: center;
		margin: 0 auto;
		max-width: 500px;

		@include mq-min($bpTabletLarge) {
			padding-top: 20px;
		}
	}

	&__landing {
		border-bottom: 1px solid $colorBorder;
		margin: 0 0 20px;

		&__image {
			display: block;
			margin: 0 auto 20px 0;
		}
	}

	&__related {
		&__list {
			font-family: $fontBaseMedium;

			@include mq-min($bpTabletLarge) {
				padding-left: 20px;
			}

			@include mq-min($bpDesktop) {
				padding-left: 25px;
			}
		}
	}
}

.m-search-page__tab-container {
	display: none;
}

.m-search-page__tab-container.is-active {
	display: block;
}

.flex-container {
	display: flex;
	flex-direction: column-reverse;

	@include mq-min($bpTabletLarge) {
		display: block;
		flex-direction: row;
	}
}

﻿.c-mobile-search-nav {
	display: none;
}

@include mq-max($bpTablet) {

	.lockout .c-mobile-search-nav__buttons,
	.destinations-picker-open .c-mobile-search-nav__buttons,
	.date-picker-open .c-mobile-search-nav__buttons,
	.airport-picker-open .c-mobile-search-nav__buttons {
		display: none;
	}
}

@include mq-max($bpDesktop) {
	.c-mobile-search-nav {
		display: block;
		position: sticky;
		top: 38px;
		/* Height of header with a little less for ios */
		left: 0;
		right: 0;
		margin: 0;
	}

	.c-mobile-search-nav__buttons {
		display: flex;
		width: 100%;
	}

	.c-mobile-search-nav__button {
		position: relative;
		width: 49.5%;
		background-color: #697D96;
		padding: 17px 30px;
		color: #FFF;
		text-transform: uppercase;
		border: none;
		cursor: pointer;
		text-align: left;
		font-size: 15px;
		font-weight: normal;
		font-family: $fontBaseMedium;
	}

	.c-mobile-search-nav__button:after {
		content: '';
		position: absolute;
		bottom: -30px;
		left: 0;
		right: 0;
		margin: auto;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 30px 30px 0 30px;
		border-color: #697D96 transparent transparent transparent;
		transition: transform 300ms;
		z-index: 100;
		transform: translateY(-32px);
	}

	.c-mobile-search-nav__button.is-active:after {
		transform: translateY(-2px);
	}

	.c-mobile-search-nav__button-text {
		position: relative;
		z-index: 101;
	}

	.c-mobile-search-nav__button--search {
		margin-left: auto;
	}

	.c-mobile-search-nav__icon {
		position: absolute;
		top: 50%;
		margin-top: -15px;
		right: 15px;
		display: block;
		width: 30px;
		height: 30px;
	}

	.c-mobile-search-nav__icon--active {
		display: none;
	}

	.c-mobile-search-nav__button.is-active .c-mobile-search-nav__icon--active {
		display: block;
	}

	.c-mobile-search-nav__button.is-active .c-mobile-search-nav__icon--inactive {
		display: none;
	}

	.c-mobile-search-nav__results {
		padding-top: 40px;
	}

	/* Hide the arrow if the user has scrolled */
	.c-mobile-search-nav.has-scrolled .c-mobile-search-nav__button.is-active:after {
		transform: translateY(-32px);
	}
}

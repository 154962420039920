.m-password {
    &__value {
        display: inline-block;
    }

    &__icon {
        content: "";
        display: inline-block;
        color: $colorAction;

        &:after {
            font-family: $fontAwesome;
            content: "\f06e";
            font: var(--fa-font-solid);
            padding-right: 9px;
        }

        &--active {
            &:after {
                font-family: $fontAwesome;
                content: "\f070";
                font: var(--fa-font-solid);
                padding-right: 8px;
            }
        }
    }

}

.m-side-nav {
	margin: 0 0 15px 0;
	padding: 0;

	&__title {
		margin: 0;
		padding: 0;

		&__link {
			background: $colorBlue;
			color: $colorWhite;
			display: block;
			font-family: $fontBase;
			font-size: $fontSize;
			font-weight: 400;
			line-height: $fontHeight;
			margin: 0;
			padding: 10px 25px 10px 10px;
			position: relative;
			text-decoration: none;
			text-transform: uppercase;
			z-index: 1;

			&:before {
				border: 5px solid transparent;
				border-bottom-width: 0;
				border-top-color: $colorWhite;
				content: "";
				display: block;
				height: 0;
				margin: -3px 0 0 0;
				padding: 0;
				position: absolute;
				right: 10px;
				top: 50%;
				width: 0;
				z-index: 1;
			}

			&:hover {
				background: darken($colorBlue, 5%);
				color: $colorWhite;
				text-decoration: none;
			}

			&.active {
				background: darken($colorBlue, 5%);

				&:before {
					border-bottom-color: $colorWhite;
					border-bottom-width: 5px;
					border-top-color: transparent;
					border-top-width: 0;
				}
			}
		}

		@include mq-min($bpTabletLarge) {
			display: none !important;
		}
	}

	&__inner {
		background: $colorOffWhite;
		margin: 0;
		padding: 0;
		display: none;

		@include mq-min($bpTabletLarge) {
			display: block !important;
		}

		@include mq-min($bpTabletLarge) {
			padding: 17px 20px 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 27px 30px 20px;
		}
	}

	&__section {
		display: none;
		margin: 1px 0 0 0;
		padding: 0;

		@include mq-min($bpTabletLarge) {
			display: block;
		}

		&__title {
			color: $colorGrey;
			display: block;
			font-size: $fontSize + 4;
			line-height: $fontHeight + 4;
			margin: 0;
			padding: 10px;
			text-decoration: none;

			@include mq-min($bpTabletLarge) {
				font-size: $fontSize + 6;
				line-height: $fontHeight + 6;
				padding: 0;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding-bottom: 5px;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 10px;
		}
	}

	&__menu {
		margin: 0;
		padding: 0;

		&__item {
			border-top: 1px solid $colorWhite;
			margin: 0;
			padding: 0;

			@include mq-min($bpTabletLarge) {
				border-top: 0;
				margin: 1px 0 0;
			}
		}

		&__link {
			color: $colorBase;
			display: block;
			font-size: $fontSize + 1;
			line-height: $fontHeight + 1;
			margin: 0;
			padding: 10px 10px 10px 25px;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:before {
				background: $colorGrey;
				content: "";
				display: block;
				height: 1px;
				left: 12px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 19px;
				width: 5px;
				z-index: 1;

				@include mq-min($bpTabletLarge) {
					display: none;
				}
			}

			&:hover,
			&.active {
				color: $colorBlue;
				text-decoration: none;
			}

			@include mq-min($bpTabletLarge) {
				padding: 10px 0;
			}
		}
	}

	&--tight {
		margin-bottom: 0;
	}
}

.m-popup {

	//Some styles have been moved to _popups.scsss
	&__title {
		font-family: $fontBase;
		font-size: $fontSize + 10;
		line-height: $fontHeight + 10;
		margin: 0;
		padding: 0 0 15px 0;
	}

	&__inner {
		@include min-height(200px);
		margin: 0;
		padding: 25px 15px;
		position: relative;
		text-align: left;
		z-index: 1;

		@include mq-min($bpTabletLarge) {
			padding: 35px 20px;
		}

		@include mq-min($bpLarge) {
			padding: 35px 25px;
		}
	}

	&__video {
		background: $colorBlack;
		height: 0;
		margin: 0 auto;
		overflow: hidden;
		padding: 35px 0 53.8% 0;
		position: relative;
		z-index: 1;

		iframe {
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}

	&__actions {
		background: lighten($colorOffWhite, 5%);
		@include border-radius-adv(0, 0, 2px, 2px);
		border-top: 1px solid $colorDivider;
		margin: 0 -15px -15px -15px;
		padding: 15px;

		&__menu {
			font-size: 0;
			line-height: 0;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;

			&__item {
				display: inline-block;
				font-size: $fontSize;
				line-height: $fontHeight;
				list-style: none;
				margin: 0;
				padding: 10px;

				.form-button {
					margin: 0;
				}
			}
		}

		@include mq-min($bpLarge) {
			margin: 0 -20px -20px -20px;
		}
	}

	&__close {
		@include bg('/images/common/sprite.png', no-repeat, -70px 0);
		border: 4px solid $colorWhite;
		display: block;
		height: 16px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		@include text-hidden();
		top: 0;
		width: 16px;
		z-index: 3;
		cursor: pointer;

		&:hover {
			@include opacity();
		}

		&--gallery,
		.m-popup--video & {
			border-color: $colorBlack;
			background-position: 0 -11px;
		}

		@include mq-min($bpTabletLarge) {
			border-width: 10px;
		}
	}

	&--image {
		.m-popup__close {
			background-position: -71px 0px;
			width: 15px;
		}
	}

	&--video {
		background: $colorBlack;

		&__inner {
			padding: 27px 5px 5px 5px;

			@include mq-min($bpTabletLarge) {
				padding: 37px 20px 20px 20px;
			}

			@include mq-min($bpDesktop) {
				padding: 37px 25px 25px 25px;
			}

			@include mq-min($bpLarge) {
				padding: 37px 30px 30px 30px;
			}
		}
	}

	&--small {
		&__inner {
			@include min-height(100px);
		}
	}

	&--share {
		&__inner {
			@include min-height(100px);
			text-align: center;
		}

		@include mq-min($bpMobileLarge) {
			width: 460px;
		}
	}

	&--confirm {
		&__inner {
			@include min-height(100px);
		}

		@include mq-min($bpMobileLarge) {
			width: 460px;
		}
	}

	&--gallery {
		background: rgba(0, 0, 0, 0.8);
		bottom: 0;
		display: none;
		left: 0 !important;
		margin: 0 !important;
		position: fixed;
		right: 0 !important;
		top: 0 !important;
		width: auto !important;
		z-index: 10000;
		max-width: 100% !important;
		transform: none !important;

		@include mq-min($bpTabletLarge) {
			height: 100% !important;
		}

		&__inner {
			padding: 0 !important;
			max-width: 100% !important;
			margin: 0;
			height: 100% !important;
		}
	}

	&--image {
		margin: 0 auto;
		max-width: 1270px;
		width: 100%;
	}
}

#popupLoader {
	background: url($svgLoader) no-repeat center 100%;
	height: 50px;
	left: 150%;
	padding: 0;
	position: fixed;
	top: 50%;
	width: 50px;
	z-index: 999999;
	transform-origin: top left;
	transform: scale(1.5) translate(-50%, -30%);

	&.show {
		left: 50%;
	}

	&Adv {
		background: $colorWhite;
		@include box-shadow(0 0 15px rgba($colorBlack, .25));
		@include box-sizing();
		display: none;
		left: 50%;
		margin: 0;
		padding: 15px 10px 100px 10px;
		position: fixed;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 9998;
		width: 100%;
		max-width: 95%;

		@include mq-min($bpMobileLarge) {
			padding: 20px 15px 100px 15px;
			width: 400px;
		}

		@include mq-min($bpTabletLarge) {
			padding: 30px 15px 110px 15px;
			width: 500px;
		}

		@include mq-min($bpDesktop) {
			padding: 40px 15px 120px 15px;
			width: 560px;
		}

		&:before {
			background: url($svgLoader) no-repeat 50% 50%;
			transform-origin: left top;
			transform: scale(1.5);
			bottom: 30px;
			content: " ";
			display: block;
			height: 75px;
			left: 50%;
			margin: 0 0 0 -31px;
			padding: 0;
			position: absolute;
			@include transform-origin(50% 50%);
			width: 75px;
			z-index: 1;

			@include mq-min($bpTabletLarge) {
				bottom: 40px;
			}

			@include mq-min($bpDesktop) {
				bottom: 50px;
			}
		}

		>.title {
			color: $colorGrey;
			font-family: $fontBaseLight;
			margin: 0;
			padding: 0 0 10px 0;
		}

		>.summary {
			margin: 0;
			padding: 0 0 20px 0;
		}

	}
}

#popupConfirm {
	.m-popup__inner {
		text-align: center;
	}
}

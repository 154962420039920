.l-internal-payments {
    &__body {
        height: 100vh;
        background-color: #E5E5E5;
    }
    &__record-banner {
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        &--warning {
            background: red;
        }
        &--complete {
            background: green;
        }
        &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            &__icon {
                font-size: 2rem;
                margin-right: 10px;
            }
            &__heading {
                font-size: 2rem;
            }
        }
    }
    &__payment {
        &__drop-in-wrapper {
            background-color: white;
            padding: 25px;
        }
        &__amount-input, &__user-reference-input, &__user-operator-input {
            width: 100%;
            padding: 5px;
            border: 1px solid lightgrey;
            margin-bottom: 11px;
        }
        &__next{
            border-radius: 0 0 5px 5px;
        }
    }
}
﻿.content-full-width {
	@include mq-min($bpDesktop) {
		left: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		max-width: 100vw;
		position: relative;
		right: 50%;
		width: 99.5vw;
	}
}

.boxContainer {
	@include mq-min($bpDesktop) {
		display: flex;
	}

	padding: 120px 0;

	h1 {
		font-size: 33px;
		margin-bottom: 7px;
	}

	p {
		font-size: 20px;
		line-height: 33px;
	}

	.accomodation {
		margin: 5px 0 10px 0;
	}

	:nth-child(even) {
		flex-direction: row-reverse;
	}

	:nth-child(odd) {
		flex-direction: row;
	}

	li {
		list-style: disc;
		list-style-position: inside;
	}

	.layout {
		text-align: center;
		padding: 45px;
	}

	.round-image {
		border-radius: 50%;
		margin: 0 auto
	}
}


.l-content {
	position: relative;
	z-index: 10;
	@include min-height(200px);
	margin: 0;
	padding: 0;

	&__title {
		font-family: $fontBase;
		font-size: $fontSize + 9;
		line-height: $fontHeight + 9;
		margin: 0;
		padding: 0 0 15px 0;

		&--tight {
			padding-bottom: 0;
		}
	}

	&__summary {
		margin: 0 auto;
		padding: 0;
		text-align: center;
		max-width: $maxContentWidth;

		@include mq-min($bpTabletLarge) {
			padding-bottom: 5px;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 15px;
		}

		@include mq-min($bpLarge) {
			padding-bottom: 20px;
		}
	}
}

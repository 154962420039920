.currency_form {
    button {
        min-height: 48px;
        padding: 10px 40px;
        background-color: #0C3465;
        border: 1px solid #0C3465;
        color: white;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: #0C3465;
        }
    }
}

.currency-list {
    .currency-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i.is-active {
            display: none;
        }

        &.active {
            i.is-active {
                display: block;
            }
        }

        &.info {
            text-transform: none;
            flex-direction: column;
        }
    }
}
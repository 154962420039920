﻿.m-tabs {
	margin: 0 0 15px 0;
	padding: 0;

	.m-popup & {
		margin: 0;
	}

	&__inner {
		background: $colorOffWhite;
		margin: 0;
		padding: 0;
	}

	&__tab__title {
		font-size: $fontSize + 7;
		line-height: $fontHeight + 7;
	}

	&__title {
		background: $colorBlue;
		@include box-sizing();
		color: $colorWhite;
		cursor: pointer;
		height: 40px;
		margin: 0;
		padding: 11px 30px 11px 10px;
		position: relative;
		@include text-overflow();
		text-transform: uppercase;
		z-index: 1;
		display: block;

		&:before {
			border: 4px solid transparent;
			border-bottom-width: 0;
			border-top-color: $colorWhite;
			border-top-width: 6px;
			content: "";
			display: block;
			height: 0;
			margin: -3px 0 0 0;
			padding: 0;
			position: absolute;
			right: 10px;
			top: 50%;
			width: 0;
			z-index: 1;
		}

		&.active {
			&:before {
				border-bottom-color: $colorWhite;
				border-bottom-width: 6px;
				border-top-color: transparent;
				border-top-width: 0;
			}
		}

		@include mq-min($bpDesktop) {
			display: none !important;
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		display: none;

		@include mq-min($bpDesktop) {
			background: $colorOffWhite;
			display: block;
		}

		@include mq-min($bpDesktop) {
			display: flex !important;
			flex-direction: row;
		}

		&__item {
			border-top: 1px solid $colorGrey;
			border-top-color: rgba($colorGrey, .2);
			display: inline-block;
			margin: 0;
			padding: 0;
			vertical-align: top;
			width: 100%;

			&--hidden-mob {
				display: none;

				@include mq-min($bpTabletLarge) {
					display: inline-block !important;
				}
			}

			@include mq-min($bpDesktop) {
				border: 0;
				margin-left: 1px;
				@include text-overflow();
				overflow: visible;
				width: auto;
			}

			@include mq-min($bpDesktop) {
				flex-grow: 1;
			}

			&:first-child {
				border-top: 0;

				@include mq-min($bpDesktop) {
					margin-left: 0;
				}
			}
		}

		&__link {
			@include box-sizing();
			color: $colorGrey;
			display: block;
			font-family: $fontBase;
			font-size: $fontSize - 2;
			@include min-height(32px);
			line-height: $fontHeight;
			margin: 0;
			padding: 11px 10px;
			text-decoration: none;
			text-transform: none;
			text-align: center;

			&:hover {
				color: $colorBase;
				text-decoration: none;

				@include mq-min($bpDesktop) {
					background: $colorWhite;
				}
			}

			&.active {
				color: $colorBase;
				text-decoration: none;

				@include mq-min($bpDesktop) {
					background: $colorWhite;
					border-bottom-color: $colorWhite;
				}
			}

			@include mq-min($bpDesktop) {
				border-bottom: 1px solid $colorPurplePale;
				font-size: $fontSize - 1;
				padding: 10px;
				text-align: center;
				text-transform: none;
			}

			@include mq-min($bpDesktop) {
				padding: 15px 10px;
			}
		}

		&--alt {
			.m-tabs__menu__link {
				border-bottom: $colorWhite;
				min-width: 140px;
			}

			.m-tabs__menu__link.active,
			.m-tabs__menu__link:hover {
				@include mq-min($bpDesktop) {
					background: $colorPurplePale;
					border-bottom: $colorPurplePale;
				}
			}

			@include mq-min($bpDesktop) {
				background: $colorWhite;
				display: block !important;
			}

		}

		&--availability {
			display: block;
			display: flex !important;
			flex-direction: row;

			.m-tabs__menu__item {
				border: 0;
				margin-left: 1px;
				@include text-overflow();
				width: auto;
				flex-grow: 1;


				&:first-child {
					margin-left: 0;
				}
			}

			.m-tabs__menu__link {
				border-bottom: 1px solid $colorPurplePale;
				font-size: $fontSize - 1;
				padding: 10px;
				text-align: center;
				text-transform: none;

				&:hover {
					background: $colorWhite;
				}

				&.active {
					background: $colorWhite;
					border-bottom-color: $colorWhite;
				}
			}
		}
	}

	&--alt {
		border: none !important;

		.m-tabs__content,
		.m-tabs__tab--alt {
			background: $colorPurplePale;
		}

		@include mq-max($bpTabletMid) {
			.m-tabs__content {
				padding: 15px;
			}
		}
	}

	&--narrow {
		.m-tabs {
			&__inner {
				@include mq-min($bpTabletMid) {
					background: none;
				}
			}

			&__title {
				@include mq-min($bpTabletMid) {
					display: none !important;
				}
			}

			&__menu {
				display: none;

				&__item {
					border-top: 1px solid $colorGrey;
					border-top-color: rgba($colorGrey, .2);
					display: inline-block;
					margin: 0;
					padding: 0;
					vertical-align: top;
					width: 100%;

					@include mq-min($bpTabletMid) {
						border: 0;
						margin-left: 1px;
						@include text-overflow();
						width: auto;
					}

					&:first-child {
						@include mq-min($bpTabletMid) {
							margin-left: 0;
						}
					}

					@include mq-min($bpTabletMid) {
						flex-grow: 1;
					}
				}

				&__link {
					&:hover {
						@include mq-min($bpTabletLarge) {
							background: $colorWhite;
						}
					}

					&.active {
						@include mq-min($bpTabletMid) {
							background: $colorWhite;
							border-bottom-color: $colorWhite;
						}
					}

					@include mq-min($bpTabletMid) {
						border-bottom: 1px solid $colorPurplePale;
						padding: 10px;
						text-align: center;
						text-transform: none;
					}
				}

				@include mq-min($bpTabletMid) {
					background: $colorPurplePale;
					height: auto !important;
					display: flex !important;
					flex-direction: row;
				}
			}
		}
	}

	&--search-block {
		position: relative;
		border: none !important;
		margin-bottom: 0;

		.m-tabs {
			&__inner {
				background: none;
			}

			&__content {
				padding: 0;
			}

			&__title {
				display: none !important;
			}

			&__menu {
				background: none;
				display: block !important;
				height: 100% !important;
			}

			&__menu__item {
				flex-grow: 0;
				width: 50%;
				box-sizing: border-box;
				border-width: 1.5px 1.5px 0 0;
				border-color: $colorPurplePale;
				border-style: solid;
				margin: 0;

				@include mq-min($bpMobileLarge) {
					width: auto;
				}
			}

			&__menu__link {
				padding: 4px 15px;
				background: $colorWhite;
				border: none;
				text-transform: uppercase;

				@include mq-min($bpMobileLarge) {
					min-width: 170px;
				}

				&.active {
					background: $colorPurplePale;
					color: $colorBrightGrey;
				}

				&:hover:not(.active) {
					color: $colorBrightGrey;
				}

				&:focus {
					outline: 1px dotted $colorPurple;
				}
			}
		}
	}

	&__content {
		margin: 0;
		padding: 15px 0 0 0;

		@include mq-min($bpTabletMid) {
			padding: 15px;
		}

		@include mq-min($bpTabletLarge) {
			padding: 30px 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 35px 30px;
		}
	}

	&__map {
		position: relative;
		padding: 0;
		margin: 0 auto;

		&__link {
			@include bg('/images/common/sprite.png', no-repeat, -245px -203px);
			content: "";
			height: 32px;
			position: absolute;
			right: 5px;
			top: 5px;
			width: 32px;
			z-index: 10;
			text-indent: 200%;
			overflow: hidden;
			white-space: nowrap;

			&:hover {
				@include opacity();
			}
		}

		&--spaced {
			margin: 0 0 20px;

			@include mq-min($bpTabletLarge) {
				margin: 0 0 30px;
			}
		}
	}

	&__actions {
		padding: 10px 0 0;

		&__inline {
			display: inline-block;
			vertical-align: top;
			padding-bottom: 15px;

			&--first {
				padding-right: 20px;
			}

			@include mq-min($bpTabletMid) {
				padding-bottom: 0;
			}

			&--spaced {
				padding-bottom: 15px;
			}
		}
	}

	&__sub-property {
		&__heading {
			font-size: $fontSize + 1;
			line-height: $fontHeight + 1;
		}

		&__heading,
		&__title {
			color: $colorGrey;
		}

		&__title,
		&__sub-title {
			font-size: $fontSize + 3;
			line-height: $fontHeight;
		}

		&__sub-title {
			padding: 0 0 8px;
		}

		&__image {
			width: 100%;
			max-width: 800px;
			margin: 0 auto;
		}

		&__call-us-message {
			font-weight: bold;
		}

		&__booking-message {
			margin-top: 30px;

			&--bold {
				font-weight: bold;
			}
		}
	}

	.m-grid__row {
		text-align: left;
	}

	&__skip-to {
		&__title {
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			text-align: center;
			padding: 0;
		}

		&__menu {
			text-align: center;
			padding: 0 0 18px;
		}

		&__menu-item {
			display: inline-block;
			padding: 3px;
			margin: 0 5px;
		}
	}

	@include mq-min($bpTabletMid) {
		border: 1px solid $colorPurplePale;
	}

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
	}

	@include mq-min($bpLarge) {
		margin-bottom: 30px;
	}

	&--tight {
		margin-bottom: 0;
	}
}

.m-tabs--search-block {

	.m-tabs__tab {

		&:not(.active) {
			display: none;
		}
	}

	.m-tabs__tab--disabled {

		input:disabled,
		.select,
		.select--faux__inner {
			background: lighten($colorOffWhite, 5%);
			cursor: not-allowed;
			@include opacity(.5);
		}

		.select {

			&:hover {
				border-color: #fff;
			}
		}
	}
}

﻿.m-no-results {
	padding: 30px 0 0 0;
}

.m-no-results__link {
	text-decoration: underline;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.m-no-results--tight {
	padding: 15px 0 0 0;

	@include mq-min($bpTablet) {
		padding: 30px 0 0 0;
	}
}

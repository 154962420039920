.m-marketing-file-collection {
    display: flex;
    margin-bottom: 20px;

    &__col1 {
        flex: 15%;
    }

    &__col2 {
        flex: 85%;
        height: 250px;
        border: 1px solid black;
        box-sizing: border-box;
        margin-left: -1px;
        overflow-y: auto;
    }

    &__name {
        border-left: 1px solid black;
        border-top: 1px solid black;
        box-sizing: border-box;
        background-color: #f8f9fb;
        font-size: 14px;
        padding: 5px;

        &:nth-last-child(1) {
            border-bottom: 1px solid black;
        }

        &:hover {
            background-color: white;
        }

        &.selected {
            background-color: white;
            border-right: 0px;
            z-index: 0;
            position: sticky;
        }
    }

    &__files {
        display: none;

        &.selected {
            display: block;
        }

        &__link {
            margin: 10px;
            max-width: 20%;
            min-width: 15%;
            text-decoration: none;
            border-radius: 0.5em;

            &:hover {
                color: white;
                background-color: #72acb3;
                text-decoration: none;
            }
        }

        &__file {
            text-align: center;
            border: 1px solid #72acb3;
            border-radius: 0.5em;
            padding: 10px;
            height: 100%;
            box-sizing: border-box;

            &__icon {
                font-size: 30px;
                ;
            }
        }
    }
}

.m-blog {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}

	&__info {
		margin: 0;
		padding: 5px 0 10px;

		&__item {
			@include box-sizing();
			color: $colorGrey;
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 0 16px 0 0;
			position: relative;
			vertical-align: top;
			z-index: 1;

			&:before {
				background: $colorGrey;
				content: "";
				display: block;
				height: 13px;
				margin: 0;
				@include opacity();
				padding: 0;
				position: absolute;
				right: 7px;
				top: 6px;
				width: 1px;
				z-index: 1;
			}

			&:last-child {
				padding-right: 0;

				&:before {
					display: none;
				}
			}
		}

		&__link {
			&:hover {
				color: $colorBlue;
				text-decoration: none;
			}
		}

		&--tight {
			padding-bottom: 0;
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -35px 0 0 -35px;
		padding: 0;
		text-align: left;

		&__item {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0 auto;
			max-width: 600px;
			padding: 35px 0 0 35px;
			vertical-align: top;
			width: 100%;

			@include mq-min($bpMobileLarge) {
				width: 50%;
			}

			@include mq-min($bpTabletMid) {
				width: 33.3333%;
			}
		}

		&__article {
			border: 1px solid $colorBorder;
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 15px;
			text-decoration: none;

			@include mq-min($bpDesktop) {
				padding: 20px;
			}
		}

		&__image {
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: relative;
			z-index: 1;

			&__image {
				display: block;
				margin: 0;
				padding: 0;
				@include image-hover();
				width: 100%;
			}
		}

		&__title {

			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			margin: 0;
			padding: 10px 0 0;
		}

		&__link {
			color: $colorBase;
			text-decoration: none !important;
			width: 100%;

			&:hover {
				.m-blog__menu__image__image {
					@include image-hover-anim();
				}
			}
		}

		&__summary {
			font-size: $fontSize;
			@include min-height(150px);
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 0 0 0;

			&__text {
				display: inline;
				margin: 0;
				padding: 0;
			}

			&__more {
				color: $colorBlue;

				&:hover {
					color: $colorTeal;
					text-decoration: none;
				}
			}
		}
	}

	&__search {
		background: $colorOffWhite;
		font-size: 0;
		line-height: 0;
		margin: 0 0 15px;
		padding: 15px;

		&__button,
		&__filters {
			font-size: $fontSize;
			line-height: $fontHeight;

			@include mq-min($bpTablet) {
				display: inline-block;
				vertical-align: top;
			}
		}

		&__button {
			@include mq-min($bpTablet) {
				float: left;
				padding-top: 29px;
				width: 27%;
			}

			@include mq-min($bpTabletLarge) {
				width: 25%;
			}

			@include mq-min($bpDesktop) {
				width: 20%;
			}
		}

		&__filters {
			@include mq-min($bpTablet) {
				float: right;
				width: 68%;
			}

			@include mq-min($bpTabletLarge) {
				width: 75%;
			}

			@include mq-min($bpDesktop) {
				width: 80%;
			}
		}

		@include mq-min($bpTablet) {
			margin: 0 0 25px;
			padding: 25px;
		}

		@include mq-min($bpDesktop) {
			margin: 0 0 35px;
			padding: 35px;
		}
	}

	&__pagination {
		padding: 35px 0 0;
		text-align: right;
	}

	&__post-wrapper {
		margin: 0 auto;
		max-width: 780px;
	}

	&__post {
		margin: 0;
		padding: 0;

		&__menu {
			border-top: 1px solid $colorBorder;
			margin-top: 15px;
			overflow: hidden;
			padding: 15px 0 5px;
			text-align: center;

			&__item {
				float: left;
				width: 50%;

				span {
					display: none;
				}

				&--next {
					float: right;
					text-align: right;
				}

				@include mq-min($bpMobileLarge) {
					span {
						display: inline;
					}
				}
			}

			&__link {
				&:hover {
					color: $colorBlue;
					text-decoration: none;
				}

				&.disabled {
					color: $colorGrey !important;
					cursor: not-allowed;
				}
			}
		}

		&__share {
			padding: 0 0 10px;
			position: relative;

			@include mq-min($bpTablet) {
				height: 0;

				&__link {
					margin: -71px 0 0;
				}
			}
		}

		&__related {
			background: $colorOffWhite;
			margin: 0 0 20px;
			padding: 15px;

			&__menu {
				color: $colorTeal;
				font-size: 0;
				line-height: 0;
				padding: 0;
				text-align: left;
			}

			&__item {
				display: inline-block;
				font-size: $fontSize;
				line-height: $fontHeight;
				padding-right: 2px;
				vertical-align: top;

				&--first {
					padding-right: 5px;
					color: $colorBase !important;
				}
			}

			@include mq-min($bpTablet) {
				padding: 20px;
				margin: 0 0 25px;
			}

			@include mq-min($bpDesktop) {
				margin: 0 0 40px;
			}
		}
	}

	&__utils {
		margin: 0;
		padding: 0 0 20px;
		text-align: center;

		&__item {
			display: inline-block;
			padding: 0 10px;
			vertical-align: top;
		}
	}

	&__author {
		margin: 0 auto;
		max-width: 800px;
		text-align: center;

		&__title {
			font-size: $fontSize + 4;
			line-height: $fontHeight + 4;
			padding: 0 0 3px;
		}
	}

	&__no-results {
		text-align: center;
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpLarge) {
		padding-bottom: 30px;
	}

	&--tight {
		padding-bottom: 0;
	}
}

﻿@mixin color-section {
	margin-bottom: 15px;
	padding: 15px;
	position: relative;
	z-index: 1;

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
		padding: 20px;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
		padding: 25px;
	}

	@include mq-min($bpLarge) {
		margin-bottom: 30px;
		padding: 30px;
	}

	&--tight {
		margin-bottom: 0;
	}
}

.m-section {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		@include box-sizing();
		margin: 0 auto;
		padding: 0;
		max-width: $maxPageWidth;

		@include mq-min($bpLarge) {
			max-width: $maxPageWidthLarge;
		}
	}

	&__title {
		font-family: $fontBase;
		font-size: $fontSize + 1;
		line-height: $fontHeight + 1;
		margin: 0;
		padding: 0;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		z-index: 1;

		&__inner {
			background: $colorWhite;
			display: inline-block;
			margin: 0;
			padding: 0 10px;
			position: relative;
			z-index: 2;

			@include mq-min($bpTabletLarge) {
				padding: 0 15px;
			}

			@include mq-min($bpDesktop) {
				padding: 0 20px;
			}
		}

		&:before {
			background: $colorDivider;
			content: "";
			height: 1px;
			left: 0;
			margin: -1px 0 0 0;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 100%;
			z-index: 1;
		}

		&--no-rule {
			&:before {
				display: none;
			}
		}
	}

	&__summary {
		margin: 0;
		padding: 10px 0 0 0;
		text-align: center;

		&--alt {
			color: $colorBase;
			font-size: $fontSize;
			line-height: $fontHeight;
		}
	}

	&__content {
		margin: 0;
		padding: 15px 0 0 0;

		@include mq-min($bpTabletLarge) {
			padding-top: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-top: 25px;
		}

		@include mq-min($bpLarge) {
			padding-top: 30px;
		}
	}

	&__actions {
		margin: 0;
		padding: 20px 0 0 0;
		text-align: center;
	}

	&__links {
		padding-top: 25px;

		&__menu {
			font-size: 0;
			line-height: 0;
			margin: -5px 0 0 -15px;
			padding: 0;
			text-align: center;

			&:before {
				color: $colorBase;
				content: attr(data-title);
				font-family: $fontBase !important;
			}

			&:before,
			&__item {
				@include box-sizing();
				display: inline-block;
				font-family: $fontBaseMedium;
				font-size: $fontSize - 1;
				line-height: $fontHeight - 1;
				margin: 0;
				padding: 5px 0 0 15px;
				vertical-align: top;
			}
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpLarge) {
		padding-bottom: 30px;
	}

	&--tight {
		padding-bottom: 0;
	}

	&--blue {
		background: $colorBlueLight;
		color: $colorWhite;
		@include color-section;
	}

	&--purple-pale {
		background: $colorPurplePale;
		color: $colorBrightGrey;
		@include color-section;
	}
}

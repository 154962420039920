.exit_form {
  z-index: 99999;
  background: white;
  margin-left: 0;
  min-height: 635px;
  display: block;

  &.active {
    display: block;
  }

  form {
    .form-row {
      &:first-child {
        margin-top: 20px;
      }
    }
  }

  .m-grid__col {
    height: 100%;
    .m-tabs {
      height: 100%;
      .m-tabs__content {
        height: calc(100% - 70px);
        section {
          height: 100%;
          img {
            height: 565px;
          }
        }
      }
    }
  }

  .content {
    padding: 30px 25px;
  }

  p, h2 {
    text-align: center;
  }

  label {
    display: none;
  }

  select {
    border: 1px solid #cecece !important;
  }

  button, button:hover, button:active {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    width: 100%;
    border-color: rgb(0, 0, 0);
    border-width: 0px;
    border-radius: 0px;
    background: rgb(12, 52, 101);
    padding: 24px 16px;
  }

  span.close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 10px 0 0;
    font-size: 1.5rem;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    line-height: normal;
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    width: 100%;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgb(88, 89, 91);
    padding: 24px 16px;
    outline: none;
    border-radius: 0px;
    border-color: rgb(229, 233, 242);
    border-width: 1px;
  }
}

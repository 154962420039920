.m-agents {
	&__reservations {
		border: 20px solid #e7ecf2;
		padding: 20px;
		margin-bottom: 20px;

		&__header {
			display: flex;
			justify-content: space-between;
			padding: 10px 0 5px 0;

			&__subheader {
				text-align: right;
				font-size: 12px;
			}
		}

		&__data {
			width: 100%;
			box-sizing: border-box;

			&__row {
				padding: 5px 10px;
				box-sizing: border-box;
				font-size: 14px;
				display: flex;

				&--header {
					@extend .m-agents__reservations__data__row;
					background-color: lightgray;
				}

				&__column {
					display: inline-block;
					box-sizing: border-box;

					&--1 {
						@extend .m-agents__reservations__data__row__column;
						width: 10%;
					}

					&--2 {
						@extend .m-agents__reservations__data__row__column;
						width: 20%;
					}

					&--3-option {
						@extend .m-agents__reservations__data__row__column;
						width: 25%;
					}

					&--3-confirmation {
						@extend .m-agents__reservations__data__row__column;
						width: 30%;
					}

					&--4 {
						@extend .m-agents__reservations__data__row__column;
						width: 15%;
					}

					&--5 {
						@extend .m-agents__reservations__data__row__column;
						width: 15%;
					}

					&--6-option {
						@extend .m-agents__reservations__data__row__column;
						width: 15%;
					}

					&--6-confirmation {
						@extend .m-agents__reservations__data__row__column;
						width: 10%;
						text-align: center;
					}
				}

				&:nth-child(even) {
					background-color: #f8f9fb;
				}
			}

			&__container {
				overflow-y: auto;
				max-height: 200px;
			}


		}

		&__search-container{
			margin-bottom: 20px;
			padding: 4px;
			border-radius: 5px;
			display: flex;
			width: 100%;
			gap: 10px;
			box-sizing: border-box;
		}

		&__search-bar{
			width: 100%;
			max-width: 88%;
			padding: 10px 15px;
			font-size: 16px;
			font-family: $fontBaseLight;
			border: 1px solid $colorPurpleLight;
			box-sizing: border-box;
			transition: border 0.3s ease;
			border-radius: 5px;
			flex: 1;
			
			&::placeholder {
				color: #aaa;
				font-family: 'FuturaBT-Book';
				opacity: 1;
				transition: opacity 0.2s ease;
			  }
		
			  &:focus::placeholder {
				opacity: 0;
			  }
		}
	}

	&__content {
		padding: 0;
		margin: 0;

		&--bg {
			background: $colorOffWhite;
			margin: 0 auto 15px;
			padding: 15px;

			@include mq-min($bpMobileLarge) {
				padding: 25px;
			}

			@include mq-min($bpDesktop) {
				padding: 30px 45px 35px;
			}
		}
	}

	&__login {
		padding: 15px;
		margin: 20px auto;
		background: $colorOffWhite;

		&__forgot {
			margin: 15px 0 0;
		}

		&--equal-heights {
			@include mq-min($bpTabletLarge) {
				min-height: 395px;
			}
		}

		&__password-btn {
			@include mq-min($bpDesktop) {
				margin: 41px 0 41px;
			}

			@include mq-min($bpLarge) {
				margin: 66px 0 41px;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 27px 30px 30px;
			margin: 50px auto;
		}

		@include mq-min($bpLarge) {
			padding: 42px 45px 45px;
			margin: 60px auto;
		}

		&--tight {
			margin-bottom: 0;
		}
	}

	&__details {
		margin: 0;
		padding: 0;
		font-size: $fontSize;
		line-height: $fontHeight;

		&__title {
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
			padding: 0 0 5px;

			&--alt {
				padding: 15px 0;
			}

			&--alt-first {
				padding: 0 0 15px;
			}
		}

		&__summary,
		&__address,
		&__list {
			padding: 0 0 20px;
		}

		&__summary--alt {
			padding: 0;
		}

		&__list--tight {
			padding: 0;
		}

		&__preferences-list {
			padding: 0;

			&-item {
				padding-left: 23px;
				position: relative;

				&:before {
					@include bg('/images/common/sprite.png', no-repeat, -143px 0px);
					content: "";
					height: 16px;
					left: 0;
					position: absolute;
					top: 4px;
					width: 16px;
					z-index: 1;
				}

				&--active {
					&:before {
						background-position: -125px 0px;
					}
				}
			}
		}
	}

	&__filters {
		background-color: $colorPurplePale;
		padding: 10px;
		margin-bottom: 10px;
	}
}

﻿@import "../_config";

.m-help-overlay {
	background: rgba($colorBlack, .8);
	@include box-sizing();
	color: $colorWhite;
	font-size: $fontSize;
	line-height: $fontHeight;
	padding: 20px 20px 5px 20px;
	width: 100%;
	max-width: 320px;
	display: none;
	left: 0;
	margin: 0;
	position: absolute;
	top: 0;
	z-index: 1000;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__close {
		background: url(/images/common/sprite.png) no-repeat -74px -3px;
		display: block;
		height: 10px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 10px;
		@include text-hidden();
		top: 10px;
		width: 10px;
		z-index: 1;
		cursor: pointer;

		&:hover {
			@include opacity(0.5);
		}
	}

	&--alt {
		width: 300px;

		@include mq-min($bpTablet) {
			width: 320px;
		}
	}

	&__link {
		&--ad-reg {
			padding: 5px;
			color: $colorWhite;
		}
	}
}

.js-help-overlay-wrapper {
	position: relative;
	z-index: 1000;
}

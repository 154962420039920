﻿.c-airport-picker {
	position: absolute;
	left: -15px;
	right: -15px;
	top: 116px;
	background-color: #FFF;
	box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
	padding: 0;
	z-index: 99999;
	display: none;
	color: $colorBase;

	&.is-active {
		display: block;

		&:before {
			content: '';
			height: 4px;
			width: 100%;
			background-color: #FFF;
			position: absolute;
			top: -4px;
			left: 0;
			right: 0;
		}
	}
}

.c-airport-picker__container-positioner {
	position: relative;
	padding: 0;
}

.c-airport-picker__inner {
	position: relative;
	padding-bottom: 80px;
}

.c-airport-picker__list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.c-airport-picker__list-item label {
	padding-left: 34px;
	font-size: 15px;
}

.c-airport-picker__list-item--group label {
	font-family: $fontBaseMedium;
}

.c-airport-picker__list-item--disabled {
	color: #C9CDD1;

	.form-checks__item label input:disabled~span {
		color: #C9CDD1;
		opacity: 1;
	}

	.form-checks__item--small label span:before {
		background-color: $colorPurplePale;
	}

	.form-checks__item label:hover input:disabled~span:before,
	.form-checks__item label:focus input:disabled~span:before {
		border-color: $colorPurplePale;
	}
}

.c-airport-picker__body {
	padding: 20px;
}

.c-airport-picker__title {
	font-size: 16px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	border-bottom: 1px solid #AFAEAE;
	font-family: $fontBaseLight;
}

.c-airport-picker__footer {
	position: relative;
	z-index: 101;
	padding-top: 20px;
}

.c-airport-picker__grid {
	margin: 0 0 20px 0;
}

@include mq-max($bpTablet) {

	html.airport-picker-open {

		body {
			overflow: hidden;
		}

		.l-page__inner {
			z-index: 1100;
		}

		.l-nav-sub {
			background-color: transparent;
		}

		.l-utils--mobile-nav {
			display: none;
		}

		.l-header-sub-nav__search-sub {
			top: 0;
		}

		.m-section {
			position: static;
			visibility: hidden;
		}
	}

	.c-airport-picker.is-active {
		height: calc(100% - 50px);
		left: 0;
		margin: 0;
		padding: 0;
		position: fixed;
		top: 50px;
		width: 100%;
		z-index: 10000;
		background: rgba(0, 0, 0, 0.8);
	}

	.c-airport-picker__inner {
		background-color: $colorWhite;
		box-sizing: border-box;
		padding: 20px 0 80px 0;
		margin: 15px;
		height: 75%;
		top: 55px;
		overflow-y: scroll;
	}

	.c-airport-picker__inner-wrapper {
		height: calc(100% - 75px);
		/* Height of apply button + info + spacing */
		overflow-y: scroll;
	}

	.c-airport-picker__body,
	.c-airport-picker__grid {
		padding-bottom: 0;
	}

	.c-airport-picker__button.c-airport-picker__button--mobile {
		width: 55px;
		margin-left: 5px;
		padding: 5px 15px;
	}

	.c-airport-picker__hide-mobile {
		display: none !important;
	}

	.c-airport-picker__back-mobile,
	.c-airport-picker__close-mobile {
		font-size: 15px;
		font-family: inherit;
		line-height: 15px;
		color: #72acb3;
		position: absolute;
		top: 0;
		padding: 20px;
		z-index: 101;
		background-color: transparent;
		-webkit-appearance: none;
		border: none;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.c-airport-picker__close-mobile {
		right: 0;
	}

	.c-airport-picker__back-mobile {
		left: 0;
	}

	.c-airport-picker__icon {
		margin-top: -2px;
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
	}

	.c-airport-picker__footer {
		position: absolute;
		bottom: 30px;
		right: 20px;
		left: 20px;
		background-color: $colorWhite;
	}

	.c-airport-picker__apply {
		display: block;
		width: 100%;
		margin: 10px 0 0 0;
	}

	.c-airport-picker__footer-info {
		width: 100%;
	}
}

@include mq-min($bpTablet) {

	html.airport-picker-open body {
		margin-top: 0 !important;
		/* Overrides mobile JavaScript in cases where the user changes orientation from mobile to desktop. Saves adding another listener */
	}

	.c-airport-picker__inner {
		padding-bottom: 0;
	}

	.c-airport-picker.is-active .c-airport-picker__grid {
		display: flex;
	}

	.c-airport-picker__col {
		flex: 33.33%;
		padding: 0 25px;
	}

	.c-airport-picker__hide-desktop {
		display: none;
	}

	.c-airport-picker__list {
		max-width: 310px;
		margin: 0 0 20px 0;
	}

	.c-airport-picker__icon {
		position: absolute;
		top: 50%;
		margin-top: -8px;
		right: 15px;
		width: 16px;
		height: 16px;
	}

	.c-airport-picker__grid {
		display: flex;
		margin: 0;
	}

	.c-airport-picker__grid-col {
		flex: 1;
		padding-right: 30px;
	}

	.c-airport-picker__grid-col:not(:first-child) .c-airport-picker__group:not(:last-child) {
		border-bottom: 0.5px solid #AFAEAE;
		margin-bottom: 20px;
	}

	.c-airport-picker__footer {
		display: flex;
		align-items: center;
		border-top: 1px solid #AFAEAE;
	}

	.c-airport-picker__footer-info {
		width: calc(100% - 170px);
	}

	.c-airport-picker__apply {
		margin-left: auto;
	}
}

@include mq-min($bpDesktop) {

	.c-airport-picker__apply {
		width: 182px;
	}

	.c-airport-picker__body {
		padding: 20px 25px;
	}

	.c-airport-picker__footer-info {
		width: calc(100% - 202px);
	}

}

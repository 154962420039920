.m-press {
	margin: 0;
	padding: 0 0 15px 0;
	position: relative;
	z-index: 1;

	&__inner {
		margin: 0 auto;
		margin-bottom: 30px;
		padding: 0 40px;
	}

	&__menu {
		margin: 0;
		padding: 0;

		&__item {
			margin: 0;
			padding: 0;
		}

		&__quote {
			color: $colorGrey;
			font-family: $fontSerif;
			margin: 0 auto;
			padding: 0;
			text-align: center;
			max-width: $maxContentWidth;

			&__logo,
			&__text,
			&__author {
				margin: 0 auto;
				padding: 0;
			}

			&__logo {
				display: inline-block;
				padding-bottom: 10px;
			}

			&__text {
				font-size: $fontSize + 7;
				line-height: $fontHeight + 7;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				&--large {
					@include mq-min($bpTabletLarge) {
						font-size: $fontSize + 13;
						line-height: $fontHeight + 13;
					}
				}
			}

			&__author {
				display: inline-block;
				font-size: $fontSize + 3;
				line-height: $fontHeight + 3;
				padding: 10px 15px 0 15px;
				position: relative;
				z-index: 1;

				&:before {
					background: $colorGrey;
					content: "";
					display: block;
					height: 1px;
					left: 0;
					margin: 0;
					@include opacity();
					padding: 0;
					position: absolute;
					top: 22px;
					width: 10px;
					z-index: 1;
				}

				&--large {
					@include mq-min($bpTabletLarge) {
						font-size: $fontSize + 7;
						line-height: $fontHeight + 7;
					}
				}

				&--inline {
					padding-top: 0;

					&:before {
						top: 12px;
					}
				}
			}
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpLarge) {
		padding-bottom: 30px;
	}

	&--tight {
		padding-bottom: 0;
	}
}

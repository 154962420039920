.m-terms {
    &__list {
        @mixin orderedList($listStyle) {
            padding: 0 0 10px 35px;
            list-style: $listStyle;

            li {
                list-style: inherit;
            }
        }

        &--numeric {
            @include orderedList(decimal);
        }

        &--alpha {
            @include orderedList(lower-alpha);
        }

        &--lower-roman {
            @include orderedList(lower-roman);
        }

        &--upper-roman {
            @include orderedList(upper-roman);
        }
    }

    &__link {
        color: $colorTeal;
        text-decoration: none;
    }

    &__section-header {
        text-align: center;
    }
}

﻿.m-payment-cards {
	background: $colorOffWhite;
	margin: 15px 0 0 0;
	padding: 15px;
	text-align: center;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__title {
		font-size: $fontSize + 1;
		line-height: $fontHeight + 1;
		padding: 0 0 10px;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		@mixin paymentIconMixin {
			padding: 10px 0px;
			margin: 0;
		}

		&__icon {
			@include paymentIconMixin;
			height: 35px;

			&--visa {
				@include paymentIconMixin;
				height: 20px;
			}

			&--paypal {
				@include paymentIconMixin;
				height: 25px;
			}
		}
	}

	&--block {
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
	}

	&--tight {
		margin-bottom: 0;
	}
}

﻿.c-tabs-simple {
	margin: 0 0 15px 0;
	padding-top: 20px;
}

.c-tabs-simple--flush {
	padding-top: 0;
}

@include mq-max($bpTablet) {
	.c-tabs-simple--pull-sides-mobile {
		overflow: hidden;
		margin-left: -10px;
		margin-right: -10px;
	}
}

.c-tabs-simple__menu {
	margin: 0;
	margin-bottom: -2px;
	padding: 0;
	display: flex;
	width: 100%;
}

.c-tabs-simple__menu--search-results {
	display: block;
	border-bottom: 1px solid $colorPurplePale;
}

.c-tabs-simple__title {
	border-top: 1px solid $colorGrey;
	border-top-color: rgba($colorGrey, .2);
	width: auto;
	margin: 0;
	padding: 0;
	border: 0;
	display: inline-block;
	flex-grow: 1;
	@include text-overflow();
	text-align: center;
}

.c-tabs-simple__title:not(:first-child) {
	border-left: 1px solid $colorWhite;
}

.c-tabs-simple__title--search-results {
	float: left;

	@include mq-min($bpTablet) {
		width: 25%;
	}

	flex-grow: unset;
}

.c-tabs-simple__link {
	@include box-sizing();
	color: $colorGrey;
	background: $colorWhite;
	display: block;
	font-family: $fontBase;
	@include min-height(40px);
	line-height: $fontHeight;
	margin: 0;
	border-bottom: 1px solid $colorPurplePale;
	border-top: 1px solid $colorWhite;
	text-decoration: none;
	font-size: $fontSize - 1;
	padding: 15px 10px;
	text-align: center;
	text-transform: none;
	position: relative;

	.fa-solid {
		position: absolute;
		top: 18px;
		left: 25px;
		font-size: 20px
	}
}

.c-tabs-simple__link--search-results {
	padding: 15px 50px;
	border-bottom: 1px solid $colorPurplePale;
	font-family: $fontBaseMedium;
}

.c-tabs-simple__link__icon {
	position: absolute;
	left: 12%;
	top: 33%;
	height: 17px;
	width: 17px;
}

.c-tabs-simple__link__icon--large {
	height: 21px;
	top: 50%;
	transform: translateY(-50%);
}

.c-tabs-simple__link.is-active,
.c-tabs-simple__link.is-active:hover,
.c-tabs-simple__link:hover {
	background: $colorPurplePale;
	color: $colorGrey;
	text-decoration: none;
	border-bottom: 1px solid $colorPurplePale;
	font-family: $fontBase;
}

.c-tabs-simple__link:focus,
.c-tabs-simple__link.is-active:focus {
	border: 1px solid $colorBorder;
}

.c-tabs-simple__content {
	background: $colorPurplePale;
	padding: 10px;
}

﻿.c-home-search {
	text-align: left;
	max-width: $maxPageWidth;
	margin: 0 auto;
	padding: 10px 10px 0 10px;
	margin-bottom: -5px;
}

@include mq-min($bpDesktopMid) {
	.c-home-search {
		padding: 10px 0 0 0;
		margin-bottom: -15px;
		max-width: 85%;
		width: 85%;
	}
}

@include mq-min($bpMedium) {
	.c-home-search {
		margin-bottom: -40px;

	}
}

@include mq-min($bpLarge) {
	.c-home-search {
		margin-bottom: -20px;
	}
}

﻿.c-destinations-picker {
	position: absolute;
	left: -15px;
	right: -15px;
	top: 116px;
	/* Height of inputs (48px) + padding top on search form (50px) + spaced required underneath input (18px) */
	background-color: #FFF;
	box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
	padding: 25px 0;
	z-index: 99999;
	display: none;

	&.is-active {
		display: block;

		&:before {
			content: '';
			height: 4px;
			width: 100%;
			background-color: #FFF;
			position: absolute;
			top: -4px;
			left: 0;
			right: 0;
		}
	}
}

.c-destinations-picker__container-positioner {
	position: relative;
	padding: 0;
}

.c-destinations-picker--in-sidebar {
	top: 60px;
	left: 0;
	right: 0;
	width: 960px;
}

.c-destinations-picker__inner {
	position: relative;
}

.c-destinations-picker__list {
	margin: 0;
	padding: 0;
	list-style: none;
	display: none;
}

.c-destinations-picker__list.is-active {
	display: block;
	position: relative;
}

.c-destinations-picker__list:empty {
	display: none;
}

.c-destinations-picker__list .c-destinations-picker__list-item {
	margin-bottom: 5px;
	padding-bottom: 0;
}

.c-destinations-picker__button {
	position: relative;
	width: 100%;
	cursor: pointer;
	background-color: #E7ECF2;
	color: $colorBase;
	text-align: left;
	padding: 11px 15px;
	text-transform: uppercase;
	font-family: $fontBase;
	font-size: 15px;
	border: 1px solid transparent;
	-webkit-appearance: none;
	transition: background-color 300ms;
}

.c-destinations-picker__button:hover,
.c-destinations-picker__button.is-active {
	background-color: #8896AB;
	color: #FFF;
}

.c-destinations-picker__button:focus {
	border-color: #8896AB;
}

.c-destinations-picker__apply {
	bottom: 15px;
}

@include mq-max($bpTablet) {

	html.destinations-picker-open {

		body {
			overflow: hidden;
		}

		.l-page__inner {
			z-index: 1100;
		}

		.l-nav-sub {
			background-color: transparent;
		}

		.l-utils--mobile-nav {
			display: none;
		}

		.l-nav-sub__search-sub {
			top: 0;
		}

		.m-section {
			position: static;
			visibility: hidden;
		}
	}

	.c-destinations-picker.is-active {
		height: calc(100% - 50px);
		left: 0;
		margin: 0;
		padding: 0;
		position: fixed;
		top: 50px;
		width: 100%;
		z-index: 10000;
		background: rgba(0, 0, 0, 0.8);
	}

	.c-destinations-picker__inner {
		background-color: $colorWhite;
		box-sizing: border-box;
		padding: 50px 20px 80px 20px;
		margin: 15px;
		height: 75%;
		top: 55px;
	}

	.c-destinations-picker__inner-wrapper {
		max-height: 90vh;
		height: 100%;
		overflow-y: scroll;
	}


	.c-destinations-picker__list-item {
		display: flex;
	}

	.c-destinations-picker__button.c-destinations-picker__button--mobile {
		width: 55px;
		margin-left: 5px;
		padding: 5px 15px;
	}

	.c-destination__hide-mobile {
		display: none !important;
	}

	.c-destinations-picker__back-mobile,
	.c-destinations-picker__close-mobile {
		font-size: 15px;
		font-family: inherit;
		line-height: 15px;
		color: #72acb3;
		position: absolute;
		top: 0;
		padding: 20px;
		z-index: 101;
		background-color: transparent;
		-webkit-appearance: none;
		border: none;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.c-destinations-picker__close-mobile {
		right: 0;
	}

	.c-destinations-picker__back-mobile {
		left: 0;
	}

	.c-destinations-picker__icon {
		margin-top: -2px;
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
	}

	.c-destinations-picker__apply {
		position: absolute;
		right: 20px;
	}
}

@include mq-min($bpTablet) {

	html.destinations-picker-open body {
		margin-top: 0 !important;
		/* Overrides mobile JavaScript in cases where the user changes orientation from mobile to desktop. Saves adding another listener */
	}

	.c-destinations-picker {
		max-width: calc(100vw - 80px);
	}

	.c-destinations-picker.is-active .c-destinations-picker__grid {
		display: flex;
	}

	.c-destinations-picker__col {
		flex: 33.33%;
		padding: 0 25px;
	}

	.c-destinations-picker__hide-desktop {
		display: none;
	}

	.c-destinations-picker__list {
		max-width: 310px;
	}

	.c-destinations-picker__icon {
		position: absolute;
		top: 50%;
		margin-top: -8px;
		right: 15px;
		width: 16px;
		height: 16px;
	}

	.c-destinations-picker__list--small.is-active {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: -35px;
			display: block;
			height: 35px;
			width: 19px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC43MiIgaGVpZ2h0PSIzNSIgdmlld0JveD0iMCAwIDE4LjcyIDM1Ij4gIDxwYXRoIGlkPSJQYXRoXzEwMSIgZGF0YS1uYW1lPSJQYXRoIDEwMSIgZD0iTTAsMCwxNi42MzMsMTcuNjQ5LDM0LjI4MiwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjM1NCAzNC42MzYpIHJvdGF0ZSgtOTApIiBmaWxsPSJub25lIiBzdHJva2U9IiM3MDcwNzAiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPg==);
			background-repeat: no-repeat;
			background-position: 0 0;
		}
	}

	.c-destinations-picker__button--small {
		padding: 5px 15px;
		text-transform: none;
		font-size: 16px;
		font-family: $fontBaseLight
	}


	.c-destinations-picker__list--small .c-destinations-picker__list-item {
		margin-bottom: 3px;
	}

	.c-destinations-picker__apply {
		position: absolute;
		bottom: 0;
		right: 25px;
		margin-top: 0;
		z-index: 101;
	}

	.c-destinations-picker__col.is-hidden {
		display: none;
	}

	.c-destinations-picker__image {
		display: block;
		width: 100%;
	}

	.c-destinations-picker__col--image {
		flex: 66.66%;
		flex-basis: calc(66.66% + 60px);
		padding-bottom: 60px;
		/* For the apply button */
	}
}

@include mq-min($bpDesktop) {

	.c-destinations-picker__apply {
		width: 182px;
	}

}

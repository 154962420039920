.m-featured-property {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__header,
	&__header-wishlist {
		font-size: 0;
		min-height: 36px;
		line-height: 0;
		margin: 0 0 5px;
		padding: 5px 0 0 60px;
		position: relative;
		z-index: 2;

		&--no-collection {
			padding-left: 0;
		}
	}

	&__link {
		color: $colorBase;
		display: block;
		margin: 0;
		padding: 0;
		text-decoration: none;

		&:hover {
			color: $colorBase;
			text-decoration: none;

			.m-featured-property__image__image {
				@include image-hover-anim();
			}
		}
	}

	&__image {
		margin: 0 0 10px 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;

		&__image {
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
			@include image-hover();
		}
	}

	&__title,
	&__location,
	&__rooms {
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
	}

	&__title {
		font-family: $fontBase;
		margin: 0;
		padding: 0;

		&--wishlist {
			font-size: $fontSize;
			line-height: $fontHeight - 4;
		}
	}

	&__location,
	&__location-inline {
		color: $colorGrey;
		min-height: 48px;
		margin: 0;
		padding: 5px 0 0 0;
	}

	&__location-inline {
		font-size: $fontSize - 2;
		padding: 0;
	}

	&__rooms {
		margin: 0;
		padding: 5px 0 0 0;

		&__item {
			@include box-sizing();
			color: $colorGrey;
			display: inline-block;
			margin: 0;
			padding: 0 16px 0 0;
			position: relative;
			vertical-align: top;
			z-index: 1;

			&:before {
				background: $colorGrey;
				content: "";
				display: block;
				height: 13px;
				margin: 0;
				@include opacity();
				padding: 0;
				position: absolute;
				right: 7px;
				top: 6px;
				width: 1px;
				z-index: 1;
			}

			&:last-child {
				padding-right: 0;

				&:before {
					display: none;
				}
			}
		}

		&--wishlist {
			padding: 0;
			font-size: $fontSize - 2;
		}
	}

	&__price {
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 10px 0 0 0;

		&--wishlist {
			padding: 5px 0 0;
		}
	}

	&--wishlist {
		margin: 0 auto;
		position: relative;
		//z-index: 1;
		max-width: 600px;
	}

	&__wishlist-remove {
		background: $colorPurple;
		border: 1px solid $colorPurple;
		color: $colorWhite;
		cursor: pointer;
		font-family: $fontSimple;
		font-size: $fontSize - 1;
		height: 29px;
		line-height: $fontHeight - 9;
		padding: 5px;
		position: absolute;
		right: -10px;
		text-align: center;
		top: -10px;
		width: 31px;
		z-index: 2;

		&:hover {
			background: $colorWhite;
			border-color: $colorPurple;
			color: $colorPurple;
		}
	}
}

﻿.m-search-block {
	background: $colorPurplePale;
	color: $colorBrightGrey;
	margin: 0 0 15px 0;
	padding: 15px;
	position: relative;

	&__inner {
		margin: 0 auto;
		padding: 0;
		position: relative;
		max-width: 100%;
	}

	&__form {
		padding: 0;
		margin-top: auto;

		&__false-input-wrapper {
			overflow: hidden;
		}

		&__false-input {
			font-size: 15px;
			line-height: 25px;
			color: $colorBase;
			padding: 7px 8px 7px 8px;
			font-family: Helvetica, Arial, Verdana, sans-serif;
			cursor: pointer;
			white-space: nowrap;
			overflow: hidden;
		}

		&--tight {
			margin-bottom: 0;
		}

		&__field-with-dropdown input {
			cursor: pointer;
		}

		.m-search-block__accommodation-only-divider {
			display: flex;
			border-left: 1px solid rgba($colorBrightGrey, 0.2);
		}

		.m-search-block__accomodation-only {
			display: flex;

			.form-checks__item {
				line-height: inherit;
			}
		}

		.m-search-block__accomodation-only-label {
			font-size: 14px;
			padding-left: 0 !important;
		}

		@include mq-min($bpTablet) {
			position: relative;
			padding-top: 10px;

			&__field-with-dropdown.is-active {
				position: relative;
				overflow: visible;
			}

			&__field-with-dropdown.is-active:after {
				position: absolute;
				bottom: -19px;
				right: 0;
				left: 0;
				content: '';
				background-color: #FFF;
				height: 21px;
				width: 100%;
				z-index: 999;
			}

			.m-search-block__form__row--people .m-search-block__form__field-with-dropdown.is-active:after {
				width: calc(100% + 2px);
				left: -1px;
			}
		}

		@include mq-min($bpLarge) {
			.m-search-block__accomodation-only-label {
				font-size: 100%;
			}
		}
	}

	&__loader {
		background: rgba($colorWhite, .9);
		bottom: 0;
		display: none;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		@include text-hidden();
		right: 0;
		top: 0;
		z-index: 3;
		width: 100%;
		height: 100%;

		&:before {
			@include bg($svgLoader, no-repeat, 50% 0);
			content: "";
			display: block;
			height: 100px;
			left: 50%;
			margin: -22px 0 0 -20px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 100px;
			z-index: 1;
		}
	}

	.form-select,
	.form-text,
	.select,
	.js-quantity-button {
		font-family: inherit;
		font-size: 15px;

		&:focus {
			outline: 1px dotted $colorPurple;
		}
	}

	.select--faux__inner {
		font-family: inherit;
		font-size: inherit;
	}

	&__message.c-info {
		display: none;

	}

	@include mq-max($bpTablet) {
		&__message--mobile.c-info {
			&.is-active {
				display: flex;
				margin: 0 0 20px 0;
			}
		}
	}

	@include mq-min($bpTablet) {
		&__message--desktop.c-info {
			&.is-active {
				display: flex;
				margin: 20px 0 0 0;
			}
		}
	}
}

.m-search-block__form__inner {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	align-items: flex-start;
	@include box-sizing();
}

.m-search-block__form__row,
.m-search-block__form__action {
	flex: 0 1 auto;
	width: 100%;

	.form-button {
		min-height: 48px;

		&:hover,
		&:focus {
			border-color: $colorWhite;
		}
	}

	.form-text,
	.select {
		max-width: 100%;
	}
}

.m-search-block__form__row {
	padding: 0 0 20px 0;

	&__label {
		display: flex;
		padding-bottom: 10px;
		justify-content: space-between;
	}
}

.m-search-block__search-results-page {
	position: relative;
	z-index: 1001;
}

.m-search-block__search-results-page .m-tabs {
	margin-bottom: 0;
}

.m-search-block__form__action--mobile-search {
	display: none;
}

.m-search-block__form__row--destination input:hover {
	cursor: pointer;
}

@include mq-max($bpDesktop) {
	.m-search-block__search-results-page {
		opacity: 0;
		max-height: 0;
		overflow: hidden;
		transition: opacity 300ms, max-height 300ms;
	}

	.m-search-block__search-results-page.is-active {
		opacity: 1;
		max-height: 750px;
		/* Height of search form */
		padding-top: 40px;
		overflow: inherit;
	}

	.m-search-block__search-results-page .m-search-block__form__action--mobile-search {
		display: block;
	}

	.m-search-block__search-results-page .m-search-block {
		margin-bottom: 0;
	}
}

@include mq-min($bpTablet) {

	.m-search-block__form__row,
	.m-search-block__form__action {
		width: 49%;
		padding: 0;
	}

	.m-search-block__form__row--destination,
	.m-search-block__form__row--departing {
		padding: 0 0 20px 0;
	}

	.m-search-block__form__row--airport,
	.m-search-block__form__row--people {
		margin-left: auto;
	}

	.m-search-block__form__row--departing .m-search-block__form__field-with-dropdown.is-active .form-text {

		&:hover,
		&:focus {
			border-bottom: 1px solid transparent;
		}
	}

	.m-search-block__form__row--destination,
	.m-search-block__form__row--departing,
	.m-search-block__form__row--people {
		cursor: pointer;
	}

	.m-search-block__accomodation-only {
		top: 5px;
		left: 51%;
		color: $colorBrightGrey;
	}
}

@include mq-min($bpDesktop) {

	.m-search-block__form__inner {
		flex-wrap: nowrap;
	}

	.m-search-block__form__action {
		padding: 0 15px;
	}

	.m-search-block__form__row {
		border-right: 1px solid rgba($colorBrightGrey, 0.2);
		padding: 0 15px;
		font-size: 15px;
	}

	.m-search-block__form__row--destination {
		width: 33%;
	}

	.m-search-block__form__row--airport,
	.m-search-block__form__row--departing,
	.m-search-block__form__row--people {
		width: 20%;
	}

	.m-search-block__form__action {
		width: 16%;
		padding-right: 0;
		margin-top: auto;
	}

	.m-search-block__form__row--full {
		width: 100%;
	}

	.m-search-block__search-results-page {
		margin-top: -10px;

		.m-search-block__form__row--people {
			padding-right: 0;
		}
	}
}

@include mq-min($bpLarge) {
	.m-search-block__search-results-page {
		margin-top: -15px;
	}

	.m-search-block__form__row {
		font-size: 100%;
	}
}

@include mq-max($bpTablet) {
	html.search-picker-open--nav {
		.l-nav {
			z-index: 13;
		}
	}
}

﻿.m-share {
	margin: 0;
	padding: 15px 0;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__title {
		color: $colorGrey;
		font-family: $fontBase;
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 0 0 10px 0;
	}

	&__content {
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 0;

		&__item {
			display: inline-block;
			margin: 0;
			padding: 5px 0 0 5px;

			@include mq-min($bpDesktop) {
				padding: 10px 0 0 10px;
			}
		}

		&__link {
			@include border-radius(2px);
			display: block;
			height: 30px;
			margin: 0;
			padding: 0 5px 0 0;
			position: relative;
			width: 30px;
			z-index: 1;

			&:hover {
				color: $colorBlue;
			}

			&--alt {
				background: $colorWhite;
				border-color: $colorBlue;

				&:hover {
					background: $colorBlue;
				}
			}

			&--large {
				@include mq-min($bpDesktop) {
					height: 40px;
					width: 40px;
				}
			}

			&--email {
				&:before {
					background-position: -150px -26px;
				}

				&:hover {
					&:before {
						background-position: -150px -56px;
					}
				}

				&--alt {
					&:before {
						background-position: -150px -56px;
					}

					&:hover {
						&:before {
							background-position: -150px -26px;
						}
					}
				}
			}

			&--print {
				&:before {
					background-position: -180px -26px;
				}

				&:hover {
					&:before {
						background-position: -180px -56px;
					}
				}

				&--alt {
					&:before {
						background-position: -180px -56px;
					}

					&:hover {
						&:before {
							background-position: -180px -26px;
						}
					}
				}
			}

		}

		.fab {
			font-size: 40px;
			color: $colorAction;
			background-color: $colorPurplePale;
		}

		.fa-brands {
			font-size: 40px;
		}

		.fab2 {
			color: $colorPurple;
			background-color: white;
		}

		@include mq-min($bpDesktop) {
			margin: -10px 0 0 -10px;

			.l-sitemap__group & {
				margin: -3px 0 0 -10px;
			}
		}
	}

	@include mq-min($bpTabletLarge) {
		padding: 20px 0;
	}

	@include mq-min($bpDesktop) {
		padding: 25px 0;
	}

	@include mq-min($bpLarge) {
		padding: 30px 0;
	}

	&--divider {
		border-top: 1px solid $colorDivider;
		margin-top: 15px;

		@include mq-min($bpTabletLarge) {
			margin-top: 20px;
		}

		@include mq-min($bpDesktop) {
			margin-top: 25px;
		}

		@include mq-min($bpLarge) {
			margin-top: 30px;
		}
	}

	&--close {
		padding-top: 0;
	}

	&--tight {
		padding-bottom: 0;
	}
}


nav .m-share__menu .fa-brands {
	color: #0c3465;
}

﻿@import "../_config";

.m-help-pin
{
	background: url(/images/common/sprite.png) no-repeat -99px 0px;
	display: inline-block;
	height: 17px;
	margin: -2px 0 0;
	padding: 0;
	@include text-hidden();
	vertical-align: middle;
	width: 17px;
	&:hover
	{
		@include opacity(0.5);
	}
}
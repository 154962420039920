﻿.m-feature {
	background: $colorWhite;
	margin: 0 0 15px 0;
	padding: 15px;
	position: relative;
	text-align: center;
	z-index: 1;

	&__link {
		color: $colorBase;
		display: block;
		margin: 0;
		padding: 0;
		text-decoration: none;

		&:hover {
			color: $colorBase;
			text-decoration: none;
		}
	}

	&__title {
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
		margin: 0;
		padding: 0 0 15px 0;
		@include text-overflow();

		@include mq-min($bpDesktop) {
			padding-bottom: 20px;
		}
	}

	&__image {
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;

		&__image {
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
			@include image-hover();
		}

		&--video {

			.fa-regular,
			.fa-solid {
				position: absolute;
				top: 30%;
				left: 44%;
				font-size: 65px;
				color: rgba(255, 255, 255, .9);
				z-index: 10;
			}
		}
	}

	&__action {
		background: $colorWhite;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: 15px 10px;
		position: absolute;
		right: 0;
		z-index: 1;

		@include mq-min($bpDesktop) {
			padding: 20px 10px;
		}
	}

	&:hover {
		.m-feature__image__image {
			@include image-hover-anim();
		}
	}

	@include mq-min($bpTabletLarge) {
		padding: 15px 20px;
	}

	@include mq-min($bpDesktop) {
		padding: 20px 25px;
	}

	@include mq-min($bpLarge) {
		padding: 20px 30px;
	}

	&--tight {
		margin-bottom: 0;
	}
}

.m-marketing {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		text-align: center;

		&__item {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 2;
			margin: 0 auto;
			padding: 0 0 10px 0;
			text-align: left;
			vertical-align: top;
			width: 100%;
			max-width: 800px;

			&:hover {
				.m-marketing__menu__image__image {
					@include image-hover-anim();
				}
			}

			@include mq-min($bpTablet) {
				padding: 12px 0 0 12px;
				width: 50%;
			}

			@include mq-min($bpTabletLarge) {
				width: 33.3333%;
			}

			@include mq-min($bpDesktop) {
				float: left;
				padding: 0;
				width: 25%;
			}

			&--featured {
				@include mq-min($bpTablet) {
					width: 100%;
				}

				@include mq-min($bpTabletLarge) {
					width: 50%;
				}

				@include mq-min($bpDesktop) {
					font-size: $fontSize;
					line-height: $fontHeight;
				}
			}

			&--simple {
				font-size: $fontSize !important;
				line-height: $fontHeight !important;

				@include mq-min($bpTabletLarge) {
					width: 50% !important;
				}

				@include mq-min($bpDesktop) {
					width: 25% !important;
				}
			}
		}

		&__link {
			color: $colorBase;
			display: block;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:before {
				border: 6px solid $colorWhite;
				bottom: 0;
				content: "";
				display: none;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 3;
				display: block;
			}

			&:hover {
				color: $colorBase;
				text-decoration: none;
			}

			&--video {
				&:after {
					@include bg('/images/common/sprite.png', no-repeat, -38px -138px);
					content: "";
					display: block;
					height: 66px;
					left: 50%;
					margin: -33px 0 0 -33px;
					padding: 0;
					position: absolute;
					top: 45%;
					width: 66px;
					z-index: 1;
				}
			}
		}

		&__image {
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: relative;
			z-index: 1;

			&__image {
				display: block;
				margin: 0;
				padding: 0;
				width: 100%;
				@include image-hover();
			}
		}

		&__title {
			background: $colorPurplePale;
			@include box-sizing();
			font-family: $fontBase;
			height: 52px;
			line-height: $fontHeight - 7;
			margin: 0;
			padding: 13px 15px;
			position: relative;
			z-index: 2;

			@include mq-min($bpTablet) {
				bottom: 5px;
				left: 5px;
				position: absolute;
				right: 5px;

				.csstransforms & {
					span {
						display: block;
						position: relative;
						top: 50%;
						@include transform(translateY(-50%));
					}
				}
			}

			&--featured {
				@include mq-min($bpTablet) {
					padding: 13px 15px;
					@include text-overflow();
				}
			}

			&--color-682b4c // secrets

				{
				background: $colorCollectionSecrets;
				color: $colorWhite;
			}

			&--color-936492 // hotels

				{
				background: $colorCollectionHotels;
				color: $colorWhite;
			}

			&--color-6993cd // creche

				{
				background: $colorCollectionCreche;
				color: $colorWhite;
			}

			&--color-5c7e97 // exclusive

				{
				background: $colorCollectionExclusive;
				color: $colorWhite;
			}

			&--color-0d2139 {
				background: $colorCollectionTangaroa;
				color: $colorWhite;
			}

			&--color-4fb3c4 {
				background: $colorCollectionTurquoise;
				color: $colorWhite;
			}

			&--color-b6dcd0 {
				background: $colorCollectionCastro;
				color: $colorBrightGrey;
			}
		}

		@include mq-min($bpTablet) {
			margin: -12px 0 0 -12px;
		}

		@include mq-min($bpDesktop) {
			margin: -6px;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpLarge) {
		padding-bottom: 30px;
	}

	&--tight {
		padding-bottom: 0;
	}
}

#cookieBar {
    line-height: 24px;
    text-align: center;
    padding: 8px 0;
    font-size: 1em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    background: #444;
    transition: transform .3s;
    opacity: 0.9;
    color: #ffffff;
}
  
.cb-enable, .cb-enable:hover {
    color: #222;
	background-color: #ffbe1a;
}
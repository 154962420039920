.m-banner__inner .bx-wrapper {
	height: 265px;


	@include mq-min($bpTablet) {
		height: 430px;
	}

	@include mq-min($bpDesktop) {
		height: 450px;
	}

	@include mq-min($bpLarge) {
		height: 506px;
	}
}

.m-banner__inner .bx-wrapper .bx-viewport {
	height: 100% !important;

}

.m-banner {
	margin: 0 auto -1px auto;
	padding: 0;
	position: relative;
	max-width: $maxPageWidth;
	z-index: 4;

	&__inner {
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;

		.bx-prev {
			display: none;

			&:before {
				content: "ABC";
				background: none;
			}
		}

		.bx-next {
			display: none;

			&:before {
				content: "DEF";
				background: none;
			}
		}

		.bx-pager {
			width: auto;
			top: 5%;
			left: 25px;
		}

		.bx-pager-link {
			border: 0;
		}

		.bx-pager-link.active {
			background: $colorAction;
		}

		.bx-controls {
			@mixin control {
				color: $colorWhite;
				position: absolute;
				z-index: 100;
				top: 5%;
				padding: 2px 4px;

				&:hover {
					color: $colorOffWhite;
					background-color: $colorAction;
				}

				@include mq-min($bpDesktop) {
					top: auto;
					bottom: 5%;
				}
			}

			&--prev {
				@include control();
				right: 65px;
			}

			&--next {
				@include control();
				right: 25px;
			}
		}
	}

	&__menu {
		margin: 0;
		padding: 0;
		height: inherit;

		&__item {
			background: $colorOffWhite;
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			height: inherit;

			@include mq-min($bpDesktop) {
				height: 450px;
			}

			@include mq-min($bpLarge) {
				height: 506px;
			}

			&--video {
				overflow: hidden;
			}
		}

		&__link {
			display: block;
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
		}

		&__image {
			display: block;
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			width: 100%;
			object-fit: cover;
			height: 100%;

			@include mq-min($bpDesktop) {
				height: 450px;
			}

			@include mq-min($bpLarge) {
				height: 506px;
			}
		}

		&__video {
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			object-fit: cover;
			object-position: center;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 3;
		}

		&__caption {
			background: $colorOffWhite;
			bottom: 5%;
			color: $colorAction;
			left: 25px;
			margin: 0;
			padding: 5px 15px;
			position: absolute;
			@include text-overflow();
			text-align: center;
			z-index: 4;

			@include mq-min($bpTabletLarge) {
				font-size: $fontSize + 2;
				line-height: $fontHeight + 2;
			}

			&:before {
				font-family: $fontAwesome;
				font: var(--fa-font-solid);
				content: "\f3c5";
				padding-right: 10px;
			}
		}
	}

	&__content {
		border-bottom: 1px solid $colorDivider;
		margin: 0;
		padding: 10px;

		&__menu {
			margin: 0;
			padding: 0;

			@include mq-min($bpTablet) {
				text-align: left;
			}

			@include mq-min($bpDesktop) {
				float: left;
				padding: 7px 0;
			}
		}

		&__actions {
			margin: 0;
			padding: 15px 0 0 0;
			text-align: center;

			@include mq-min($bpTablet) {
				text-align: left;
			}

			@include mq-min($bpTabletLarge) {
				padding-top: 20px;
			}

			@include mq-min($bpDesktop) {
				float: right;
				padding-top: 0;
				text-align: right;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 15px 10px;
		}

		@include mq-min($bpDesktop) {
			padding: 20px 10px;
		}

		@include mq-min($bpDesktopClear) {
			padding: 20px 0;
		}
	}

	&__play {
		@include bg('/images/common/sprite.png', no-repeat, -104px -138px);
		display: block;
		height: 49px;
		left: 50%;
		margin: -25px 0 0 -25px;
		padding: 0;
		position: absolute;
		@include text-hidden();
		top: 50%;
		width: 49px;
		z-index: 3;

		@include mq-min($bpTabletLarge) {
			background-position: -38px -138px;
			height: 66px;
			margin: -33px 0 0 -33px;
			width: 66px;
		}
	}

	&__logo {
		bottom: 10px;
		position: absolute;
		right: 10px;
		text-align: right;
		width: 50px;
		z-index: 5;

		&__image {
			display: inline-block;
			margin: 0;
		}

		@include mq-min($bpMobileLarge) {
			bottom: 20px;
			right: 20px;
			width: 100px;
		}

		@include mq-min($bpTablet) {
			bottom: 25px;
			right: 25px;
			width: 115px;
		}

		@include mq-min($bpDesktop) {
			bottom: 35px;
			right: 35px;
			width: 130px;
		}

		@include mq-min($bpLarge) {
			bottom: 50px;
			right: 50px;
			width: 155px;
		}
	}

	&__gallery-link {
		bottom: 0;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 100;
	}

	@include mq-min($bpLarge) {
		max-width: $maxPageWidthLarge;
	}

	@include mq-min($bpDesktop) {
		padding-top: 45px;
	}
}

﻿.m-quotes {
	border-bottom: 1px solid $colorDivider;
	border-top: 1px solid $colorDivider;
	margin: 0 0 15px 0;
	padding: 15px 0;
	position: relative;
	z-index: 1;

	&__inner {
		margin: 0;
		padding: 0;
	}

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
		padding: 20px 0;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
		padding: 25px 0;
	}

	@include mq-min($bpLarge) {
		margin-bottom: 30px;
		padding: 30px 0;
	}

	&--tight {
		margin-bottom: 0;
	}
}

﻿$bpMapCutoff: 880px;

.m-explore {
	margin: 0 auto;
	padding: 0;
	position: relative;
	max-width: 800px;
	z-index: 1;

	&__image {
		display: none;
		margin: 0 auto;
		padding: 0;
		position: relative;
		z-index: 1;

		@include mq-min($bpMapCutoff) {
			display: block;
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -12px 0 0 -12px;
		padding: 0;
		position: relative;
		text-align: left;
		z-index: 2;

		&__item {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 12px 0 0 12px;
			text-align: left;
			vertical-align: top;
			width: 100%;

			@include mq-min($bpMobileLarge) {
				width: 50%;
			}

			@include mq-min($bpTablet) {
				width: 33.3333%;
			}

			@include mq-min($bpMapCutoff) {
				display: block;
				height: 50px;
				left: 0;
				margin: -25px 0 0 -50px;
				padding: 0;
				position: absolute;
				text-align: center;
				top: 0;
				width: auto;
				z-index: 1;
			}
		}

		&__link {
			background: $colorPurple;
			color: $colorWhite;
			font-family: $fontBase;
			display: block;
			margin: 0;
			padding: 10px;
			text-decoration: none;
			text-transform: uppercase;

			&__inner {
				display: block;
				margin: 0;
				padding: 0;

				&:before {
					border: 8px solid transparent;
					border-top-color: $colorPurple;
					border-bottom-width: 0;
					bottom: -8px;
					content: "";
					display: none;
					height: 0;
					left: 50%;
					margin: 0 0 0 -9px;
					padding: 0;
					position: absolute;
					width: 0;
					z-index: 1;

					@include mq-min($bpMapCutoff) {
						display: block;
					}
				}

				@include mq-min($bpMapCutoff) {
					background: $colorPurple;
					bottom: 14px;
					display: block;
					left: 50%;
					margin: 0;
					@include opacity(0);
					padding: 5px 15px;
					position: absolute;
					text-align: center;
					@include transform(translateX(-50%));
					@include transition(all .2s);
					min-width: 80px;
					z-index: 2;
				}

				.touchevents & {
					@include mq-min($bpMapCutoff) {
						bottom: 40px;
						@include opacity(1);
					}
				}
			}

			&:hover,
			&:focus {
				background: $colorPurpleLight;
				color: $colorWhite;
				text-decoration: none;
				z-index: 2;

				.js-title {
					@include mq-min($bpMapCutoff) {
						bottom: 40px;
						@include opacity(1);
					}
				}

				@include mq-min($bpMapCutoff) {
					background: none;
				}
			}

			@include mq-min($bpMapCutoff) {
				background: none;
				height: 50px;
				margin: 0;
				padding: 0;
				position: relative;
				width: 100px;
				z-index: 1;
			}
		}

		@include mq-min($bpMapCutoff) {
			bottom: 0;
			left: 0;
			margin: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
	}
}

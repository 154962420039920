﻿$cardButtonWidth: 144px;

.c-card {
	background: $colorOffWhite;
	margin: 0 auto 20px auto;
	max-width: 400px;

	@include mq-min($bpDesktop) {
		max-width: none;
		margin-bottom: 25px
	}

	&__main {
		display: flex;
		flex-direction: column;

		@include mq-min($bpDesktop) {
			flex-direction: row;
		}

		&__col {
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: space-between;

			&--lhs {
				border-right: 1px solid $colorPurplePale;
			}

			&__image {
				position: relative;

				&__collection {
					&:hover {
						.m-help-overlay {
							display: block;
						}

						&.m-help-overlay__close {
							display: none;
						}
					}

					&__link {
						display: block;

						&__flag {
							position: absolute;
							top: 0;
							left: 0;
							box-sizing: border-box;
							color: $colorWhite;
							background-color: $colorCollectionExclusive;
							font-size: 15px;
							font-family: $fontBaseMedium;
							font-weight: normal;
							padding: 5px 15px;
							min-width: 150px;
							text-align: center;

							@include mq-min($bpDesktop) {
								min-width: 180px;
								padding: 5px 20px;
							}
						}
					}
				}

				&__link {
					display: block;

					&__img {
						display: block;
						width: 100%;
						max-width: none;
					}
				}
			}

			&__flights {
				border-top: 1px solid $colorPurplePale;

				@include mq-min($bpDesktop) {
					display: none;
					border-top: 0;
				}

				&--desktop {
					display: none;

					@include mq-min($bpDesktop) {
						display: block;
					}
				}
			}

			&__content {
				height: 100%;
				padding: 20px;

				&__title {
					font-size: 21px;
					line-height: 29px;
					padding-bottom: 0;
					overflow: hidden;
					text-align: left;
					width: 100%;
					color: $colorBase;

					&__link {
						color: $colorBase;

						&:hover,
						:focus {
							color: $colorBase;
							text-decoration: underline;
						}
					}
				}

				&__subtitle {
					color: $colorBase;

					&__link {
						color: $colorBase;

						&:hover,
						:focus {
							color: $colorBase;
							text-decoration: underline;
						}
					}
				}

				&__list {
					display: block;
					padding: 0 0 15px 16px;

					&--hide-mobile {
						display: none;

						@include mq-min($bpDesktop) {
							display: block;
						}
					}

					&__item {
						position: relative;

						&:before {
							background: $colorBase;
							border-radius: 100%;
							content: "";
							display: block;
							height: 4px;
							left: -15px;
							margin: 0;
							padding: 0;
							position: absolute;
							top: 11px;
							width: 4px;
							z-index: 1;

							.c-search-map & {
								left: -10px;
								top: 7px;
							}
						}

						&--bold {
							font-family: $fontBaseMedium;
						}

						&__description {
							width: 53%;
						}
					}
				}
			}

			&__price-summary {
				padding: 0 20px 20px 20px;
				font-size: 13px;

				&__no-price {
					font-size: 18px
				}
			}

		}
	}

	&__footer {
		&__holiday {
			@mixin holiday() {
				display: flex;
				flex-direction: column;
				background: #61758B;
				border-bottom: 1px solid $colorWhite;
				padding: 15px;
				gap: 10px;

				@include mq-min($bpDesktop) {
					flex-direction: row;
					justify-content: space-between;
				}

				&:last-child {
					border-bottom: 0;
				}
			}

			@include holiday();

			&--ad-reg {
				@include holiday();
				flex-direction: row;
			}

			&__details {
				@mixin holiday-details() {
					display: flex;
					align-items: center;
					position: relative;
					color: $colorWhite;
				}

				@include holiday-details();

				&--ad-reg {
					@include holiday-details();
					display: block;
					font-size: 17px;
				}

				&__type {
					color: $colorWhite;
					font-size: 15px;
					flex: 1;
					min-width: 170px;
				}

				&__features {
					display: flex;
					gap: 15px;
					flex: 3;
					justify-content: flex-end;

					@include mq-min($bpDesktop) {
						gap: 10px;
					}

					&__item {
						color: $colorWhite;
						font-size: 16px;

						@include mq-min($bpLarge) {
							color: $colorAction;
							background: $colorWhite;
							border-radius: 20px;
							padding: 5px 10px;
							font-size: 13px;
						}

						&__label {
							display: none;

							@include mq-min($bpLarge) {
								display: inline;
							}
						}
					}
				}
			}

			&__cta {
				color: $colorWhite;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 20px;

				&__price {
					display: flex;
					gap: 10px;
					align-items: center;

					&__from {
						font-size: 13px;
						line-height: 1.3;
					}

					&__value {
						&__pp {
							font-size: 20px;
							font-weight: 600;
						}

						&__total {
							font-size: 13px;
						}
					}
				}

				&__button {
					a {
						min-width: $cardButtonWidth;
						display: flex;
						justify-content: space-evenly;

						@include mq-max($bpMobileSmall) {
							min-width: 130px;
							font-size: 13px;
						}
					}

				}
			}
		}
	}

	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		align-content: center;
		align-items: center;
		text-align: left;
		background: rgba(0, 0, 0, 0.8);
		overflow-y: scroll;

		@include mq-min($bpTabletLarge) {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: transparent;
		}

		&__container {
			position: relative;
		}

		&__content {
			max-width: 400px;
			margin: 0 auto;
			padding: 15px;
			font-family: $fontBaseLight;
			font-size: 17px;

			@include mq-min($bpTabletLarge) {
				max-width: 400px;
				padding: 0;
			}

			@include mq-min($bpDesktop) {
				max-width: 650px;
				width: 100%;
				padding: 0;
			}

			@include mq-min($bpLarge) {
				max-width: 775px;
				padding: 0;
			}

			&.c-card {
				max-height: calc(75vh - 40px);
				height: 100%;
				overflow-y: scroll;
				margin-bottom: 0;

				@include mq-min($bpTabletLarge) {
					padding-top: 35px;
					overflow-y: auto;
					overflow-x: hidden;
					box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
					max-height: none;
				}

				@include mq-min($bpDesktop) {
					padding-top: 25px;
				}
			}
		}

		&__close {
			text-align: right;
			padding: 0 10px;

			&__button {
				color: $colorTeal;
				background-color: transparent;
				border: none;
				padding: 5px;
				margin: 0 -5px 0 0;
				display: inline-flex;
				align-items: center;
				cursor: pointer;
				transition: color 300ms;

				i {
					font-size: 25px;
					padding: 0 5px;
				}
			}
		}
	}
}

@include mq-max($bpTabletLarge) {
	html.map-card-overlay-open {
		position: fixed;
		width: 100%;

		body {
			position: static;
			overflow: hidden;
		}

		.l-page__inner {
			z-index: 100;
		}



		.l-utils--mobile-nav,
		.c-mobile-search-nav {
			display: none;
		}

		.l-nav-sub__search-sub {
			top: 0;
		}

		.m-section {
			position: static;
			visibility: hidden;
		}

		.c-card__overlay {
			top: 120px;
			z-index: 1001;
		}
	}
}

@include mq-min($bpTabletLarge) {
	html.map-card-overlay-open {
		.l-nav-sub {
			background-color: transparent;
		}
	}
}

﻿.m-contact {
	margin: 0;
	padding: 15px 0;

	&__inner {
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}

	&__details {
		&__title {
			color: $colorGrey;
			font-size: $fontSize + 6;
			line-height: $fontHeight;
			padding: 0 0 20px;
		}

		&__phone {
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
			padding: 0;
		}

		&__address {
			font-style: normal;
			font-size: $fontSize;
			line-height: $fontHeight + 2;
			padding: 10px 0 20px;
		}

		&__hours-item {
			span {
				display: inline-block;
				min-width: 135px;
			}
		}

		&__wishlist {
			&__item {
				overflow: hidden;
				padding: 0 0 20px;
			}

			&__link {
				color: $colorBase;
				display: block;

				&:hover {
					color: $colorBlack;
					text-decoration: none;
				}
			}

			&__text {
				overflow: hidden;
			}

			&__title {
				font-size: $fontSize;
				line-height: $fontHeight;
			}

			&__image {
				float: left;
				padding: 0 15px 0 0;
				max-width: 120px;
			}
		}
	}

	&__form {
		&__left {
			@include mq-min($bpTabletLarge) {
				float: left;
				width: 55%;
			}

			@include mq-min($bpDesktop) {
				width: 60%;
			}
		}

		&__right {
			@include mq-min($bpTabletLarge) {
				@include box-sizing();
				float: left;
				width: 45%;
				padding-left: 20px;
			}

			@include mq-min($bpDesktop) {
				width: 40%;
				padding-left: 40px;
			}

			&--popup-enquiry {
				display: none;
			}
		}

		&__full-width {
			width: 100%;
			clear: both;

			&--bordered {
				padding: 20px 0 5px;
				border-top: 1px solid $colorWhite;
				border-bottom: 1px solid $colorWhite;
				margin: 10px 0 5px;
			}
		}

		&__form-text {
			@include mq-min($bpDesktop) {
				float: left;
				padding: 10px 0 0;
				width: 65%;
				width: calc(100% - 210px);
			}
		}

		&__form-submit {
			padding: 10px 0 15px;

			@include mq-min($bpDesktop) {
				float: right;
				padding: 10px 0 0;
				text-align: right;
				width: 35%;
				width: 210px;
			}
		}

		&__title-label {
			color: $colorGrey;
			font-size: $fontSize;
			line-height: $fontHeight;
			padding: 0 0 5px;

			&--alt {
				padding: 0 0 13px;
			}
		}
	}

	&__brochure {
		&__inner {
			@include mq-min($bpDesktop) {
				margin: 0 -20px 0 0;
			}
		}

		&__block {
			background: $colorWhite;
			padding: 15px 15px 0;
			margin: 5px 0 20px;
			overflow: hidden;

			@include mq-min($bpDesktop) {
				padding: 20px;
			}
		}

		&__col {
			overflow: hidden;
			padding: 0 0 15px;
			width: 100%;

			@include mq-min($bpDesktop) {
				@include box-sizing();
				float: left;
				padding: 0 20px 0 0;
				width: 50%;
			}
		}

		&__image {
			float: left;
			vertical-align: top;
			width: 100px;
			margin: 0 10px 0 0;
			overflow: hidden;
		}

		&__content {
			overflow: hidden;
		}

		&__title {
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			padding: 0 0 5px;
		}
	}

	.m-popup & {
		padding: 0;

		.m-tabs {
			margin-bottom: 0;
		}

		.m-tabs__menu__item--brochure {
			display: none;
		}

		.m-contact__form__right--popup-enquiry {
			display: block;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-top: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-top: 25px;
	}

	@include mq-min($bpLarge) {
		padding-top: 30px;
	}
}

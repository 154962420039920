﻿.m-booking-extras {
	background: $colorBlue;
	margin: 0 0 15px 0;
	padding: 25px 15px;
	text-align: center;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__summary {
		color: $colorWhite;
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
		margin: 0;
		padding: 0 0 15px;
	}

	&__link {
		color: $colorWhite;
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
		margin: 0;
		padding: 0;
		text-decoration: underline;
		text-transform: uppercase;

		&:hover {
			color: $colorWhite;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding: 30px 20px;
	}

	@include mq-min($bpDesktop) {
		padding: 35px 25px;
	}

	@include mq-min($bpLarge) {
		padding: 40px;
	}

	&--block {
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
	}

	&--tight {
		margin-bottom: 0;
	}
}

.m-login--popup {
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    display: none;
    padding: 40px 10px;
    position: absolute;
    z-index: 9999;
    margin: 0 auto;
    width: 300px;
    left: 50%;

    @media(min-width: $bpTablet) {
        width: 500px;
    }

    img {
        width: 250px;
    }

    &__login {
        display: block;
    }

    &__reset {
        display: none;
        max-width: 350px;
        margin: 0 auto;
        margin-top: 30px;

        p {
            opacity: 0;
            word-wrap: break-word;
        }

        &-complete {
            display: none;
        }
    }

    @mixin form-width {
        width: 250px;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .form-row {
            display: flex;
            flex-direction: column;
            align-items: center;

            label {
                display: none !important;
            }

            &__label {
                display: flex;
                align-items: center;
                flex-direction: column;

                .m-accordion-header {
                    @include form-width;
                }
            }

            &__remember-me {
                label {
                    display: flex !important;
                }
            }

            input[type=text],
            input[type=password] {
                border-color: gray;
                @include form-width;
            }

            button {
                @include form-width;
            }

            &.two-col {
                display: flex;
                @include form-width;
            }

            .form-error {
                @include form-width;
            }
        }
    }
}

﻿.m-image-grid {
	margin: 0;
	padding: 0;

	&__item {
		margin: 0;
		overflow: hidden;
		padding: 0;
		line-height: 10px;

		a {
			width: 100%;
			height: 100%;
		}

		&__link {
			color: $colorBase;
			display: block;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:hover {
				color: $colorBase;
				text-decoration: none;
			}

			@include mq-min($bpDesktop) {
				height: 450px;
			}

			@include mq-min($bpLarge) {
				height: 506px;
			}
		}

		&__image {
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: relative;
			z-index: 1;
			object-fit: cover;
			height: inherit;

			&__image {
				display: block;
				width: 100%;
				object-fit: cover;

				@include mq-min($bpTabletLarge) {
					height: 361px;
				}

				@include mq-min($bpDesktop) {
					height: 450px;
				}

				@include mq-min($bpLarge) {
					height: 506px;
				}

				margin: 0;
				padding: 0;
				@include image-hover();
				opacity: .9;
			}

			&__image__grid {
				display: block;
				width: 100%;
				object-fit: cover;
				margin: 0;
				padding: 0;
				@include image-hover();
				opacity: .9;
				aspect-ratio: 1.33/1;
			}

			&__background {
				background-color: rgba(0, 0, 0, .45);
			}
		}

		&__container {
			position: absolute;
			top: 40px;
			left: 0;
			width: 100%;
			z-index: 1;
			text-align: center;
			color: white;
		}

		&__title {
			margin: 8px 0 0 0;
			padding: 0;
		}

		&__subtitle {
			display: block;
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			margin: 0;
			padding: 10px 0 0 0;
		}

		&:hover {

			.m-image-grid__item__image__image,
			.m-image-grid__item__image__image__grid {
				@include image-hover-anim();
			}
		}
	}
}

﻿.m-info-block {
	background: $colorOffWhite;
	padding: 25px;
	margin-bottom: 15px;

	&__title {
		color: $colorGrey;
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -15px;
		padding: 0;

		&__title,
		&__data {
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize - 2;
			font-weight: 300;
			line-height: $fontHeight;
			margin: 0;
			padding: 15px 0 0 15px;
			vertical-align: top;
			width: 50%;
			float: left;
		}

		&__title {
			color: $colorGrey;
			max-width: 135px;
			padding-right: 15px;
			clear: both;
		}
	}

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
	}

	&--tight {
		margin-bottom: 0;
	}
}

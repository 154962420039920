﻿.m-booking-options {}

.m-booking-options__details {
	background-color: $colorPurplePale;
	padding: 10px 15px;
	margin-bottom: 15px;
}

.m-booking-options__details__title {
	color: $colorGrey;
	font-size: 23px;
	line-height: 25px;
	padding: 5px 0 20px;
}

.m-booking-options__details__intro {
	font-weight: 700;
	font-size: 15px;
}

.m-booking-options__details__summary {
	background-color: $colorWhite;
	padding: 5px 5px 0;
}

.m-booking-options__details__summary__details {
	padding-bottom: 0;
}

.m-booking-options__details__summary__text {
	font-weight: 600;
	display: inline;
}

.m-booking-options__details__summary__value:after {
	display: block;
	content: '';
	padding-bottom: 15px;
}

.m-booking-options__details__summary__value {
	font-weight: 400;
	display: inline;
}

.m-booking-options__details__info {
	padding: 10px 0px 0;

	p {
		padding-bottom: 0px;
	}
}

.m-booking-options__footer {
	p {
		font-size: 14px;
		padding: 10px 10px 10px 10px;
		line-height: 15px;

		@include mq-min($bpTabletLarge) {
			text-align: left;
			padding-left: 40px;
		}
	}
}

.m-booking-options__details__buttons {
	@include mq-min($bpMobileLarge) {
		width: 300px;
	}
}

﻿.m-photo-gallery {
	margin: 0;
	padding: 0;
	position: relative;
	height: 100%;

	&__inner {
		padding: 0;
		position: relative;
		margin: 0;
		height: 100%;
	}

	&__content {
		@include box-sizing();
		height: 100%;
		margin: 0 auto;
		max-width: $maxPageWidthLarge;
		padding: 10px 0 0 0;
		text-align: center;

		@include mq-min($bpDesktop) {
			padding-top: 20px;
		}
	}

	&__images {
		height: 100%;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;

		.bx-prev {
			display: none;
		}

		.bx-next {
			display: none;
		}

		.bx-viewport,
		.bx-wrapper {
			height: 100% !important;
		}

		&__menu {
			height: 100%;
			margin: 0;
			padding: 0;

			&__item {
				margin: 0;
				padding: 0;
				bottom: 0px;
				top: 35%;
				transform: translateY(-50%);

				@media screen and (min-width: 960px) {
					bottom: 130px;
					top: 0;
					transform: none;
				}
			}

			&__image {
				display: block;
				margin: 0 auto;
				max-height: 100%;
				position: relative;

				@media screen and (min-width: 960px) and (min-height: 750px) {
					max-width: 960px;
					min-height: 620px;
				}

				@media screen and (min-width: 1248px) and (min-height: 828px) {
					max-width: $maxPageWidthLarge;
					min-height: 698px;
				}
			}
		}
	}

	&__controls {
		display: none;

		&__menu {
			margin: 0;
			padding: 0;
			height: 20px;

			&__item {
				padding: 0;
				margin: 0;
			}

			&__link {
				position: absolute;
				top: 50%;
				left: 0;
				z-index: 1;
				height: 60px;
				margin: -30px 0 0;
				padding: 0 10px;
				@include text-hidden;
				z-index: 1;

				&--next {
					position: absolute;
					top: 2em;
					color: $colorPurplePale;
					font-size: 40px;
					background-color: rgba(88, 89, 91, 0.3);
					right: 0;
					padding-left: 5px;
					padding-right: 9px;
					padding-top: 35px;
					padding-bottom: 35px;
					z-index: 10000;

					@include mq-min($bpTablet) {
						top: 3em;
						font-size: 60px;
					}

					@include mq-min($bpDesktop) {
						top: 5em;
						font-size: 60px;
					}

					@include mq-min($bpLarge) {
						background-color: transparent;
					}

					&:hover {
						color: $colorBlue;
					}
				}

				&--prev {
					position: absolute;
					top: 2em;
					color: $colorPurplePale;
					font-size: 40px;
					background-color: rgba(88, 89, 91, 0.3);
					left: 0;
					padding-right: 5px;
					padding-left: 9px;
					padding-top: 35px;
					padding-bottom: 35px;
					z-index: 10000;

					@include mq-min($bpTablet) {
						top: 3em;
						font-size: 60px;
					}

					@include mq-min($bpDesktop) {
						top: 5em;
						font-size: 60px;
					}

					@include mq-min($bpLarge) {
						background-color: transparent;
					}

					&:hover {
						color: $colorBlue;
					}
				}
			}
		}
	}

	&__thumbs-bar {
		background: lighten($colorBlack, 10%);
		bottom: -80px;
		display: block;
		height: 65px;
		left: 0;
		padding: 15px 0 10px;
		position: absolute;
		right: 0;
		@include transition(0.35s);
		z-index: 1;

		&.active {
			bottom: 0px;
		}

		&__link {
			background: lighten($colorBlack, 10%);
			display: block;
			font-size: 0;
			line-height: 0;
			outline: none !important;
			padding: 10px;
			position: absolute;
			top: -35px;
			right: 15px;
			z-index: 10;

			&:before {
				background: url(/images/common/sprite.png) no-repeat 0 -90px;
				width: 23px;
				height: 15px;
				content: "";
				display: block;
			}

			&:hover {
				&:before {
					@include opacity(0.8);
				}
			}
		}
	}

	&__thumbs {
		margin: 0 auto;
		max-width: 960px;
		padding: 0;
		position: relative;

		&__inner {
			margin: 0;
			padding: 0 50px;

			.bx-wrapper {
				max-width: 100% !important;
			}
		}

		&__menu {
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;

			&__item {
				display: inline-block;
				margin: 0;
				max-width: 102px;
				padding: 0;
			}

			&__link {
				border: 1px solid #0d0d0d;
				display: block;
				margin: 0;
				@include opacity(.5);
				padding: 0;

				&:hover,
				&.active {
					@include opacity(1);
				}

				&.active {
					border: 1px solid $colorWhite;
				}
			}

			&__image {
				display: block;
				margin: 0;
				padding: 0;
				width: 100%;
			}
		}

		@include mq-min($bpLarge) {
			max-width: $maxPageWidthLarge;
		}
	}

	&__details {
		color: $colorWhite;
		margin: 0 auto;
		padding: 5px 10px 0 10px;
		text-align: center;
		max-width: 450px;

		@include mq-min($bpDesktop) {
			padding-top: 10px;
			max-width: 600px;
		}
	}

	&__description {
		color: $colorWhite;
		font-family: $fontBaseMedium;
		margin: 0 auto;
		padding: 0 10px;
		text-align: center;
		max-width: 450px;

		@include mq-min($bpDesktop) {
			padding-top: 5px;
			max-width: 600px;
		}
	}
}

.m-loyalty {
    &__benefit {
        color: $colorWhite;
        background: $colorAction;
        padding: 10px;
        margin-bottom: 35px;

        &__header {
            font-weight: bold;
            padding-bottom: 5px;
        }
    }
}

// cms
.cms {
	margin: 0 auto;

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $fontBase;
		font-size: $fontSize;
		font-weight: 400;
		line-height: $fontHeight;
		margin: 0;
		padding: 0 0 10px 0;
	}

	h2 {
		font-size: $fontSize + 1;
		line-height: $fontHeight - 7;
		padding-top: 17px;
	}

	h3 {
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
	}

	h4,
	h5,
	h6 {
		padding-bottom: 5px;
	}

	a {
		font-family: $fontBaseMedium;
	}

	blockquote {
		color: $colorGrey;
		font-family: $fontSerif;
		font-size: $fontSize + 7;
		line-height: $fontHeight + 7;
		margin: 0;
		padding: 5px;
		text-align: center;

		.quote-author {
			display: inline-block;
			font-size: $fontSize + 3;
			line-height: $fontHeight + 3;
			margin-bottom: -10px;
			padding-left: 15px;
			padding-right: 15px;
			position: relative;
			top: -10px;
			z-index: 1;

			&:before {
				background: $colorGrey;
				content: "";
				display: block;
				height: 1px;
				left: 0;
				margin: 0;
				@include opacity();
				padding: 0;
				position: absolute;
				top: 12px;
				width: 10px;
				z-index: 1;
			}

			@include mq-min($bpTabletLarge) {
				font-size: $fontSize + 7;
				line-height: $fontHeight + 7;
			}

			&:first-child {
				margin-bottom: 0;
				top: auto;
			}
		}

		@include mq-min($bpTablet) {
			margin: 0 10%;
		}

		@include mq-min($bpTabletLarge) {
			font-size: $fontSize + 13;
			line-height: $fontHeight + 13;
		}
	}

	ul,
	ol {
		list-style: none;
		margin: 0;
		padding: 0 0 10px 25px;

		>li {
			list-style: none;
			margin: 0;
			padding: 0 0 5px 0;
			position: relative;
			z-index: 1;
		}

		ul,
		ol {
			margin-bottom: -5px;
			padding-bottom: 0;
			padding-top: 5px;
		}
	}

	ul {
		>li {
			&:before {
				background: lighten($colorBase, 40%);
				@include border-radius(100%);
				content: "";
				display: block;
				height: 4px;
				left: -15px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 10px;
				width: 4px;
				z-index: 1;
			}
		}
	}

	ol {
		counter-reset: cms-ol;

		>li {
			&:before {
				color: lighten($colorBase, 40%);
				content: counter(cms-ol)".";
				counter-increment: cms-ol;
				display: block;
				font-size: $fontSize - 2;
				line-height: $fontHeight - 2;
				left: -30px;
				margin: 0;
				overflow: hidden;
				padding: 0;
				position: absolute;
				text-align: right;
				top: 2px;
				width: 25px;
				z-index: 1;
			}

			>ol {
				counter-reset: cms-ol;

				>li {
					&:before {
						content: counter(cms-ol, lower-alpha)".";
						counter-increment: cms-ol;
					}

					>ol {
						counter-reset: cms-ol;

						>li {
							&:before {
								content: counter(cms-ol, lower-roman)".";
								counter-increment: cms-ol;
							}
						}
					}
				}
			}
		}
	}

	small {
		font-size: $fontSize - 3;
		line-height: $fontHeight - 3;
	}

	img {
		display: block;
		margin: 0 auto;
		padding: 0;
	}

	iframe {
		display: block;
		margin: 0 0 15px 0;
		padding: 0;
		max-width: $maxContentWidth;
	}

	figure {
		figcaption {
			font-style: normal;
			text-align: left;
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
		}

		@include mq-min($bpTabletLarge) {
			padding: 0 0 20px;
		}

		@include mq-min($bpDesktop) {
			padding: 0 0 25px;
		}
	}

	&-video {
		height: 0;
		margin: 0 auto 15px auto;
		overflow: hidden;
		padding: 35px 0 53.8% 0;
		position: relative;
		max-width: $maxContentWidth;
		z-index: 1;

		iframe {
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			max-width: 100%;
			z-index: 1;
		}
	}

	.light-text {
		color: $colorGrey;
	}

	.button {
		a {
			background: $colorPurple;
			border: 1px solid $colorPurple;
			color: $colorWhite;
			display: inline-block;
			font-family: $fontBaseMedium;
			font-weight: 400;
			margin: 0;
			padding: 8px 55px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			min-width: 121px;

			@include mq-max($bpMobileLarge) {
				display: flex;
				justify-content: center;
				min-width: auto;
				font-size: smaller;
			}

			&:hover {
				background: $colorWhite;
				color: $colorPurple;
			}
		}
	}

	.video-link {
		a {
			display: inline-block;
			@include min-height(18px);
			padding: 2px 0 2px 28px;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:before {
				@include bg('/images/common/sprite.png', no-repeat, -58px -86px);
				content: "";
				display: block;
				height: 22px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 0;
				width: 22px;
				z-index: 1;
			}

			&:hover {
				@include opacity();
				text-decoration: none;
			}
		}
	}

	.video-image-link {
		display: block;

		img {
			width: 100%;
		}

		a {
			display: block;
			overflow: hidden;
			position: relative;
			z-index: 1;

			>img {
				@include image-hover();
			}

			.fa-solid {
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 10;
				transform: translate(-50%, -50%);
				color: rgba(255, 255, 255, .7);
			}

			&:hover {
				>img {
					aspect-ratio: 1.33/1;
					@include image-hover-anim();
				}
			}
		}
	}

	table {
		border-bottom: 1px solid $colorDivider;
		margin-bottom: 20px;

		tbody {
			tr {
				&:nth-child(odd) {

					th,
					td {
						background: $colorOffWhite;
					}
				}
			}
		}
	}

	&-table {
		margin: 0 0 15px 0;
		overflow-x: auto;
		padding: 0 0 10px 0;
		-webkit-overflow-scrolling: touch;

		table {
			@include mq-max($bpTablet - 1) {
				margin: 0;
				min-width: $bpMobileLarge;
			}
		}

		@include mq-min($bpDesktop) {
			margin: 0;
			overflow-x: visible;
			padding: 0;
		}
	}
}

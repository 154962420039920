.m-booking-confirm {
    &__newuser {
        display: flex;
        flex-direction: column;

        &-list {
            margin: 0 20px;
            li {
                list-style: disc;
            }
        }
        &-form {
            display: flex;
            margin: 0 50px;
            margin: 0 12px 20px;

            form {
                input[type="text"] {
                    width: 250px;
                }

                .form-button {
                    width: 250px;
                    margin: 10px 0;
                }
            }
        }

        @media(min-width: $bpTabletLarge) {
            flex-direction: row;
            &-form {
                align-items: flex-end;
                margin: 0 50px 30px;
            }
        }
    }
}
﻿.m-grading {
	margin: 0;
	padding: 15px 0;

	&__menu {
		&__item {
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 15px 0 0 0;

			&:first-child {
				padding-top: 0;
			}
		}

		&__title,
		&__rating,
		&__info {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 0 0 0 5px;
			vertical-align: top;
		}

		&__title {
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			padding: 0 0 20px;
			width: 95px;
		}

		&__rating {
			height: 11px;
			padding: 6px 0 0;
			width: 110px;

			&__point {
				background: $colorPurplePale;
				@include border-radius(100%);
				display: inline-block;
				height: 16px;
				margin: 0 0 0 5px;
				padding: 0;
				position: relative;
				width: 16px;

				&:first-child {
					margin-left: 0;
				}

				&.active {
					background: $colorPurpleLight;
				}

				&.half {
					background: $colorPurpleLight;
					position: relative;

					&:before {
						background: $colorPurplePale;
						@include border-radius(0 50% 50% 0);
						content: "";
						height: 16px;
						left: 8px;
						position: absolute;
						right: 0;
						top: 0;
						width: 8px;
						z-index: 2;
					}
				}
			}

			@include mq-min($bpLarge) {
				width: 100px;
			}

			@include mq-min($bpLarge) {
				width: 110px;
			}
		}

		&__info {
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			font-size: $fontSize + 2;
			line-height: $fontHeight + 2;
			width: 275px;

			@include mq-min($bpTabletLarge) {
				width: 200px;
			}

			@include mq-min($bpTabletLarge + 80px) {
				width: 255px;
			}

			@include mq-min($bpDesktop) {
				width: auto;
			}
		}
	}

	&--tight {
		padding-bottom: 0;
	}
}
